import { useEffect, useState } from 'react';
import { Button, Col, Divider, message, Row, Space, Typography } from 'antd';
import { PlusOutlined, SyncOutlined } from '@ant-design/icons';
import { UnmountClosed } from 'react-collapse';
import { animateScroll, Element, scroller } from 'react-scroll';
import { useRecoilValue } from 'recoil';
import { useScopes } from 'hooks/useScopes';
import { RoleTable } from 'components/atoms/RoleTable';
import { RoleCard } from 'components/atoms/RoleCard';
import { Role } from 'sb/models/Role';
import { scrollOptions } from 'sb/shared/constants';
import { useAdminApi } from 'sb/api/admin';
import { menuKeyState } from 'recoil/selectors';

const Roles: React.FC = () => {
  const { aclCheck } = useScopes();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);

  const [roles, setRoles] = useState<Role[]>([]);
  const [selectedRole, setSelectedRole] = useState<Role>();
  const menuKey = useRecoilValue(menuKeyState);

  // API
  const { getRoles, createRole, deleteRole, updateRole } = useAdminApi();

  useEffect(() => {
    if (menuKey !== 'roles') return;

    getAllRoles().then();
  }, [menuKey]);

  const getAllRoles = async () => {
    setLoading(true);
    const res = await getRoles();
    if (res?.status === 'success') setRoles(res.data);
    setLoading(false);
  };

  const handleEdit = (row?: Role) => {
    setOpen(true);
    setSelectedRole(row);
    scroller.scrollTo('role-card', scrollOptions);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedRole(undefined);
    animateScroll.scrollToTop(scrollOptions);
  };

  const handleDelete = async (row: Role) => {
    if (selectedRole?.id === row.id) {
      handleClose();
    }
    const res = await deleteRole(row.id);
    if (res) {
      setRoles((prev) => prev.filter((role) => role.id !== row.id));
      message.success('Role successfully deleted!');
    }
  };

  const handleSubmit = async (data: any) => {
    setSaving(true);
    if (selectedRole) {
      // Update
      const res = await updateRole(selectedRole.id, data);
      if (res?.status === 'success') {
        setRoles((prev) => {
          const temp = [...prev];
          const index = temp.findIndex((role) => role.id === selectedRole.id);
          temp.splice(index, 1, res.data);
          return temp;
        });
        message.success('Role successfully updated!');
        handleClose();
      }
    } else {
      // Create
      const res = await createRole(data);
      if (res?.status === 'success') {
        setRoles((prev) => [...prev, res.data]);
        message.success('Role successfully created!');
        handleClose();
      }
    }
    setSaving(false);
  };

  return (
    <>
      <Row justify="space-between">
        <Col>
          <Typography.Title level={4}>User Roles</Typography.Title>
        </Col>
        <Col>
          <Space>
            {aclCheck(['create:site_wide_admin']) && (
              <Button type="primary" icon={<PlusOutlined />} onClick={() => handleEdit()}>
                Add
              </Button>
            )}
            <Button type="default" loading={loading} icon={<SyncOutlined spin={loading} />} onClick={getAllRoles}>
              Refresh
            </Button>
          </Space>
        </Col>
      </Row>

      <Divider className="my-4" />

      <RoleTable dataSource={roles} loading={loading} onEdit={handleEdit} onDelete={handleDelete} />

      <Element name="role-card" />
      <UnmountClosed isOpened={open}>
        <RoleCard
          className="mt-10"
          title={<span className="text-base">{selectedRole ? 'Update' : 'Create'} User Role</span>}
          data={selectedRole}
          loading={saving}
          onSubmit={handleSubmit}
          onClose={handleClose}
        />
      </UnmountClosed>
    </>
  );
};

export default Roles;
