import { CustomFieldElement, Deal, Contacts } from 'models/Deal.d';
import {
  Avatar,
  Button,
  Card,
  Checkbox,
  Col,
  Comment,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  notification,
  Row,
  Select,
  Typography
} from 'antd';
import './DealModalSummary.scss';
import moment from 'moment-timezone';
import { EditOutlined, MailOutlined, PhoneOutlined } from '@ant-design/icons';
import { useRecoilValue } from 'recoil';
import { profileSwitchState } from 'recoil/atoms';
import { ANONYMOUS_CLIENT_EMAIL, ANONYMOUS_CLIENT_USER } from 'shared/constants';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSalesforceApi, Contact } from 'api/salesforce';
import { useEditDeal, useContractApi } from 'api/contract';
import { SfUserMetaData } from 'models/Deal';
import { round } from 'lodash-es';
import type { InputStatus } from 'antd/lib/_util/statusUtils';

interface Props {
  deal?: Deal;
  closeModal?: () => void;
  refetchDeal?: () => void;
  onDeleteDeal?: (dealId: number) => void;
}

const defaultFieldsKeys = [
  'contract_start_date',
  'contract_renewal_date',
  'client_contact_id',
  'supplier',
  'subject',
  'term'
];

/**
 * Represents a modal component used to display and edit deal summary information.
 *
 * @component
 * @param {Object} props - The props object containing deal data and event handlers.
 * @param {Deal} props.deal - The deal object containing deal information.
 * @param {Function} props.refetchDeal - The function to refetch deal data.
 * @returns {React.Component} The DealModalSummary component.
 */
const DealModalSummary: React.FC<Props> = ({ deal, refetchDeal }: Props): React.ReactElement => {
  // LOCAL STATE
  const [term, setTerm] = useState(1);
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [isUserDealEditEnabled, setIsUserDealEditEnabled] = useState(false);
  const [selectedClientId, setSelectedClientId] = useState<string>('');
  const [editSfMetaData, setEditSfMetaData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedSecondaryClientUserIds, setSelectedSecondaryClientUserIds] = useState<string[]>([]);
  const [selectedPrimaryClientUserId, setSelectedPrimaryClientUserId] = useState<string | null>(null);
  const [primaryContactStatus, setPrimaryContactStatus] = useState<InputStatus>('');

  // RECOIL STATE
  const profileSwitch = useRecoilValue(profileSwitchState);

  const [sfDealEditForm] = Form.useForm();
  const [userDealEditForm] = Form.useForm();
  const tcv = Form.useWatch('total_value', userDealEditForm);
  const annualValue = useMemo(() => {
    return deal?.total_value ? round(Number(deal.total_value) / (term || 1), 2) : 0;
  }, [deal, term]);

  // API
  const { getSfAccountContacts } = useSalesforceApi();
  const { updateDealMetadata } = useContractApi();

  const { mutate: editDeal, isLoading } = useEditDeal({
    onSuccess: () => {
      refetchDeal && refetchDeal();
      notification.success({
        message: 'Deal updated successfully'
      });
      setIsUserDealEditEnabled(false);
    },
    onError: () => {
      notification.error({
        message: 'Deal update failed'
      });
      userDealEditForm.resetFields(['subject', 'total_value', 'contract_start_date', 'contract_renewal_date']);
      setIsUserDealEditEnabled(false);
    }
  });

  useEffect(() => {
    !selectedClientId ? setPrimaryContactStatus('error') : setPrimaryContactStatus('');
  }, [selectedClientId]);

  useEffect(() => {
    if (tcv) {
      userDealEditForm.setFieldsValue({
        annual_value: annualValue
      });
    }
  }, [userDealEditForm, tcv, annualValue]);

  useEffect(() => {
    if (!deal) return;
    if (deal?.sfDeal?.Client_Contact__r?.Id) {
      setSelectedClientId(deal?.sfDeal?.Client_Contact__r?.Id);
    }
    if (deal?.secondaryContacts?.length > 0) {
      const userIds = deal.secondaryContacts.map((contact) => contact.user_id);
      setSelectedSecondaryClientUserIds(userIds);
    }
    if (deal.contract_renewal_date && deal.contract_start_date) {
      setTerm(moment(deal?.contract_renewal_date).diff(deal?.contract_start_date, 'years', true));
    } else if (deal.projected_close_date && deal.projected_start_date) {
      setTerm(moment(deal?.projected_close_date).diff(deal?.projected_start_date, 'years', true));
    } else {
      setTerm(1);
    }
  }, [deal]);

  useEffect(() => {
    const selectedPrimaryContact = contacts.find((t) => t.Id === selectedClientId)?.user_id ?? null;
    setSelectedPrimaryClientUserId(selectedPrimaryContact);
  }, [selectedClientId, deal?.sfDeal?.Client_Contact__r?.Id, contacts]);

  useEffect(() => {
    (async () => {
      try {
        const clientContacts: Contact[] | undefined = await getSfAccountContacts(profileSwitch?.id);
        if (Array.isArray(clientContacts)) setContacts(clientContacts);
      } catch (err) {
        console.error('Error occurred while fetching contacts', err);
      }
    })();
  }, [profileSwitch?.id]);

  const getClientContactValue = useCallback(() => {
    return contacts?.find((i) => i?.Id === deal?.sfDeal?.Client_Contact__r?.Id)?.Name;
  }, [contacts, deal?.sfDeal?.Client_Contact__r?.Id]);

  const getClientSecondaryContactValues = useCallback(() => {
    return Array.from(
      new Set(
        contacts
          ?.filter((i) => deal?.secondaryContacts?.some((contact) => contact.user_id === i.user_id))
          .map((contact) => contact.user_id)
      )
    );
  }, [contacts, deal?.secondaryContacts]);

  /**
   * Returns initial values for the SF Deal form.
   * If a deal exists and has SF Deal data, the function creates base fields with the following properties:
   * - contract_start_date: The Contract Start Date from the SF Deal, formatted as a moment object or an empty string.
   * - contract_renewal_date: The Contract Renewal Date from the SF Deal, formatted as a moment object or an empty string.
   * - client_contact_id: The client contact value obtained from the getClientContactValue() function or an empty string.
   *
   * For each custom field in the deal's client, the function adds a property to the base fields object with the following properties:
   * - key: The custom field key.
   * - value: The custom field value converted based on the data type (either as a moment object for date, boolean for boolean, or string for any other data type).
   *
   * The function returns the baseFields object if the deal and SF Deal data exist, otherwise returns an empty object.
   *
   * @returns {Object} - The initial values for the SF Deal form.
   * @param {Object} deal - The deal from which the initial values will be extracted.
   * @param {Function} getClientContactValue - The function to retrieve the client contact value.
   */
  const sfDealFormInitialValues = useMemo(() => {
    if (deal && deal.sfDeal) {
      const baseFields = {
        contract_start_date: deal?.sfDeal?.Contract_Start_Date__c
          ? moment.utc(deal?.sfDeal?.Contract_Start_Date__c)
          : undefined,
        contract_renewal_date: deal?.sfDeal?.Contract_Renewal_Date__c
          ? moment.utc(deal?.sfDeal?.Contract_Renewal_Date__c)
          : undefined,
        client_contact_id: getClientContactValue() || ''
      };
      deal.client.custom_field.forEach((field: CustomFieldElement) => {
        Object.assign(baseFields, {
          [field.key]:
            field.data_type === 'date' && field.custom_field_value[0]?.val
              ? moment(field.custom_field_value[0]?.val)
              : field.data_type === 'boolean'
              ? field.custom_field_value[0]?.val === 'true'
              : field.custom_field_value[0]?.val
        });
      });
      return baseFields;
    }
    return {};
  }, [deal, getClientContactValue]);

  const handleEditSfMetaData = () => {
    setEditSfMetaData(true);
  };

  const handleClientChange = (id: string) => {
    setSelectedClientId(id);
  };

  const handleSecondaryClientChange = (value: string[]) => {
    setSelectedSecondaryClientUserIds(value);
  };

  const filteredSecondaryClientOptions = useMemo(() => {
    return contacts?.filter((contact) => contact?.Id !== selectedClientId);
  }, [contacts, selectedClientId]);

  const filteredPrimaryClientOptions = useMemo(() => {
    return contacts?.filter((contact) => !selectedSecondaryClientUserIds?.includes(contact?.user_id));
  }, [contacts, selectedSecondaryClientUserIds]);

  const clientOptions = useMemo(() => {
    return filteredPrimaryClientOptions?.map((a) => (
      <Select.Option key={a.Id} value={a.Id}>
        {a.Name}
      </Select.Option>
    ));
  }, [contacts, filteredPrimaryClientOptions]);

  const renderEditButton = () => (
    <Col>
      <Button
        type="link"
        size="small"
        icon={<EditOutlined />}
        onClick={() => {
          if (deal?.sfDeal) {
            handleEditSfMetaData();
          } else {
            setIsUserDealEditEnabled(true);
          }
        }}
        disabled={editSfMetaData || isUserDealEditEnabled}
      >
        Edit Metadata
      </Button>
    </Col>
  );

  const handleSfDealSubmit = async (values: SfUserMetaData): Promise<void> => {
    if (primaryContactStatus == 'error') return;
    if (!selectedPrimaryClientUserId) {
      setPrimaryContactStatus('error');
      return;
    }
    const defaultFields: Record<string, any> = {};
    const customFields: Record<string, any> = {};
    Object.entries(values).forEach(([key, value]) => {
      if (defaultFieldsKeys.indexOf(key) > -1) {
        Object.assign(defaultFields, {
          [key]: value,
          client_contact_id: selectedClientId
        });
      } else {
        Object.assign(customFields, { [key]: value });
      }
    });

    defaultFields['primaryContactUserId'] = selectedPrimaryClientUserId || '';
    defaultFields['secondaryContactUserIds'] =
      selectedSecondaryClientUserIds?.length > 0 ? selectedSecondaryClientUserIds : [];

    try {
      setLoading(true);
      const payload = { defaultFields, customFields };
      const res = await updateDealMetadata(deal?.id as number, payload);
      if (res?.status === 'success') {
        notification.success({
          message: 'Data updated successfully',
          duration: 4
        });
        refetchDeal && refetchDeal();
      }
    } catch (error) {
      notification.error({
        message: 'Failed to update data',
        duration: 4
      });
    } finally {
      setLoading(false);
      setEditSfMetaData(false); // Always switch back to view mode
    }
  };

  const resetForm = () => {
    const customFieldsKey = deal?.client?.custom_field.map((field: CustomFieldElement) => field?.key);
    setPrimaryContactStatus('');
    deal?.sfDeal?.Client_Contact__r?.Id && setSelectedClientId(deal?.sfDeal?.Client_Contact__r?.Id);
    setSelectedSecondaryClientUserIds(deal?.secondaryContacts?.map((contact) => contact?.user_id) || []);
    if (editSfMetaData) {
      setEditSfMetaData(false);
      const sfDealEditFormFields = ['contract_start_date', 'contract_renewal_date', 'client_contact_id'];
      sfDealEditForm.resetFields(
        customFieldsKey && customFieldsKey?.length > 0
          ? sfDealEditFormFields.concat(customFieldsKey)
          : sfDealEditFormFields
      );
    }
  };

  /**
   * Renders a custom field based on its data type.
   *
   * @param {CustomFieldElement} field - The custom field object.
   * @returns {React.ReactElement} - The rendered custom field component.
   */
  const customField = (field: CustomFieldElement): React.ReactElement => {
    const required: boolean | undefined = field.attributes.required;

    switch (field.data_type) {
      case 'string':
        return (
          <Form.Item
            key={field.id}
            label={field.label}
            name={field.key}
            rules={[{ required: required, message: 'A value is required' }]}
          >
            <Input size="large" showCount={editSfMetaData} allowClear {...field.attributes} />
          </Form.Item>
        );
      case 'number': {
        const numberAttributes: any = { ...field?.attributes };
        delete numberAttributes['required'];
        return (
          <Form.Item
            key={field.id}
            label={field.label}
            name={field.key}
            rules={[
              {
                required: field?.attributes?.required,
                message: `${field?.label} is required`
              },
              {
                validator: (_, value) => {
                  const minValue = field?.attributes?.min;
                  const maxValue = field?.attributes?.max;

                  if (value === undefined || value === null || value === '') {
                    return Promise.resolve();
                  }

                  if (minValue && Number(value) < Number(minValue)) {
                    return Promise.reject(new Error(`Minimum value should be ${minValue}.`));
                  }

                  if (maxValue && Number(value) > Number(maxValue)) {
                    return Promise.reject(new Error(`Maximum value should be ${maxValue}.`));
                  }

                  return Promise.resolve();
                }
              }
            ]}
          >
            <InputNumber<string>
              size="large"
              style={{ width: 270 }}
              className="rounded-md"
              {...numberAttributes}
              controls={true}
            />
          </Form.Item>
        );
      }
      case 'date':
        return (
          <Form.Item
            key={field.id}
            label={field.label}
            name={field.key}
            rules={[{ required: required, message: 'A value is required' }]}
          >
            <DatePicker size="large" {...field.attributes} />
          </Form.Item>
        );
      case 'boolean':
        return (
          <Form.Item key={field.id} label={field.label} name={field.key} valuePropName="checked">
            <Checkbox />
          </Form.Item>
        );
      default:
        return (
          <Form.Item
            key={field.id}
            label={field.label}
            name={field.key}
            rules={[{ required: required, message: 'A value is required' }]}
          >
            <Input size="large" showCount={editSfMetaData} allowClear {...field.attributes} />
          </Form.Item>
        );
    }
  };

  const renderSfDealForm = () => {
    return (
      <Form
        className={editSfMetaData ? '' : 'view-mode'}
        form={sfDealEditForm}
        labelCol={{ span: 8 }}
        labelAlign="left"
        size="small"
        wrapperCol={{ span: 16 }}
        colon={false}
        disabled={!editSfMetaData}
        initialValues={sfDealFormInitialValues}
        onFinish={handleSfDealSubmit}
      >
        <Form.Item label="Supplier" name="supplier">
          <Typography.Text>{deal?.vendor.name}</Typography.Text>
        </Form.Item>
        {deal?.reseller?.name && (
          <Form.Item label="Reseller" name="reseller">
            <Typography.Text>{deal?.reseller?.name || '--'}</Typography.Text>
          </Form.Item>
        )}
        <Form.Item label="Subject" name="subject">
          <Typography.Text>{deal?.subject || deal?.name}</Typography.Text>
        </Form.Item>
        <Form.Item label="Description">
          <Input.TextArea disabled value={deal?.sf_description || ''} bordered={true} rows={3} />
        </Form.Item>
        <Form.Item label="Term" name="term">
          <Typography.Text>{deal && deal?.sfDeal?.Term__c ? deal?.sfDeal.Term__c : undefined}</Typography.Text>
        </Form.Item>
        <Form.Item label="Contract Start" name="contract_start_date">
          <DatePicker
            size="large"
            className="rounded-md"
            format={'MMM DD, YYYY'}
            disabledDate={(current) => {
              const endDate = sfDealEditForm.getFieldValue('contract_renewal_date');
              return current && endDate && current.isAfter(endDate);
            }}
          />
        </Form.Item>
        <Form.Item label="Contract Renewal" name="contract_renewal_date">
          <DatePicker
            size="large"
            className="rounded-md"
            format={'MMM DD, YYYY'}
            disabledDate={(current) => {
              const endDate = sfDealEditForm.getFieldValue('contract_start_date');
              return current && endDate && current.isBefore(endDate);
            }}
          />
        </Form.Item>
        <Form.Item label="Deal Type">
          <Typography.Text>{deal?.sfDeal?.Deal_Reason__c}</Typography.Text>
        </Form.Item>
        <Form.Item label="Report Type">
          <Typography.Text>{deal?.sfDeal?.Report_Type__c}</Typography.Text>
        </Form.Item>
        {deal && deal.client.custom_field.length > 0 && (
          <>
            <Divider className="uppercase" orientation="left" style={{ fontSize: 14 }}>
              Custom Fields
            </Divider>
            {deal.client.custom_field.map(customField)}
          </>
        )}
      </Form>
    );
  };

  const renderSalesContact = () => (
    <>
      <div style={{ fontSize: 15, fontWeight: 700 }}>Sales Contact</div>
      <Comment
        avatar={
          <Avatar style={{ background: '#152d51' }}>
            {deal?.sfDeal?.Client__r.Owner
              ? deal?.sfDeal.Client__r.Owner.FirstName.charAt(0) + deal?.sfDeal.Client__r.Owner.LastName.charAt(0)
              : 'NPI'}
          </Avatar>
        }
        author={deal?.sfDeal?.Client__r.Owner?.Full_Name__c}
        content={
          <>
            <PhoneOutlined /> {deal?.sfDeal?.Client__r.Owner ? deal.sfDeal.Client__r.Owner.MobilePhone : '--'}
          </>
        }
      />
    </>
  );

  const renderDeliveryContact = () => (
    <>
      <div style={{ fontSize: 15, fontWeight: 700 }}>NPI Delivery Contact</div>
      <Comment
        avatar={
          <Avatar style={{ background: '#152d51' }}>
            {deal?.sfDeal?.Account_Manager__r
              ? deal?.sfDeal.Account_Manager__r.FirstName.charAt(0) + deal?.sfDeal.Account_Manager__r.LastName.charAt(0)
              : 'NPI'}
          </Avatar>
        }
        author={deal?.sfDeal?.Account_Manager__r ? deal?.sfDeal.Account_Manager__r.Name : '--'}
        content={
          <>
            <PhoneOutlined />{' '}
            {deal?.sfDeal?.Account_Manager__r
              ? deal?.sfDeal.Account_Manager__r.MobilePhone || deal?.sfDeal.Account_Manager__r.Phone
              : '--'}
          </>
        }
      />
    </>
  );

  const renderPrimaryClientContact = () => (
    <>
      <div className="text-base font-bold">Primary Contact</div>
      {editSfMetaData ? (
        <>
          <Select
            className="w-full"
            placeholder={'Select Primary Contact'}
            size="large"
            allowClear
            showArrow
            virtual={false}
            defaultValue={getClientContactValue()}
            onChange={handleClientChange}
            showSearch={true}
            status={primaryContactStatus}
            optionFilterProp="children"
          >
            {clientOptions}
          </Select>
          {primaryContactStatus == 'error' && <span className="text-red-500">Primary contact is required</span>}
        </>
      ) : (
        <Comment
          avatar={
            <Avatar style={{ background: '#30aa9e' }}>
              {deal?.sfDeal?.Client_Contact__r
                ? deal?.sfDeal.Client_Contact__r.FirstName.charAt(0) + deal?.sfDeal.Client_Contact__r.LastName.charAt(0)
                : 'NPI'}
            </Avatar>
          }
          author={
            deal?.sfDeal?.Client_Contact__r
              ? profileSwitch && profileSwitch.anonymous
                ? ANONYMOUS_CLIENT_USER
                : deal?.sfDeal.Client_Contact__r.Name
              : '--'
          }
          content={
            <>
              <MailOutlined />{' '}
              {deal?.sfDeal?.Client_Contact__r
                ? profileSwitch && profileSwitch.anonymous
                  ? ANONYMOUS_CLIENT_EMAIL
                  : deal?.sfDeal.Client_Contact__r.Email
                : '--'}
            </>
          }
        />
      )}
    </>
  );

  const renderSecondaryClientContact = () => (
    <>
      <div className="text-base font-bold">Secondary Contact</div>
      {editSfMetaData ? (
        <Select
          className="w-full"
          mode="multiple"
          placeholder="Select Secondary Contacts"
          size="large"
          allowClear
          showArrow
          virtual={false}
          defaultValue={getClientSecondaryContactValues()}
          onChange={handleSecondaryClientChange}
          showSearch={true}
          fieldNames={{ label: 'Name', value: 'user_id' }}
          options={filteredSecondaryClientOptions}
          optionFilterProp="Name"
        />
      ) : (
        <div className="max-h-[200px] overflow-y-auto">
          {deal?.secondaryContacts?.map((contact: Contacts) => (
            <Comment
              key={contact.user_id}
              avatar={
                <Avatar style={{ background: '#30aa9e' }}>
                  {contact.FirstName.charAt(0).toUpperCase()}
                  {contact.LastName.charAt(0).toUpperCase()}
                </Avatar>
              }
              author={profileSwitch && profileSwitch.anonymous ? ANONYMOUS_CLIENT_USER : contact.Name}
              content={
                <>
                  <MailOutlined /> {profileSwitch && profileSwitch.anonymous ? ANONYMOUS_CLIENT_EMAIL : contact.Email}
                </>
              }
            />
          ))}
        </div>
      )}
    </>
  );

  return (
    <div className="deal-modal-summary">
      <Card className="pt-4">
        <Row gutter={36}>
          <Col span={16}>
            <Row>
              <Col flex="auto">
                <Typography.Title level={4}>Summary</Typography.Title>
              </Col>
              {renderEditButton()}
            </Row>
            {deal && deal.sfDeal ? renderSfDealForm() : null}
          </Col>
          <Col span={8}>
            {(deal?.sfDeal && (
              <Card bordered={true} className={'contacts-card-body'} title="Key Contacts">
                {renderSalesContact()}
                <Divider />
                {renderDeliveryContact()}
                <Divider />
                {renderPrimaryClientContact()}
                <Divider />
                {renderSecondaryClientContact()}
              </Card>
            )) ||
              null}
          </Col>
        </Row>
      </Card>

      {editSfMetaData ? (
        <Row justify="end" className="footer-cta">
          <Col>
            <Button onClick={() => resetForm()}>Cancel</Button>
            <Button
              loading={loading || isLoading}
              type={'primary'}
              htmlType="submit"
              className="ml-4"
              onClick={() => sfDealEditForm.submit()}
            >
              Save
            </Button>
          </Col>
        </Row>
      ) : null}
    </div>
  );
};

export default DealModalSummary;
