import { useAxios } from 'hooks/useAxios';
import { AxiosErrorHandler } from 'shared/Helpers';
import { useQuery } from '@tanstack/react-query';
import { BASE_URL } from 'sb/shared/constants';

const { VITE_APP_UNSECURE_ROUTE } = import.meta.env;

export const useExtraApi = () => {
  const { axios } = useAxios(BASE_URL);

  const getMe = async () => {
    try {
      const res = await axios.get(`/secure/me`);
      return res.data;
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  const updateMe = async (data: any) => {
    try {
      const res = await axios.put(`/secure/me`, data);
      return res.data;
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  const getUsers = async () => {
    try {
      const res = await axios.get(`/secure/users`);
      if (res.data?.status === 'success') {
        return res.data?.data;
      }
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  const getAccountUsers = async (profile_id?: number) => {
    try {
      const res = await axios.get(`/secure/account/users`, {
        params: {
          profile_id
        }
      });
      return res.data;
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  const getStatusLov = async () => {
    try {
      const res = await axios.get(`/secure/status/lov`);
      if (res.data?.status === 'success') {
        return res.data?.data;
      }
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  /**
   * Select Categories and Sub-Categories
   */
  const getCategories = async () => {
    try {
      const res = await axios.get(`${VITE_APP_UNSECURE_ROUTE}/categories`);
      return res.data;
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  const getPricingBySKUs = async (skus: string[]) => {
    try {
      const res = await axios.post(`/secure/pricing`, skus);
      return res.data;
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  /**
   * Select Supplier Dynamics
   */
  const getDealDynamics = async () => {
    try {
      const res = await axios.get(`${VITE_APP_UNSECURE_ROUTE}/deal/dynamics`);
      return res.data;
    } catch (e) {
      return e;
    }
  };

  const createDealDynamic = async (data: any) => {
    try {
      const res = await axios.post(`/secure/deal/dynamic`, data);
      return res.data;
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  const updateDealDynamic = async (id: any, data: any) => {
    try {
      const res = await axios.put(`/secure/deal/dynamic/${id}`, data);
      return res.data;
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  const deleteDealDynamic = async (id: number) => {
    try {
      const res = await axios.delete(`/secure/deal/dynamic/${id}`);
      return res.data;
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  const reorderDealDynamics = async (order: any) => {
    try {
      const res = await axios.post(`/secure/deal/dynamics/reorder`, order);
      return res.data;
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  const getAccountContracts = async (id: string) => {
    try {
      const res = await axios.get(`/secure/account/${id}/contracts`);
      return res.data;
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  /**
   * Update contract tags
   *
   * @param contract_id
   * @param tags
   */
  const updateContractTags = async (contract_id: number, tags: any) => {
    try {
      const res = await axios.put(`/secure/contracts/${contract_id}/tags`, { tags });
      if (res.data?.status === 'success') {
        return res.data.data;
      }
      throw new Error(res.data.message);
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  /**
   * Search account tags by query
   *
   * @param query
   */
  const searchAccountTags = async (query: string) => {
    try {
      const res = await axios.get(`/secure/tags?str=${query}`);
      if (res.data?.status === 'success') {
        return res.data.data;
      }
      throw new Error(res.data.message);
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  /**
   * Get Acodis transaction
   *
   * @param id
   */
  const getAcodisTransaction = async (id: number) => {
    try {
      const res = await axios.get(`${VITE_APP_UNSECURE_ROUTE}/ai/transaction/${id}`);
      return res.data;
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  /**
   * Export Acodis transaction
   *
   * @param contract_id
   * @param transaction_id
   */
  const exportAcodisTransaction = async (contract_id: number, transaction_id: number) => {
    try {
      const res = await axios.get(
        `${VITE_APP_UNSECURE_ROUTE}/ai/contract/${contract_id}/transaction/${transaction_id}/export`
      );
      return res.data;
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  const sendVerificationEmail = async () => {
    try {
      const res = await axios.post(`/secure/send-verification`);
      return res.data;
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  /**
   * Update note
   *
   * @param noteId note id
   * @param note new note
   * @returns
   */
  const updateNote = async (noteId: number, note: string) => {
    try {
      const res = await axios.put(`/secure/note/${noteId}`, { note });
      if (res.data?.status === 'error') {
        throw new Error(res.data?.message);
      }
      return res.data;
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  /**
   * Delete note
   *
   * @param noteId note id
   * @returns
   */
  const deleteNote = async (noteId: number) => {
    try {
      const res = await axios.delete(`/secure/note/${noteId}`);
      return res.data;
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  /**
   * Search countries
   *
   * @param { string } str
   * @returns
   */
  const getCountries = async (str: string) => {
    try {
      const res = await axios.get(`/secure/countries?str=${str}`);
      return res.data;
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  return {
    getMe,
    updateMe,
    getUsers,
    getAccountUsers,
    getStatusLov,
    getCategories,
    getPricingBySKUs,
    getDealDynamics,
    createDealDynamic,
    updateDealDynamic,
    deleteDealDynamic,
    reorderDealDynamics,
    getAccountContracts,
    updateContractTags,
    searchAccountTags,
    getAcodisTransaction,
    exportAcodisTransaction,
    sendVerificationEmail,
    updateNote,
    deleteNote,
    getCountries
  };
};

export const useAccountUsers = (profile_id?: number, config = {}) => {
  const { getAccountUsers } = useExtraApi();
  return useQuery({
    queryKey: ['users'],
    queryFn: () => getAccountUsers(profile_id) || [],
    staleTime: 1000 * 60 * 60 * 24,
    ...config
  });
};
