import { StringHelper } from '@bryntum/gantt';
import { Task } from 'models/Renewals';

export interface ColumnRecord {
  record: Task;
  value: string | number;
}

const red = 'background: #ff6961';
const orange = 'background: #ffb54c';

const taskRenderer = ({ taskRecord, renderData }: any) => {
  if (!taskRecord.isMilestone) {
    // For leaf tasks we return some custom elements, described as DomConfig objects.
    // Please see https://bryntum.com/products/grid/docs/api/Core/helper/DomHelper#typedef-DomConfig for more information.

    const data = taskRecord.originalData;
    const orangeRunway = 1;
    // Adding background color of progressbar
    if (data.runway) {

      if (data.remaining >= 0 && data.remaining <= data.runway) {
        renderData.style = red;
        return [
          {
            tag: 'div',
            class: 'taskName',
            html: StringHelper.encodeHtml(`${taskRecord._data.remaining} mos.`)
          }
        ];
      } else if (data.remaining >= 0 && data.remaining <= data.runway + orangeRunway) {
        renderData.style = orange;
        return [
          {
            tag: 'div',
            class: 'taskName',
            html: StringHelper.encodeHtml(`${taskRecord._data.remaining} mos.`)
          }
        ];
      }
    } else {
      if (data.remaining >= 0 && data.remaining <= 3) {
        renderData.style = red;
        return [
          {
            tag: 'div',
            class: 'taskName',
            html: StringHelper.encodeHtml(`${taskRecord._data.remaining} mos.`)
          }
        ];
      } else if (data.remaining >= 0 && data.remaining <= 3 + orangeRunway) {
        renderData.style = orange;
        return [
          {
            tag: 'div',
            class: 'taskName',
            html: StringHelper.encodeHtml(`${taskRecord._data.remaining} mos.`)
          }
        ];
      }
    }
  }
};

export { taskRenderer };
