// noinspection DuplicatedCode

import { AxiosInstance, default as axiosInstance, InternalAxiosRequestConfig } from 'axios';
import { notification } from 'antd';
import { MyAuth0Context } from 'index';
import { useContext } from 'react';

export const useAxios = (baseURL: string): { axios: AxiosInstance } => {
  // Using MyAuth0Context instead of useAuth0 hook directly since we need the wrapped context
  const { getAccessTokenSilently } = useContext(MyAuth0Context);
  const axios = axiosInstance.create({ baseURL });

  axios.interceptors.request.use(async (config: InternalAxiosRequestConfig<any>) => {
    try {
      const token = await getAccessTokenSilently();
      if (config.headers) {
        config.headers.Accept = 'application/json,text/plain';
        config.headers['Content-Type'] = 'application/json';
        config.headers['Cache-Control'] = 'no-cache';

        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
      }
      return config;
    } catch (err) {
      // return original config if JSON.parse fails
      return config;
    }
  });

  axios.interceptors.response.use(undefined, (error: any) => {
    const status = error.response ? error.response.status : null;
    if (status === 403) {
      notification.warning({
        message: 'Protected Data',
        description: 'You do not have permission to access this information.',
        duration: 8
      });
      return error;
    } else {
      const message = error.response?.data?.message;
      notification.warning({
        message: 'Error',
        description: Array.isArray(message) ? message[0] : message,
        duration: 8
      });
      return error;
    }
  });

  return { axios };
};
