import { useAxios } from 'hooks/useAxios';
import { AxiosErrorHandler } from 'shared/Helpers';
import { AxiosError, AxiosResponse } from 'axios';
import { AddTasksPayload, BulkDeal, CategoryTask, Deal, EditDealRequest, EditTasksPayload } from 'types/Contract';
import { useMutation, useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import { UploadedFile } from 'models/Files';
import { FollowContractRecord, MultiDealRequestPayload } from '../models/Deal';
import { BASE_URL } from 'shared/constants';

export const useContractApi = () => {
  const { axios } = useAxios(BASE_URL);
  /**
   * Fetch the logged-in user's company contracts
   */
  const fetchMyCompanyContracts = async () => {
    try {
      const res = await axios.get(`/secure/contracts`);
      if (res.data?.status === 'success') {
        return res.data.data;
      }
      return Promise.reject(
        new Error(res.data?.message || 'Error occurred while fetching the user`s company contracts')
      );
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  /**
   * Fetch contract summary
   * @param contract_id
   */
  const fetchContractSummary = async (contract_id: number) => {
    try {
      const res = await axios.get(`/api/contract/${contract_id}`);
      if (res.data?.status === 'success') {
        return res.data.data;
      }
      return Promise.reject(new Error(res.data?.message || 'Error occurred while fetching the contract summary'));
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  /**
   * Fetch contract summary
   * @param contract_id
   */
  const fetchDealContacts = async (contract_id: number) => {
    try {
      const res = await axios.get(`/api/contract/${contract_id}/contacts`);
      if (res.data?.status === 'success') {
        return res.data.data;
      }
      return Promise.reject(new Error(res.data?.message || 'Error occurred while fetching deal contracts'));
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  /**
   * Fetch contract comps
   *
   * @param {number} contract_id
   * @param {number} years
   */
  const fetchDealComps = async (contract_id: number, years: number) => {
    try {
      const res = await axios.get(`/api/contract/${contract_id}/comps?years=${years}`);
      if (res.data?.status === 'success') {
        return res.data.data;
      }
      return Promise.reject(new Error(res.data?.message || 'Error occurred while fetching the contract comps'));
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  /**
   * Returns a Prisma structured JSON object
   *
   * @param {number} contract_id
   */
  const fetchContract = async (contract_id: number) => {
    try {
      const res = await axios.get(`/secure/p/contract/${contract_id}`);
      return res.data;
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  /**
   * Returns a Prisma structured JSON object
   *
   * @param {number} id
   */
  const fetchDeal = async (id: number) => {
    try {
      const res: AxiosResponse<any, any> = await axios.get(`/secure/deal/${id}`);
      if (res?.data?.status === 'success') {
        return res?.data?.data;
      }
      return Promise.reject(new Error(res?.data?.message || 'Error occurred while fetching deal'));
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  /**
   * Fetch Contract Renewal Notifications
   *
   * @param {number} id
   */
  const fetchContractRenewalNotifications = async (id: number) => {
    try {
      const res: AxiosResponse<any, any> = await axios.get(`/secure/contract/${id}/renewal/notifications`);
      if (res.data?.status === 'success') {
        return res.data.data;
      }
      return Promise.reject(Error(res.data?.message || 'Error occurred while fetching the user`s company contracts'));
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  const addDeal = async (deal: Deal, profile_id?: number) => {
    try {
      const url = `/api/deal`;
      const { data } = await axios.post(url, deal, { params: { profile_id } });
      if (data?.status === 'success') return data;
      return Promise.reject(Error(data?.message || 'Error occurred while adding deal'));
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  const editDeal = async (dealId: number, deal: EditDealRequest) => {
    try {
      const url = `/api/deal/${dealId}`;
      const { data } = await axios.patch(url, deal);
      return data;
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  /**
   * Returns a Prisma structured JSON object
   *
   * @param formData
   */
  const fileUpload = async (formData: any) => {
    try {
      const res: AxiosResponse<any, any> = await axios.post(`/api/files/upload`, formData, {
        transformRequest: () => formData
      });
      if (res.data?.status === 'success') {
        return res.data.data;
      }
      return Promise.reject(new Error(res.data?.message || 'Error occurred while file upload'));
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  /**
   * Remove File
   * @param key
   */
  const removeFile = async (key: string) => {
    try {
      const res: AxiosResponse<any, any> = await axios.delete(`/api/file`, {
        params: { key }
      });
      if (res.data?.status === 'success') {
        return res.data.data;
      }
      return Promise.reject(new Error(res.data?.message || 'Error occurred while removing file'));
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  const removeFiles = async (keys: string[]) => {
    try {
      const res: AxiosResponse<any, any> = await axios.delete(`/api/files`, {
        data: keys
      });
      if (res?.data?.status === 'success') {
        return res?.data?.data;
      }
      return Promise.reject(new Error(res?.data?.message || 'Error occurred while removing files'));
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  const deleteDeal = async (id: number) => {
    try {
      const res: AxiosResponse<any, any> = await axios.delete(`/api/deal/${id}`);
      if (res.data?.status === 'success') {
        return res.data.data;
      }
      return Promise.reject(new Error(res.data?.message || 'Error occurred while deleting deal'));
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  const getBlobFromFileURL = async (fileUrl: string): Promise<Blob | undefined> => {
    try {
      const response = await fetch(fileUrl);
      if (response.ok) {
        return response.blob();
      }
      return Promise.reject(Error(`Failed to fetch file: ${response.statusText}`));
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  const removeContractFile = async (id: number, key: string) => {
    try {
      const res: AxiosResponse<any, any> = await axios.delete(`/api/deal/${id}/file`, {
        params: { key }
      });
      if (res.data?.status === 'success') {
        return res.data.data;
      }
      return Promise.reject(new Error(res.data?.message || 'Error occurred while deleting contract file'));
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  /**
   * Attach upload files to contract record
   * @param id
   * @param data
   */
  const attachContractFiles = async (id: number, data: UploadedFile[]) => {
    try {
      const res: AxiosResponse<any, any> = await axios.post(`/api/deal/${id}/files/attach`, data);
      if (res.data?.status === 'success') {
        return res.data.data;
      }
      return Promise.reject(new Error(res.data?.message || 'Error occurred while attaching contract file'));
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  const parseBulkContractFile = async (formData: any) => {
    try {
      const res: AxiosResponse<any, any> = await axios.post(`/api/deals/upload/parse`, formData, {
        transformRequest: () => formData
      });
      if (res.data?.status === 'success') {
        return res.data.data;
      }
      return Promise.reject(new Error(res.data?.message || 'Error occurred while parsing bulk contract file'));
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  const addBulkDeal = async (deals: BulkDeal[]) => {
    try {
      const url = `/api/deals`;
      const { data } = await axios.post(url, deals);
      return data;
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  const updateDealMetadata = async (id: number, data: any) => {
    try {
      const res = await axios.patch(`api/deal/${id}/metadata`, data);
      if (res?.data?.status === 'success') {
        return res?.data;
      }
      return Promise.reject(new Error(res?.data?.message || 'Failed to update deal metadata'));
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  const getCategoryDefaultTasks = async (): Promise<CategoryTask[]> => {
    try {
      const url = `/api/tasks/category_default`;
      const res = await axios.get(url);
      if (res?.data?.status === 'success') {
        return res?.data?.data;
      }
      return Promise.reject(new Error(res?.data?.message || 'Failed to fetch category default tasks'));
    } catch (e) {
      AxiosErrorHandler(e);
      throw e;
    }
  };

  /**
   * Fetch deal notes
   * @param contract_id
   */
  const fetchDealNotes = async (contract_id: number) => {
    try {
      const res = await axios.get(`/api/deal/${contract_id}/notes`);
      if (res.data?.status === 'success') {
        return res.data.data;
      }
      return Promise.reject(new Error(res.data?.message || 'Error occurred while fetching deal notes'));
    } catch (e) {
      AxiosErrorHandler(e);
      throw e;
    }
  };

  const getRequestAccess = async (contract_id: number) => {
    try {
      const res = await axios.get(`/api/deal/documents/${contract_id}/access_request`);
      if (res.data?.status === 'success') {
        return res.data.data;
      }
      return Promise.reject(new Error(res.data?.message || 'Error occurred while fetching request access'));
    } catch (e) {
      AxiosErrorHandler(e);
      throw e;
    }
  };

  const sendRequestAccess = async (payload: { contract_id: number }) => {
    try {
      const res = await axios.post(`/api/deal/documents/access_request`, payload);
      if (res.data?.status === 'success') {
        return res.data.data;
      }
      return Promise.reject(new Error(res.data?.message || 'Error occurred while sending request access'));
    } catch (e) {
      AxiosErrorHandler(e);
      throw e;
    }
  };

  const notifyClient = async (payload: { contract_id: number }) => {
    try {
      const res = await axios.post(`/api/deal/documents/notify_access_grant`, payload);
      if (res.data?.status === 'success') {
        return res.data.data;
      }
      return Promise.reject(new Error(res.data?.message || 'Error occurred while sending access grant notification'));
    } catch (e) {
      AxiosErrorHandler(e);
      throw e;
    }
  };

  const addTasks = async (dealId: number, payload: AddTasksPayload[]) => {
    try {
      const res = await axios.post(`/api/contract/${dealId}/tasks`, payload);
      if (res.data?.status === 'success') {
        return res.data.data;
      }
      return Promise.reject(new Error(res.data?.message || 'Error occurred while adding tasks'));
    } catch (e) {
      AxiosErrorHandler(e);
      throw e;
    }
  };

  /**
   * Create deal note
   */
  const createDealNote = async (
    contractId: number,
    dealNote: { note: string; parent_id?: number; is_visible_to_npi: boolean }
  ) => {
    try {
      const url = `/api/deal/${contractId}/note`;
      const { data } = await axios.post(url, dealNote);
      return data;
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  /**
   * Update deal note
   */
  const updateDealNote = async (
    contractId: number,
    noteId: number,
    payload: { note: string; is_visible_to_npi: boolean }
  ) => {
    try {
      const url = `/api/deal/${contractId}/note/${noteId}`;
      const { data } = await axios.patch(url, payload);
      return data;
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  /**
   * Delete deal note
   */
  const deleteDealNote = async (contractId: number, noteId: number) => {
    try {
      const url = `/api/deal/${contractId}/note/${noteId}`;
      const { data } = await axios.delete(url);
      return data;
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  /**
   * Update deal task
   */
  const updateTask = async (dealId: number, taskId: number, data: EditTasksPayload) => {
    try {
      const res = await axios.patch(`api/contract/${dealId}/task/${taskId}`, data);
      if (res.data?.status === 'success') {
        return res.data.data;
      }
      return Promise.reject(new Error(res.data?.message || 'Error occurred while updating task'));
    } catch (e) {
      AxiosErrorHandler(e);
      throw e;
    }
  };

  /**
   * Delete deal task
   */
  const deleteDealTask = async (contractId: number, taskId: number) => {
    try {
      const url = `/api/contract/${contractId}/task/${taskId}`;
      const { data } = await axios.delete(url);
      return data;
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  const addMultipleDeal = async (deal: any, profile_id?: number) => {
    try {
      const url = `/api/deals`;
      const { data } = await axios.post(url, deal, { params: { profile_id } });
      return data;
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  const fetchContractsForNotifications = async (profile_id?: number) => {
    try {
      const res = await axios.get(`/api/notifications/contracts`, {
        params: { profile_id }
      });
      if (res.data?.status === 'success') {
        return res.data.data;
      }
      return Promise.reject(
        new Error(res.data?.message || 'Error occurred while fetching the contracts for notifications')
      );
    } catch (e) {
      AxiosErrorHandler(e);
      throw e;
    }
  };

  /**
   * Returns a Prisma structured JSON object
   *
   * @param formData
   */
  const fileUploadUnsafe = async (formData: any) => {
    try {
      const res: AxiosResponse<any, any> = await axios.post(`/api/files/upload`, formData, {
        transformRequest: () => formData
      });
      if (res.data?.status === 'success') {
        return res.data.data;
      }
      return Promise.reject(new Error(res.data?.message || 'Error occurred while file upload'));
    } catch (e) {
      await AxiosErrorHandler(e);
    }
  };

  return {
    fetchMyCompanyContracts,
    fetchContractSummary,
    fetchDealContacts,
    fetchDealComps,
    fetchContract,
    fetchDeal,
    fetchContractRenewalNotifications,
    addDeal,
    editDeal,
    fileUpload,
    removeFile,
    removeFiles,
    deleteDeal,
    getBlobFromFileURL,
    removeContractFile,
    attachContractFiles,
    parseBulkContractFile,
    addBulkDeal,
    updateDealMetadata,
    getCategoryDefaultTasks,
    fetchDealNotes,
    getRequestAccess,
    sendRequestAccess,
    notifyClient,
    addTasks,
    createDealNote,
    updateDealNote,
    deleteDealNote,
    updateTask,
    deleteDealTask,
    addMultipleDeal,
    fetchContractsForNotifications,
    fileUploadUnsafe
  };
};

export const useContractsForNotifications = (
  profile_id?: number
): UseQueryResult<FollowContractRecord[], AxiosError> => {
  const { fetchContractsForNotifications } = useContractApi();
  return useQuery({
    queryKey: ['contracts', profile_id],
    queryFn: () => fetchContractsForNotifications(profile_id)
  });
};

export const useAddMultipleDeal = (config = {}) => {
  const { addMultipleDeal } = useContractApi();
  return useMutation({
    mutationFn: async ({ data, profile_id }: { data: MultiDealRequestPayload[]; profile_id?: number }) => {
      return await addMultipleDeal(data, profile_id);
    },
    ...config
  });
};

export const useSendRequestAccess = (config = {}) => {
  const { sendRequestAccess } = useContractApi();
  return useMutation({
    mutationFn: async (data: { contract_id: number }) => {
      return await sendRequestAccess(data);
    },
    ...config
  });
};

export const useNotifyClient = (config = {}) => {
  const { notifyClient } = useContractApi();
  return useMutation({
    mutationFn: async (data: { contract_id: number }) => {
      return await notifyClient(data);
    },
    ...config
  });
};

export const useDeleteDealTask = (config = {}) => {
  const { deleteDealTask } = useContractApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ dealId, taskId }: { dealId: number; taskId: number }) => {
      const res = await deleteDealTask(dealId, taskId);
      await queryClient.invalidateQueries(['my_tasks']);
      return res;
    },
    ...config
  });
};

export const useUpdateTask = (config = {}) => {
  const { updateTask } = useContractApi();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ dealId, taskId, data }: { dealId: number; taskId: number; data: EditTasksPayload }) => {
      const res = await updateTask(dealId, taskId, data);
      await queryClient.invalidateQueries(['my_tasks']);
      return res;
    },
    ...config
  });
};

export const useAddTasks = (config = {}) => {
  const { addTasks } = useContractApi();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ dealId, data }: { dealId: number; data: AddTasksPayload[] }) => {
      const res = await addTasks(dealId, data);
      await queryClient.invalidateQueries(['my_tasks']);
      return res;
    },
    ...config
  });
};

export const useCategoryDefaultTasks = (config = {}): UseQueryResult<CategoryTask[], AxiosError> => {
  const { getCategoryDefaultTasks } = useContractApi();
  const queryClient = useQueryClient();

  return useQuery({
    queryKey: ['category_default_tasks'],
    queryFn: async () => {
      try {
        const res = await getCategoryDefaultTasks();
        const defaultCategories = res.map((category: CategoryTask) => ({
          categoryId: category.category_root.id,
          categoryName: category.category_root.name
        }));

        queryClient.setQueryData(['default_categories'], defaultCategories);

        return res;
      } catch (err) {
        console.error('Error occurred while fetching category default tasks');
        return [];
      }
    },
    staleTime: 1000 * 60 * 60 * 24,
    ...config
  });
};

export const useDefaultCategories = (config = {}): UseQueryResult<Record<string, string | number>[]> => {
  const queryClient = useQueryClient();

  return useQuery({
    queryKey: ['default_categories'],
    queryFn: () => queryClient.getQueryData(['default_categories']) || [],
    staleTime: 1000 * 60 * 60 * 24,
    ...config
  });
};

export const useAddBulkDeal = (config = {}) => {
  const { addBulkDeal } = useContractApi();
  return useMutation({
    mutationFn: async (data: BulkDeal[]) => {
      return await addBulkDeal(data);
    },
    ...config
  });
};

export const useParseBulkContractFile = (config = {}) => {
  const { parseBulkContractFile } = useContractApi();
  return useMutation({
    mutationFn: async (data: any) => {
      return await parseBulkContractFile(data);
    },
    ...config
  });
};

export const useEditDeal = (config = {}) => {
  const { editDeal } = useContractApi();
  return useMutation({
    mutationFn: async (data: EditDealRequest & { dealId: number }) => {
      const { dealId, ...rest } = data;
      return await editDeal(dealId, rest);
    },
    ...config
  });
};

export const useAddDeal = (config = {}) => {
  const { addDeal } = useContractApi();
  return useMutation({
    mutationFn: async ({ data, profile_id }: { data: Deal; profile_id?: number }) => {
      return await addDeal(data, profile_id);
    },
    ...config
  });
};

export const useDeal = (id: number, config = {}): UseQueryResult<Deal, AxiosError> => {
  const { fetchDeal } = useContractApi();
  return useQuery({
    queryKey: ['deal', id],
    queryFn: () => {
      return fetchDeal(id);
    },
    ...config
  });
};
