import { InboxOutlined } from '@ant-design/icons';
import Dragger from 'antd/es/upload/Dragger';
import React, { useCallback, useEffect, useState } from 'react';
import { message, UploadFile, UploadProps } from 'antd';
import { RcFile, UploadChangeParam } from 'antd/es/upload';
import { DraggerProps } from 'antd/lib/upload';
import { UploadedFile } from 'models/Files';
import { useContractApi } from 'api/contract';
import { UploadRequestOption as RcCustomRequestOptions } from 'rc-upload/lib/interface';
import fileUploadValidator from '../../shared/fileUploadValidator';

interface Props extends DraggerProps {
  onUploadFilelistChange?: (uploadFiles: UploadedFile[]) => void;
}

export const UploadFilesDragger: React.FC<Props> = ({ onUploadFilelistChange, ...rest }: Props) => {
  // LOCAL STATE
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [uploadedFileList, setUploadedFileList] = useState<UploadedFile[]>([]);
  const MAX_FILES_COUNT = 5;

  // API
  const { fileUpload, removeFile } = useContractApi();

  const customRequestHandler = async (options: RcCustomRequestOptions) => {
    const { onSuccess } = options;
    const file = options.file as RcFile;
    if (onSuccess) {
      onSuccess(console.log);
    }
    const formData = new FormData();
    formData.append('file', file);
    try {
      const newFile: UploadedFile | undefined = await fileUpload(formData);
      if (newFile) {
        setUploadedFileList((prevState: UploadedFile[]) => [
          ...prevState,
          {
            uid: file.uid,
            key: newFile.key,
            signedUrl: newFile.signedUrl,
            lastModified: file.lastModified,
            name: file.name,
            size: file.size,
            type: file.type
          }
        ]);
      }
    } catch (e) {
      message.error('There was a problem uploading the file');
    }
  };

  const uploadChangeHandler = useCallback(
    (info: UploadChangeParam<UploadFile>) => {
      const { status } = info.file;
      if (status === 'removed') {
        setFileList(fileList.filter((file) => file.uid !== info.file.uid));
      } else if (status && status !== 'error') {
        setFileList(info.fileList);
      }
    },
    [fileList]
  );

  useEffect(() => {
    if (onUploadFilelistChange) {
      onUploadFilelistChange(uploadedFileList);
    }
  }, [onUploadFilelistChange, uploadedFileList]);

  const fileRemoveHandler = useCallback(
    async (removedFile: UploadFile) => {
      const [fileToRemove] = uploadedFileList.filter((file: UploadedFile) => file.uid === removedFile.uid);
      if (fileToRemove?.key) {
        const res = await removeFile(fileToRemove.key);
        if (res) {
          setFileList(fileList.filter((file) => file.uid !== removedFile.uid));
          setUploadedFileList(uploadedFileList.filter((file) => file.uid !== removedFile.uid));
          await message.success('File removed.');
        }
      }
    },
    [fileList, uploadedFileList]
  );

  const uploadProps: UploadProps = {
    name: 'files',
    multiple: true,
    fileList,
    beforeUpload: (file: RcFile) => {
      if (fileUploadValidator(file, fileList, MAX_FILES_COUNT)) {
        message.success('File added successfully!', 2).then();
        return true;
      } else {
        return false;
      }
    },
    customRequest: customRequestHandler,
    onChange: uploadChangeHandler,
    onRemove: fileRemoveHandler
  };

  return (
    <Dragger {...uploadProps} className="p-2 mb-[-8]" maxCount={MAX_FILES_COUNT} style={{ height: 100 }} {...rest}>
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">Click or drag file(s) to this area to upload</p>
      <p className="ant-upload-hint">{MAX_FILES_COUNT} files maximum at a time.</p>
    </Dragger>
  );
};
