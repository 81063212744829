import {
  Button,
  Card,
  Col,
  Divider,
  Drawer,
  DrawerProps,
  Empty,
  Form,
  Input,
  InputNumber,
  List,
  message,
  Row,
  Select,
  Space
} from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { DealDynamic, SupplierDealDynamics } from 'sb/models/SupplierDynamics';
import { useSupplierDynamicsApi } from 'sb/api/supplier_dynamics';
import moment from 'moment/moment';
import './SupplierDynamicsScoringDrawer.scss';

type Score = {
  deal_dynamic_id: number;
  value: number | string;
  vendor_id?: number;
  supplier_category_id?: number;
};

type Note = {
  deal_dynamic_id: number;
  note: string;
  vendor_id?: number;
  supplier_category_id?: number;
};

interface Props extends DrawerProps {
  open: boolean;
  vendor_id?: number;
  onOkay: () => void;
  onClose?: () => void;
}

export const SupplierDynamicsScoringDrawer: React.FC<Props> = ({ open, vendor_id, onOkay, onClose, ...rest }) => {
  // LOCAL STATE
  const [saving, setSaving] = useState(false);
  const [categoryId, setCategoryId] = useState<undefined | number>(undefined);
  const [loading, setLoading] = useState(false);
  const [supplierDynamics, setSupplierDynamics] = useState<undefined | SupplierDealDynamics>(undefined);

  const [form] = Form.useForm();

  // API
  const { fetchSupplierDealDynamics, insertSupplierDealDynamics } = useSupplierDynamicsApi();

  const resetFields = useCallback(() => {
    form.resetFields();
    setCategoryId(undefined);
    setSupplierDynamics(undefined);
  }, [form, setCategoryId, setSupplierDynamics]);

  useEffect(() => {
    if (!vendor_id || !open) return;
    setLoading(true);
    resetFields();
    fetchSupplierDealDynamics(vendor_id)
      .then(setSupplierDynamics)
      .finally(() => setLoading(false));
  }, [vendor_id, open, resetFields]);

  const handleOnOkay = async () => {
    try {
      await form.validateFields();
      const rawData = await form.getFieldsValue();

      // Compile deal dynamic scores array
      const supplierDealDynamics: Array<Score> = [];
      const supplierDealDynamicsNotes: Array<Note> = [];
      Object.entries(rawData).forEach(([key, value]) => {
        const [label, deal_dynamic_id] = key.split('_');
        if (label === 'value' && typeof value === 'number') {
          supplierDealDynamics.push({
            deal_dynamic_id: Number(deal_dynamic_id),
            value: value,
            vendor_id: vendor_id,
            supplier_category_id: categoryId
          });
        } else if (label === 'note' && typeof value === 'string') {
          supplierDealDynamicsNotes.push({
            deal_dynamic_id: Number(deal_dynamic_id),
            note: value,
            vendor_id: vendor_id,
            supplier_category_id: categoryId
          });
        }
      });
      setSaving(true);
      try {
        await insertSupplierDealDynamics(supplierDealDynamics, supplierDealDynamicsNotes);
        message.success('Your supplier feedback has been successfully recorded!');
        onOkay();
      } catch (e) {
        console.log(e);
      } finally {
        setSaving(false);
      }
    } catch (e) {
      await message.error('Scores are required for all Dynamics.');
    }
  };

  return (
    <Drawer
      open={open}
      {...rest}
      width={725}
      onClose={onClose}
      title={
        <Row justify="space-between">
          <Col>{supplierDynamics?.name}</Col>
          <Col style={{ marginRight: 24, fontSize: 14, color: '#777' }}>
            {supplierDynamics?.supplier_deal_dynamic.length === 1 ? (
              <>
                You last scored this vendor on{' '}
                <strong>{moment(supplierDynamics?.supplier_deal_dynamic[0].creation_date).format('ll')}</strong>
              </>
            ) : (
              <div>
                You have <i>never</i> scored this vendor
              </div>
            )}
          </Col>
        </Row>
      }
      footer={
        <div style={{ textAlign: 'right' }}>
          <Space>
            <Button size="large" onClick={onClose}>
              Close
            </Button>
            <Button size="large" type="primary" onClick={handleOnOkay} loading={saving}>
              Save
            </Button>
          </Space>
        </div>
      }
    >
      <div className="supplier-dynamics">
        {supplierDynamics && supplierDynamics.supplier_category.length > 0 && (
          <div className="category-container">
            <div className="category-label">Category</div>
            <Select
              size="large"
              placeholder="Select Category"
              options={supplierDynamics?.supplier_category}
              fieldNames={{ label: 'category', value: 'id' }}
              style={{ width: '100%' }}
              onChange={setCategoryId}
              value={categoryId}
              allowClear
              showArrow
            />
          </div>
        )}
        <Card size="small" title={'Supplier Deal Dynamics'}>
          <div className="card-container">
            <Form
              form={form}
              size={'large'}
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 18 }}
              autoComplete="off"
              labelAlign="left"
              disabled={
                !supplierDynamics || (supplierDynamics && supplierDynamics.supplier_category.length > 0 && !categoryId)
              }
            >
              {supplierDynamics && (
                <List
                  // header="Deal Categories"
                  itemLayout="horizontal"
                  dataSource={supplierDynamics.dealDynamics}
                  loading={loading}
                  size="small"
                  rowKey="id"
                  locale={{
                    emptyText: <Empty description="No supplier selected" />
                  }}
                  bordered={false}
                  renderItem={(item: DealDynamic, index: number) => (
                    <List.Item>
                      <List.Item.Meta
                        title={
                          <>
                            <strong>
                              {index + 1}. {item.name}
                            </strong>
                            : <span className="hint">{item.hint}</span>
                          </>
                        }
                        description={ListItemDescription(item)}
                      />
                    </List.Item>
                  )}
                />
              )}
            </Form>
          </div>
        </Card>
      </div>
    </Drawer>
  );
};

const ListItemDescription = (item: DealDynamic) => {
  return (
    <>
      <div>
        <span style={{ textTransform: 'uppercase', fontSize: 12 }}>Scale</span>: {item.scale_low} to {item.scale_high}
      </div>
      <Divider style={{ margin: '10px 0' }} />
      <Form.Item label="Your Score" name={`value_${item.id}`}>
        <InputNumber min={item.scale_low} max={item.scale_high} placeholder="Score" />
      </Form.Item>
      <Form.Item label="Observation" name={`note_${item.id}`}>
        <Input.TextArea rows={2} placeholder="Enter comment" />
      </Form.Item>
    </>
  );
};
