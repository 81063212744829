import React, { FC, useEffect, useState } from 'react';
import {
  Button,
  Descriptions,
  Select,
  Tag,
  message,
  Form,
  Space,
  Spin,
  Switch,
  Tooltip,
  Radio,
  RadioChangeEvent
} from 'antd';
import { useRecoilState, useRecoilValue } from 'recoil';
import { accountSettingsMenuKeyState, myState } from 'recoil/atoms';
import moment from 'moment';
import { FormatCurrency } from 'shared/Helpers';
import './AccountInfo.scss';
import { AccountData } from 'models/AccountData';
import { ApiOutlined, EditFilled, InfoCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useAdminApi } from 'api/admin';
import { DefaultRole } from 'sb/models/Role';
import { MultifactorAuthentication, useAccountSettingsApi } from 'api/accountSettings';

import { useScopes } from 'hooks/useScopes';

interface AccountInfoDetailsProps {
  accountInfo?: AccountData;
  emailDomains?: string[];
  activeUsers: number;
  siteAdmin?: boolean;
  setEmailDomains?: (value: ((prevState: string[]) => string[]) | string[]) => void;
  onUsersLinkClick?: () => void;
  hasContract?: boolean;
  updateEnabled?: boolean;
  onProvisionAccount?: () => void;
  savedRoles?: DefaultRole[];
  availableRoles?: DefaultRole[];
  fetchSavedAndDefaultRoles?: () => Promise<void>;
  loadingRoles?: boolean;
}

type FormTypes = {
  client_email_domains: Array<string>;
};

export const AccountInfoDetails: FC<AccountInfoDetailsProps> = ({
  accountInfo,
  emailDomains,
  activeUsers,
  siteAdmin,
  setEmailDomains,
  onUsersLinkClick,
  hasContract,
  updateEnabled,
  onProvisionAccount,
  savedRoles,
  availableRoles,
  fetchSavedAndDefaultRoles,
  loadingRoles
}: AccountInfoDetailsProps) => {
  const me = useRecoilValue(myState);
  const [_accountSettingsMenuKey, setAccountSettingsMenuState] = useRecoilState(accountSettingsMenuKeyState);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [editRolesMode, setEditRolesMode] = useState<boolean>(false);
  const [savingEmailDomains, setSavingEmailDomains] = useState<boolean>(false);
  const [savingRoles, setSavingRoles] = useState<boolean>(false);
  const [isProvisioningAccount, setProvisioningAccount] = useState(false);
  const [multifactorAuthentication, setMultifactorAuthentication] = useState<MultifactorAuthentication>({
    is_mfa_required: false,
    allow_remember_browser: false
  });
  const [form] = Form.useForm();
  const mfaPromptFrequencyOptions = [
    { label: 'Each login', value: false },
    { label: 'Every 30 days', value: true }
  ];
  // API
  const { updateClientEmailDomains, saveDefaultRoles } = useAdminApi();
  const { updateMultifactorAuthentication } = useAccountSettingsApi();
  const { aclCheck } = useScopes();

  const { auth0_organization_id } = accountInfo || {};

  useEffect(() => {
    form.setFieldValue('client_email_domains', emailDomains);
  }, [emailDomains, form]);

  useEffect(() => {
    form.setFieldValue(
      'client_roles',
      savedRoles?.map((role) => role?.id)
    );
  }, [savedRoles, form]);

  useEffect(() => {
    setMultifactorAuthentication({
      is_mfa_required: accountInfo?.is_mfa_required ?? false,
      allow_remember_browser: accountInfo?.allow_remember_browser ?? false
    });
  }, [accountInfo?.is_mfa_required, accountInfo?.allow_remember_browser]);

  /**
   * Handles the saving of email domains for a client.
   *
   * This function saves the provided email domains by updating the client's email domains
   * in the system. It also manages the state of the saving process and provides feedback
   * upon successful update.
   * @param {FormTypes} values - The values containing the email domains to be saved.
   * @returns {Promise<void>} - A promise that resolves when the saving process is complete.
   */
  const handleEmailDomainsSave = async (values: FormTypes): Promise<void> => {
    if (!accountInfo?.Id) return;

    const { client_email_domains } = values;
    try {
      setSavingEmailDomains(true);
      const res = await updateClientEmailDomains(accountInfo?.Id + '', client_email_domains);
      setEditMode(false);
      if (res) {
        message.success('Client email domains updated!');
        setEmailDomains && setEmailDomains(client_email_domains);
      } else {
        form.setFieldValue('client_email_domains', emailDomains);
      }
    } finally {
      setSavingEmailDomains(false);
    }
  };

  /**
   * Asynchronously handles the process of saving roles for a client account.
   * Sets savingRoles to true, gets the roles selected by the user from the form,
   * saves the default roles for the client account by calling saveDefaultRoles API,
   * fetches saved and default roles if required, sets savingRoles to false,
   * and sets editRolesMode to false after completion or error.
   * @returns {Promise<void>} A Promise that resolves once the roles are saved and handled.
   */
  const handleSaveRoles = async (): Promise<void> => {
    try {
      setSavingRoles(true);
      const rolesSelected = form.getFieldValue('client_roles');
      const res = await saveDefaultRoles(
        { default_roles: rolesSelected?.map((role: string) => parseInt(role)) },
        accountInfo?.client_id
      );
      if (fetchSavedAndDefaultRoles && res) {
        await fetchSavedAndDefaultRoles();
      }
    } catch (error) {
      message.error('Problem saving/retrieving default roles');
    } finally {
      setSavingRoles(false);
      setEditRolesMode(false);
    }
  };

  const renderMsg = () => {
    return (
      <p>
        To request a change to the domains your team can use to authenticate, please contact your NPI customer service
        director.
      </p>
    );
  };

  const handleDomainEditCancel = () => {
    setEditMode(false);
    form.setFieldValue('client_email_domains', emailDomains);
  };

  const handleRolesEditCancel = () => {
    setEditRolesMode(false);
    form.setFieldValue(
      'client_roles',
      savedRoles?.map((role) => role?.id)
    );
  };

  const handleProvisionAccount = async () => {
    setProvisioningAccount(true);
    try {
      onProvisionAccount?.();
    } catch (e) {
      console.error(e);
    }
    setProvisioningAccount(false);
  };
  const handleMultifactorAuthentication = async (
    checked: boolean | RadioChangeEvent,
    type: 'multifactor_authentication' | 'allow_remembering_devices'
  ) => {
    const data: MultifactorAuthentication = {
      is_mfa_required: false,
      allow_remember_browser: false
    };
    const checkedEvent = checked as RadioChangeEvent;
    switch (type) {
      case 'multifactor_authentication':
        data.is_mfa_required = checked as boolean;
        data.allow_remember_browser = false;
        break;
      case 'allow_remembering_devices':
        data.is_mfa_required = true;
        data.allow_remember_browser = checkedEvent.target.value;
        break;
    }
    try {
      await updateMultifactorAuthentication(data, siteAdmin ? accountInfo?.client_id : undefined);
      message.success(
        type === 'multifactor_authentication'
          ? 'Multifactor authentication settings updated successfully'
          : 'MFA Prompt Frequency updated successfully'
      );
      setMultifactorAuthentication(data);
    } catch (error) {
      message.error('Failed to update multifactor authentication settings');
    }
  };

  const renderAvailableRoles = () => {
    return !siteAdmin ? (
      <>
        {savedRoles?.map((role) => (
          <Tag key={role?.id} color="#d9d9d9" className="text-base px-2 py-1 rounded-lg ml-2 text-black">
            {role.name}
          </Tag>
        ))}
      </>
    ) : siteAdmin && !editRolesMode ? (
      <>
        {savedRoles && savedRoles?.length > 0 ? (
          savedRoles?.map((role) => (
            <Tag key={role.id} color="#d9d9d9" className="text-base px-2 py-1 rounded-lg ml-2 text-black">
              {role.name}
            </Tag>
          ))
        ) : loadingRoles ? (
          <Spin />
        ) : (
          'No roles selected'
        )}
        <Button type="link" onClick={() => setEditRolesMode(true)}>
          {availableRoles && availableRoles?.length > 0 && !loadingRoles ? (
            <>
              <EditFilled />
              Edit List
            </>
          ) : (
            <></>
          )}
        </Button>
      </>
    ) : (
      <div className="flex gap-2">
        <Form.Item name="client_roles" label={undefined} style={{ marginBlock: 0 }}>
          <Select mode="tags" style={{ width: 650 }} tokenSeparators={[',']} placeholder="Select roles">
            {availableRoles?.map((role) => (
              <Select.Option key={role.id} id={role.id} value={role.id}>
                {role.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Space>
          <Button type="primary" loading={savingRoles} onClick={() => handleSaveRoles()}>
            Save
          </Button>
          <Button onClick={() => handleRolesEditCancel()}>Cancel</Button>
        </Space>
      </div>
    );
  };

  return (
    <Form form={form} size="large" colon={false} onFinish={handleEmailDomainsSave}>
      <Descriptions column={1} colon={true} labelStyle={{ width: 180 }} bordered>
        <Descriptions.Item label="Phone">{accountInfo?.Phone}</Descriptions.Item>
        <Descriptions.Item label="Annual Revenue">{FormatCurrency(accountInfo?.AnnualRevenue)}</Descriptions.Item>
        <Descriptions.Item label="Status">
          {siteAdmin
            ? accountInfo?.CurrentContract?.Status || 'No Contract'
            : accountInfo?.Current_Contract__r?.Status || 'No Contract'}
        </Descriptions.Item>
        <Descriptions.Item label="Vantage Access">
          {siteAdmin
            ? accountInfo?.CurrentContract?.Vantage_Access__c
            : accountInfo?.Current_Contract__r?.Vantage_Access__c}
        </Descriptions.Item>
        <Descriptions.Item label="Sign On Method">
          {accountInfo?.auth0_connection_id ? 'Federated Signon' : 'Username/Password'}
        </Descriptions.Item>
        {aclCheck(['update:own_company_admin', 'update:site_wide_admin', 'update:client_admin']) && (
          <>
            <Descriptions.Item
              label={
                <span className="flex items-center">
                  Multi Factor Auth
                  <Tooltip title="Enable multifactor authentication for all users in the account">
                    <InfoCircleOutlined className="ml-2" />
                  </Tooltip>
                </span>
              }
            >
              <Switch
                disabled={!accountInfo?.client_id}
                checked={multifactorAuthentication.is_mfa_required}
                onChange={(checked) => handleMultifactorAuthentication(checked, 'multifactor_authentication')}
              />
              <span className="ml-2">{multifactorAuthentication.is_mfa_required ? 'Enabled' : 'Disabled'}</span>
            </Descriptions.Item>

            {multifactorAuthentication.is_mfa_required && (
              <Descriptions.Item
                label={
                  <span className="flex items-center">
                    MFA Prompt Frequency
                    <Tooltip title="In some scenarios, you may not want to prompt the user for MFA each time they log in from the same browser.  Selecting this will allow your users to save their browser for 30 days.">
                      <InfoCircleOutlined className="ml-2" />
                    </Tooltip>
                  </span>
                }
              >
                <Radio.Group
                  options={mfaPromptFrequencyOptions}
                  onChange={(e) => handleMultifactorAuthentication(e, 'allow_remembering_devices')}
                  value={multifactorAuthentication.allow_remember_browser}
                />
              </Descriptions.Item>
            )}
          </>
        )}

        {auth0_organization_id && accountInfo?.client_id != me?.client_id ? (
          <Descriptions.Item label="Available Roles">{renderAvailableRoles()}</Descriptions.Item>
        ) : null}
        <Descriptions.Item label="Industry">{accountInfo?.Industry}</Descriptions.Item>
        <Descriptions.Item label="Billing Address">
          <div>{accountInfo?.BillingAddress?.street || ''}</div>
          <div>
            {`${accountInfo?.BillingAddress?.city || ''} ${accountInfo?.BillingAddress?.city ? ',' : ''} ${
              accountInfo?.BillingAddress?.state || ''
            } ${accountInfo?.BillingAddress?.postalCode || ''}`}
          </div>
        </Descriptions.Item>

        <Descriptions.Item label="Current Contract">
          {siteAdmin ? accountInfo?.CurrentContract?.Name : accountInfo?.Current_Contract__r?.Name}
        </Descriptions.Item>
        <Descriptions.Item label="Dates">
          {siteAdmin
            ? accountInfo?.CurrentContract?.StartDate && accountInfo?.CurrentContract?.EndDate
              ? moment(accountInfo.CurrentContract.StartDate).format('MMM DD, YYYY') +
                ' - ' +
                moment(accountInfo.CurrentContract.EndDate).format('MMM DD, YYYY')
              : 'N/A'
            : accountInfo?.Current_Contract__r?.StartDate && accountInfo?.Current_Contract__r?.EndDate
            ? moment(accountInfo.Current_Contract__r.StartDate).format('MMM DD, YYYY') +
              ' - ' +
              moment(accountInfo.Current_Contract__r.EndDate).format('MMM DD, YYYY')
            : 'N/A'}
        </Descriptions.Item>
        <Descriptions.Item label="Subscription">
          {siteAdmin
            ? FormatCurrency(accountInfo?.CurrentContract?.Total_Subscription_Fee__c)
            : FormatCurrency(accountInfo?.Current_Contract__r?.Total_Subscription_Fee__c)}
        </Descriptions.Item>
        <Descriptions.Item label="Active Users">
          {
            <span
              className="text-blue-500 cursor-pointer"
              onClick={() => {
                setAccountSettingsMenuState('userManagement');
                onUsersLinkClick && onUsersLinkClick();
              }}
            >
              {activeUsers} Active Users
            </span>
          }
        </Descriptions.Item>

        {auth0_organization_id ? (
          <Descriptions.Item label="Enabled Domains">
            {!siteAdmin ? (
              <>
                {emailDomains?.map((domain, idx) => (
                  <Tag key={idx + domain} color="#d9d9d9" className="text-base px-2 py-1 rounded-lg ml-2 text-black">
                    {domain}
                  </Tag>
                ))}
              </>
            ) : siteAdmin && !editMode ? (
              <>
                {emailDomains && emailDomains?.length > 0
                  ? emailDomains?.map((domain, idx) => (
                      <Tag
                        key={idx + domain}
                        color="#d9d9d9"
                        className="text-base px-2 py-1 rounded-lg ml-2 text-black"
                      >
                        {domain}
                      </Tag>
                    ))
                  : 'No client domain URL found'}
                <Button type="link" onClick={() => setEditMode(true)}>
                  {emailDomains && emailDomains?.length > 0 ? (
                    <>
                      <EditFilled />
                      Edit List
                    </>
                  ) : (
                    <>
                      <PlusOutlined />
                      Click here to add
                    </>
                  )}
                </Button>
              </>
            ) : (
              <div className="flex gap-2">
                <Form.Item name="client_email_domains" label={undefined} style={{ marginBlock: 0 }}>
                  <Select
                    mode="tags"
                    style={{ width: 650 }}
                    tokenSeparators={[',']}
                    placeholder="Enter email domain w/out '@'"
                  >
                    {emailDomains?.map((domain) => (
                      <Select.Option key={domain} id={domain}>
                        {domain}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Space>
                  <Button type="primary" htmlType="submit" loading={savingEmailDomains}>
                    Save
                  </Button>
                  <Button onClick={() => handleDomainEditCancel()}>Cancel</Button>
                </Space>
              </div>
            )}
          </Descriptions.Item>
        ) : null}

        {hasContract && !auth0_organization_id && updateEnabled ? (
          <Descriptions.Item label="Provisioning">
            <Button
              data-testid="provision-button"
              type="primary"
              size="large"
              icon={<ApiOutlined />}
              block
              loading={isProvisioningAccount}
              onClick={handleProvisionAccount}
            >
              Provision Online Account
            </Button>
          </Descriptions.Item>
        ) : null}

        {!siteAdmin && <Descriptions.Item label={undefined}>{renderMsg()}</Descriptions.Item>}
      </Descriptions>
    </Form>
  );
};
