import { useAxios } from 'hooks/useAxios';
import { AxiosErrorHandler } from 'shared/Helpers';
import { BASE_URL } from 'shared/constants';

const { VITE_APP_UNSECURE_ROUTE } = import.meta.env;

export const useExtraApi = () => {
  const { axios } = useAxios(BASE_URL);

  const getMe = async () => {
    try {
      const res = await axios.get(`/secure/me`);
      return res.data;
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  const updateMe = async (data: any) => {
    try {
      const res = await axios.put(`/secure/me`, data);
      return res.data;
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  const getSupplierDynamicsStats = async () => {
    try {
      const res = await axios.get(`${VITE_APP_UNSECURE_ROUTE}/supplierDynamics/stats`);
      return res.data;
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  const sendVerificationEmail = async () => {
    try {
      const res = await axios.post(`/secure/send-verification`);
      return res.data;
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  /**
   * Get File from API
   *
   * @param filename
   */
  const getFile = async (filename: string) => {
    const { data } = await axios.get(`/secure/file/${encodeURIComponent(filename)}`, {
      responseType: 'blob'
    });
    return data;
  };

  const getStatusLov = async () => {
    try {
      const res = await axios.get(`/secure/status/lov`);
      if (res.data?.status === 'success') {
        return res.data?.data;
      }
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  const getStatusLovUnsafe = async () => {
    try {
      const res = await axios.get(`/secure/status/lov`);
      if (res.data?.status === 'success') {
        return res.data?.data;
      }
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  return {
    getMe,
    updateMe,
    getSupplierDynamicsStats,
    sendVerificationEmail,
    getFile,
    getStatusLov,
    getStatusLovUnsafe
  };
};
