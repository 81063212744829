import { Button, message, Popconfirm, Table, TableColumnsType, TableProps, Typography } from 'antd';
import { File, UploadedFile } from 'models/Files';
import React, { useCallback, useMemo, useState } from 'react';
import millify from 'millify';
import moment from 'moment-timezone';
import { TIMEZONE } from 'shared/constants';
import { resolveMime } from 'friendly-mimes';
import { DeleteOutlined, FileAddOutlined } from '@ant-design/icons';
import { Collapse } from 'react-collapse';
import { UploadFilesDragger } from './UploadFilesDragger';
import { useContractApi } from 'api/contract';

interface Props extends Omit<TableProps<any>, 'id'> {
  id?: number;
  dataSource?: File[];
  onDelete: (file: File) => void;
  onChange: () => void;
}

export interface CreateManyResult {
  count: number;
}

export const FilesTable: React.FC<Props> = ({ id, dataSource, onDelete, onChange, ...rest }: Props) => {
  const [addFile, setAddFile] = useState(false);
  const [uploadedFileList, setUploadedFileList] = useState<UploadedFile[]>([]);

  // API
  const { attachContractFiles, removeFiles } = useContractApi();

  const columns: TableColumnsType<File> = useMemo(
    () => [
      {
        title: 'File',
        dataIndex: 'filename',
        render: (_, rec: File) => (
          <Typography.Link target="_blank" href={rec.url}>
            {rec.filename}
          </Typography.Link>
        )
      },
      {
        title: 'Type',
        dataIndex: 'type',
        render: (_, rec: File) => (rec.type ? resolveMime(rec.type).name : 'Unknown File Type')
      },
      {
        title: 'Size',
        dataIndex: 'size',
        render: (_, rec: File) => millify(rec.size)
      },
      {
        title: 'Date Added',
        dataIndex: 'date_added',
        render: (_, rec: File) => moment(rec.date_added).tz(TIMEZONE).format('ll')
      },
      {
        title: undefined,
        dataIndex: 'action',
        width: 60,
        align: 'center',
        render: (_, rec: File) => (
          <Popconfirm
            title={
              <div>
                Delete <span className="font-bold">{rec.filename}</span>?
              </div>
            }
            onConfirm={() => onDelete(rec)}
            placement={'topRight'}
          >
            <DeleteOutlined />
          </Popconfirm>
        )
      }
    ],
    [onDelete]
  );

  const handleUploadedFilelistChange = (uploadedFilelist: UploadedFile[]) => {
    setUploadedFileList(uploadedFilelist);
  };

  const handleOnAttachFilesToContract = useCallback(async () => {
    if (!id) return;
    const result: undefined | CreateManyResult = await attachContractFiles(id, uploadedFileList);
    if (result) {
      onChange();
      setAddFile(false);
      await message.success(`${result.count} files attached to deal.`);
    }
  }, [id, onChange, uploadedFileList]);

  const handleAddFilesButtonClick = async () => {
    if (addFile) {
      await onCancelHandler();
    }
    setAddFile(!addFile);
  };

  /**
   * On cancel, delete any files that were added
   */
  const onCancelHandler = async () => {
    setUploadedFileList([]);
    if (uploadedFileList.length > 0) {
      await removeFiles(uploadedFileList.map((file) => file.key));
    }
  };

  return (
    <div>
      <div className={'text-right mb-2'}>
        <Button type={'default'} icon={<FileAddOutlined />} onClick={handleAddFilesButtonClick}>
          {(!addFile && <>Add file(s)</>) || <>Cancel add file(s)</>}
        </Button>
      </div>
      <Collapse isOpened={!addFile}>
        <Table {...rest} rowKey={'id'} dataSource={dataSource} columns={columns} bordered={false} />
      </Collapse>
      <Collapse isOpened={addFile}>
        <UploadFilesDragger onUploadFilelistChange={handleUploadedFilelistChange} />
        <Button
          type="primary"
          size="large"
          className="mt-2"
          block
          onClick={handleOnAttachFilesToContract}
          disabled={uploadedFileList.length === 0}
        >
          Attach Files to Deal
        </Button>
      </Collapse>
    </div>
  );
};
