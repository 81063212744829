import { Button, Popover, TableColumnsType, Typography } from 'antd';
import { Inquiry } from 'sb/models/Inquiry';
import { useEffect, useMemo } from 'react';
import { FieldSorter } from 'sb/shared/Helpers';
import { User } from 'sb/models/User';
import moment from 'moment';
import { URGENCY_LOV } from 'sb/shared/constants';
import nl2br from 'react-nl2br';
import { useRecoilState } from 'recoil';
import { allAccountUsersState } from 'recoil/atoms';
import { useScopes } from 'hooks/useScopes';
import { useExtraApi } from 'sb/api/extra';

interface Props {
  onStatusClick?: (rec: Inquiry) => void;
  onSubjectClick?: (rec: Inquiry) => void;
}

export const InquiryTableColumnsAnalyst = ({ onStatusClick, onSubjectClick }: Props) => {
  const { aclCheck } = useScopes();
  const [npiUsers, setNpiUsers] = useRecoilState(allAccountUsersState);

  // API
  const { getAccountUsers } = useExtraApi();

  useEffect(() => {
    (async () => {
      if (npiUsers.length === 0) {
        const users = await getAccountUsers();
        if (Array.isArray(users)) {
          setNpiUsers(users);
        }
      }
    })();
  }, [npiUsers.length, setNpiUsers]);

  const columns: TableColumnsType<Inquiry> = useMemo(
    () => [
      {
        title: 'User',
        dataIndex: 'user',
        render: (_, rec: Inquiry) => rec.users.name || rec.users.first_name
      },
      {
        title: 'Client',
        dataIndex: 'client',
        render: (_, rec: Inquiry) => rec.users.client.display_name || rec.users.client.name
      },
      {
        title: 'Vendor',
        dataIndex: 'vendor',
        render: (_, rec: Inquiry) => (rec.vendor ? rec.vendor.name : '--')
      },
      {
        title: 'Subject',
        dataIndex: 'subject',
        render: (_, rec: Inquiry) => (
          <Popover
            title={<Typography.Title level={5}>{rec.subject}</Typography.Title>}
            content={nl2br(rec.note)}
            mouseEnterDelay={onSubjectClick ? 1 : 0.25}
            overlayStyle={{ width: 530 }}
          >
            <Button
              type={onSubjectClick ? 'link' : 'text'}
              size={'small'}
              style={{ fontSize: 13, paddingLeft: 0 }}
              onClick={onSubjectClick ? () => onSubjectClick(rec) : undefined}
            >
              {rec.subject}
            </Button>
          </Popover>
        )
      },
      {
        title: 'Urgency',
        dataIndex: 'urgency',
        render: (_, rec: Inquiry) =>
          URGENCY_LOV.filter((item) => {
            return item.value === rec.urgency;
          })[0].label
      },
      {
        title: 'Needed by',
        dataIndex: 'creation_date',
        sorter: (a: Inquiry, b: Inquiry) => FieldSorter(a.date_needed, b.date_needed),
        sortDirections: ['ascend', 'descend'],
        render: (_, rec: Inquiry) => (rec.date_needed ? moment(rec.date_needed).utc().format('ll') : '--')
      },
      {
        title: 'Status',
        dataIndex: 'status',
        render: (_, rec: Inquiry) => {
          if (aclCheck(['update:inquiry_admin', 'update:site_wide_admin'])) {
            return (
              <Button
                type={'link'}
                size={'small'}
                onClick={onStatusClick ? () => onStatusClick(rec) : undefined}
                className={'pl-0'}
              >
                <div className={'text-xs'}>
                  {rec.note_status.length ? rec.note_status[0].lov_status.name : 'Submitted'}
                </div>
              </Button>
            );
          } else {
            return rec.note_status.length ? rec.note_status[0].lov_status.name : 'Submitted';
          }
        }
      },
      {
        title: 'Assigned To',
        dataIndex: 'assigned_to',
        render: (_, rec: Inquiry) => {
          if (rec.note_status.length && npiUsers.length > 0) {
            return rec.note_status[0].users_note_status_assigned_toTousers
              ? npiUsers.filter((user: User) => {
                  return user.user_id === rec.note_status[0].users_note_status_assigned_toTousers?.auth0_user_id;
                })[0].name
              : '--';
          } else {
            return '--';
          }
        }
      },
      {
        title: 'Submitted',
        dataIndex: 'creation_date',
        sorter: (a: Inquiry, b: Inquiry) => FieldSorter(a.creation_date, b.creation_date),
        sortDirections: ['ascend', 'descend'],
        render: (_, rec: Inquiry) => (rec.creation_date ? moment(rec.creation_date).utc().format('lll') : '--')
      }
    ],
    [onSubjectClick, aclCheck, onStatusClick, npiUsers]
  );
  return columns;
};
