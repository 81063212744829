import { useAxios } from 'hooks/useAxios';
import { AxiosErrorHandler } from 'shared/Helpers';
import { ExcelCreateRequest } from 'models/ExcelJs';
import { BASE_URL } from 'shared/constants';

export const useExcelApi = () => {
  const { axios } = useAxios(BASE_URL);

  /**
   * Create Excel file
   * @param formData
   */
  const createExcel = async (formData: ExcelCreateRequest) => {
    try {
      const res = await axios.post(`/api/excel/create`, formData, {
        responseType: 'blob'
      });
      return res.data;
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  return {
    createExcel
  };
};
