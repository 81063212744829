import { useAxios } from 'hooks/useAxios';
import { AxiosErrorHandler } from 'shared/Helpers';
import { useMutation } from '@tanstack/react-query';
import { BASE_URL } from 'shared/constants';

export const useSupplierDynamicsApi = () => {
  const { axios } = useAxios(BASE_URL);

  /**
   * Fetch Deal Dynamic
   *
   * @param id
   */
  const fetchDealDynamic = async (id: number) => {
    try {
      const res = await axios.get(`/api/deal_dynamic/${id}`);
      if (res.data?.status === 'success') {
        return res.data.data;
      }
      return Promise.reject(Error(res.data.message));
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  /**
   * Inserts a supplier selection with the provided details.
   *
   * @param {number} [supplier_id] - The ID of the supplier to be selected.
   * @param {string} [vendor_name] - The name of the vendor.
   * @param {string} [vendor_domain] - The domain of the vendor.
   * @return {Promise<any>} A promise that resolves with the inserted supplier selection data if the operation is successful, or rejects with an error if the operation fails.
   */
  const insertSupplierSelection = async (
    supplier_id?: number | null,
    vendor_name?: string | null,
    vendor_domain?: string | null
  ): Promise<any> => {
    try {
      const res = await axios.post(`/api/supplierDynamics/supplier/selection`, {
        supplier_id,
        vendor_name,
        vendor_domain
      });
      if (res.data?.status === 'success') {
        return res.data.data;
      }
      return Promise.reject(Error(res.data.message));
    } catch (e) {
      await AxiosErrorHandler(e);
    }
  };

  /**
   * Fetch Supplier Deal Dynamic
   *
   * @param id
   * @param supplier_category_id
   */
  const fetchSupplierDealDynamics = async (id: number, supplier_category_id?: number) => {
    try {
      const res = await axios.get(`/api/supplier/${id}/deal_dynamics`, {
        params: { supplier_category_id }
      });
      if (res.data?.status === 'success') {
        return res.data.data;
      }
      return Promise.reject(Error(res.data.message));
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  /**
   * Fetch Supplier Deal Dynamic statistics
   *
   */
  const fetchSupplierDynamicsStats = async () => {
    try {
      const res = await axios.get(`/api/supplier_dynamics/statistics`);
      if (res.data?.status === 'success') {
        return res.data.data;
      }
      return Promise.reject(Error(res.data.message));
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  /**
   * Fetch Supplier Briefing
   *
   * @param id
   */
  const fetchSupplierBriefing = async (id: number) => {
    try {
      const res = await axios.get(`/api/supplier/${id}/briefing`);
      if (res?.data?.status === 'success') {
        return res?.data?.data;
      }
      return Promise.reject(Error(res?.data?.message || 'Failed to fetch supplier briefing'));
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  const fetchSupplierRiskDetails = async (id: number) => {
    try {
      const res = await axios.get(`/api/supplier/${id}/report?report_type=Risk`);
      if (res?.data?.status === 'success') {
        return res?.data?.data;
      }
      return Promise.reject(Error(res?.data?.message || 'Failed to fetch supplier risk details'));
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  const insertSupplierBriefingFeedback = async (id: number, score: number, feedback?: string) => {
    try {
      const res = await axios.post(`/api/supplier_briefing/${id}/feedback`, {
        score: score,
        feedback: feedback
      });
      if (res.data?.status === 'success') {
        return res.data.data;
      }
      return Promise.reject(Error(res.data.message));
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  const insertSupplierReportFeedback = async (id: number, score: number, feedback?: string) => {
    try {
      const res = await axios.post(`/api/supplier_report/${id}/feedback`, {
        score: score,
        feedback: feedback
      });
      if (res.data?.status === 'success') {
        return res.data.data;
      }
      return Promise.reject(Error(res.data.message));
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  /**
   * Fetch supplier notes
   * @param supplier_id
   */
  const fetchSupplierNotes = async (supplier_id: number) => {
    try {
      const res = await axios.get(`/api/supplier/${supplier_id}/notes`);
      if (res?.data?.status === 'success') {
        return res?.data.data;
      }
      return Promise.reject(Error(res?.data?.message || 'Failed to fetch supplier notes'));
    } catch (e) {
      AxiosErrorHandler(e);
      throw e;
    }
  };

  /**
   * Create supplier note
   */
  const createSupplierNote = async (
    supplierId: number,
    dealNote: { note: string; parent_id?: number; is_visible_to_npi: boolean }
  ) => {
    try {
      const url = `/api/supplier/${supplierId}/note`;
      const { data } = await axios.post(url, dealNote);
      return data;
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  /**
   * Delete supplier note
   */
  const deleteSupplierNote = async (supplierId: number, noteId: number) => {
    try {
      const url = `/api/supplier/${supplierId}/note/${noteId}`;
      const { data } = await axios.delete(url);
      return data;
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  /**
   * Update supplier note
   */
  const updateSupplierNote = async (
    supplierId: number,
    noteId: number,
    payload: { note: string; is_visible_to_npi: boolean }
  ) => {
    try {
      const url = `/api/supplier/${supplierId}/note/${noteId}`;
      const { data } = await axios.patch(url, payload);
      return data;
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  return {
    fetchDealDynamic,
    insertSupplierSelection,
    fetchSupplierDealDynamics,
    fetchSupplierDynamicsStats,
    fetchSupplierBriefing,
    fetchSupplierRiskDetails,
    insertSupplierBriefingFeedback,
    insertSupplierReportFeedback,
    fetchSupplierNotes,
    createSupplierNote,
    deleteSupplierNote,
    updateSupplierNote
  };
};

export const useSupplierBriefingFeedback = (config = {}) => {
  const { insertSupplierBriefingFeedback } = useSupplierDynamicsApi();
  return useMutation({
    mutationFn: async (data: { id: number; score: number; feedback?: string }) => {
      const { id, score, feedback } = data;
      return await insertSupplierBriefingFeedback(id, score, feedback);
    },
    ...config
  });
};

export const useSupplierReportFeedback = (config = {}) => {
  const { insertSupplierReportFeedback } = useSupplierDynamicsApi();
  return useMutation({
    mutationFn: async (data: { id: number; score: number; feedback?: string }) => {
      const { id, score, feedback } = data;
      return await insertSupplierReportFeedback(id, score, feedback);
    },
    ...config
  });
};
