import { cloneDeep, isNil, isNull, isNumber, padStart, remove, uniq } from 'lodash-es';
import { message } from 'antd';
import Bugsnag, { OnErrorCallback } from '@bugsnag/js';
import { closest } from 'fastest-levenshtein';
import moment from 'moment';
import { Fragment } from 'react';
import { ReportDetailsType, ReportLayoutDb, ReportLayoutElement } from '../models/Reports';


export const SelectFuzzyFieldValue = (str: string, fields: Array<any>) => {
  if (fields.length === 0) return undefined;
  const fieldsArray: Array<string> = [];
  for (let i = 0; i < fields.length; i++) {
    fieldsArray.push(fields[i].label);
  }
  const fuzzyMatch: string = closest(str, fieldsArray);

  const [match] = fields.filter((item: any) => item.label === fuzzyMatch);

  return match.value;
};

/**
 * @param event
 * @returns
 */
export const handleBugsnagError: OnErrorCallback = (event) => {
  console.error('Bugsnag error:', event);
  return event.errors?.[0].errorMessage !== 'ResizeObserver loop limit exceeded';
};

/**
 * Format currency
 *
 * @param value
 * @param maximumFractionDigits
 * @constructor
 * @return string | null;
 */
export const FormatCurrency = (value?: number | null, maximumFractionDigits?: number | undefined) => {
  if (isNil(value)) return null;
  
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: isNil(maximumFractionDigits) ? 2 : maximumFractionDigits
  }).format(value);
};

/**
 * FieldSorter usable for table string fields
 *
 * @param str_a
 * @param str_b
 * @constructor
 */
export const FieldSorter = (str_a?: string | null, str_b?: string | null) => {
  if (str_a && str_b) {
    return str_a.localeCompare(str_b);
  } else if (!str_a && str_b) {
    return -1;
  } else if (str_a && !str_b) {
    return 1;
  } else {
    return 0;
  }
};

/**
 * Sort Currency
 *
 * @param a
 * @param b
 * @constructor
 */
export const SortCurrency = (a: any, b: any) => {
  let first = '';
  let second = '';

  if (!isNull(a)) {
    const paddedA: string = (Math.round(a * 100) / 100).toFixed(2);
    first = padStart(paddedA.replace('.', ''), 12, '0');
  }

  if (!isNull(b)) {
    const paddedB: string = (Math.round(b * 100) / 100).toFixed(2);
    second = padStart(paddedB.replace('.', ''), 12, '0');
  }

  return first.localeCompare(second);
};

/**
 * Does object have property
 *
 * @param obj
 * @param prop
 */
export function hasOwnProperty<X extends Record<string, any>, Y extends PropertyKey>(
  obj: X,
  prop: Y
): obj is X & Record<Y, unknown> {
  // eslint-disable-next-line no-prototype-builtins
  return obj.hasOwnProperty(prop);
}

/**
 * Currency Parser
 *
 * @param val
 */
// export const CurrencyParser = (val: any) => {
//     try {
//         // for when the input gets clears
//         if (typeof val === "string" && !val.length) {
//             val = "0.0";
//         }
//
//         // detecting and parsing between comma and dot
//         const group = new Intl.NumberFormat(locale).format(1111).replace(/1/g, "");
//         const decimal = new Intl.NumberFormat(locale).format(1.1).replace(/1/g, "");
//         let reversedVal = val.replace(new RegExp("\\" + group, "g"), "");
//         reversedVal = reversedVal.replace(new RegExp("\\" + decimal, "g"), ".");
//         //  => 1232.21 €
//
//         // removing everything except the digits and dot
//         reversedVal = reversedVal.replace(/[^0-9.]/g, "");
//         //  => 1232.21
//
//         // appending digits properly
//         const digitsAfterDecimalCount = (reversedVal.split(".")[1] || []).length;
//         const needsDigitsAppended = digitsAfterDecimalCount > 2;
//
//         if (needsDigitsAppended) {
//             reversedVal = reversedVal * Math.pow(10, digitsAfterDecimalCount - 2);
//         }
//
//         return Number.isNaN(reversedVal) ? 0 : reversedVal;
//     } catch (error) {
//         console.error(error);
//     }
// };

/**
 * Currency Formatter
 *
 */
// export const CurrencyFormatter = () => (value: any) => {
//     return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
// }

/**
 *
 * @param val
 * @return string
 * @constructor
 */
export const DecimalString = (val: string | number) => {
  return val.toString() !== ''
    ? val
        .toString()
        .replace(/[^0-9.-]/g, '')
        .replace('.', 'x')
        .replace(/\./g, '')
        .replace('x', '.')
        .replace(/(?!^)-/g, '')
    : '';
};

/**
 * Convert number string to number
 *
 * @param value
 * @return number | null
 * @constructor
 */
export const ToNumber = (value?: string | number | null) => {
  if (isNil(value)) return null;
  if (isNumber(value)) return value;

  const cleanedNumber = value.replace(/\(/, '-').replace(/[^0-9.-]/g, '');

  return Number.isNaN(cleanedNumber) || cleanedNumber === '-' ? null : +cleanedNumber;
};

/**
 * Extract simple array from an array of objects.
 *
 * @param arr
 * @param column
 * @constructor
 */
export const ExtractColumn = (arr: Array<any>, column: string) => arr.map((x: any) => x[column]);

export const skusToArray = (skusString: string) => {
  const skus: Array<string> = skusString.replace(/ /g, '').replace(/\r?\n/g, ',').split(',');
  remove(skus, (sku: string) => sku === '');
  return uniq(skus);
};

export const getMonthDaySelectOptions = () => {
  const days = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  const numToDate = (num: number) => (num + 1).toString().padStart(2, '0');

  return moment.monthsShort().map((month, index) => ({
    value: numToDate(index),
    label: month,
    children: Array(days[index])
      .fill(0)
      .map((_, day) => {
        const str = numToDate(days[index] - day - 1);
        return { value: str, label: str };
      })
  }));
};

/**
 * New line to <br />
 * @param str
 */
export const nl2br = (str: string) => {
  if (!str) return '';
  return str.split('\n').map((item: string, key: number) => {
    return (
      <Fragment key={key}>
        {item}
        <br /> <br />
      </Fragment>
    );
  });
};

/**
 * Updates the layout of a report based on a list of report templates.
 * @param {ReportDetailsType} report - The report to update.
 * @param {ReportLayoutDb[]} reportTemplates - The list of report templates.
 * @returns {undefined | ReportDetailsType} - The updated report, or undefined if the report or templates are invalid.
 */
export const updateReportLayout = (
  report: ReportDetailsType,
  reportTemplates: ReportLayoutDb[]
): undefined | ReportDetailsType => {
  if (!reportTemplates || !report || !report.layout) return;

  const _report = cloneDeep(report);
  const [template] = reportTemplates.filter((tmpl: ReportLayoutDb) => report.layout?.id === tmpl.id);
  if (!template) return;

  _report.layout?.topElements?.map((element: ReportLayoutElement) => {
    if (template && element.report_element_template.level === 'top') {
      const [elementTemplate] = template.topElements.filter(
        (template: ReportLayoutElement) => template.report_element_template.id === element.report_element_template.id
      );
      Object.assign(element.report_element_template, {
        name: elementTemplate.report_element_template.name,
        type: elementTemplate.report_element_template.type,
        component_name: elementTemplate.report_element_template.component_name
      });
    } else if (template && element.report_element_template.level === 'findings') {
      const [elementTemplate] = template.findingsElements.filter(
        (template: ReportLayoutElement) => template.report_element_template.id === element.report_element_template.id
      );
      Object.assign(element.report_element_template, {
        name: elementTemplate.report_element_template.name,
        type: elementTemplate.report_element_template.type,
        component_name: elementTemplate.report_element_template.component_name
      });
    }
    return element;
  });
  return _report;
};

/**
 * Check for empty string
 *
 * @param data
 */
export const isEmptyString = (data: string): boolean => data.trim().length == 0;

export const getCommentDateTime = (datetime: Date) => {
  const now = moment();
  const timePosted = moment(datetime);
  const diff = now.diff(timePosted, 'hours');
  return diff <= 24 ? moment(datetime).utc().fromNow(false) : moment(datetime).utc().format('lll');
};
