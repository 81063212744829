import { PinpointStep } from 'models/Pinpoint';

const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

// ENVIRONMENT VARIABLES
export const TIMEZONE = timeZone;
export const APP_NAME = import.meta.env.VITE_APP_APP_NAME || 'Vantage™';
export const BASE_URL = import.meta.env.VITE_APP_EXPRESS_URL;
export const NESTJS_BASE_URL: boolean = import.meta.env.VITE_APP_NESTJS_BASE_URL;
export const ENABLE_SD_PRODUCTS = import.meta.env.VITE_APP_ENABLE_SD_PRODUCTS === 'true';
export const ANONYMOUS_CLIENT_NAME = import.meta.env.VITE_APP_ANONYMOUS_CLIENT_NAME || 'Acme Industries';
export const ANONYMOUS_CLIENT_SHORT_NAME = import.meta.env.VITE_APP_ANONYMOUS_CLIENT_SHORT_NAME || 'AW';
export const ANONYMOUS_CLIENT_EMAIL = import.meta.env.VITE_APP_ANONYMOUS_CLIENT_EMAIL || 'kschmidt@acmewidgets.com';
export const ANONYMOUS_CLIENT_USER = import.meta.env.VITE_APP_ANONYMOUS_CLIENT_USER || 'Kelly Schmidt';
export const ENV = import.meta.env.VITE_APP_ENV;
export const HANDS_ON_TABLE_LICENSE =
  import.meta.env.VITE_APP_HANDS_ON_TABLE_LICENSE || 'edd3b-d2be2-46101-84b40-d012d';
export const CLEAR_SITE_DATA_FAQ =
  import.meta.env.VITE_APP_CLEAR_SITE_DATA_FAQ ||
  'https://npi-vantage.freshdesk.com/en/support/solutions/articles/153000189754-clearing-vantage-site-data';
export const AUTH0_TOKEN_URL = import.meta.env.VITE_APP_AUTH0_TOKEN_URL;
export const CLEARBIT_LOGO_BASE_URL = import.meta.env.VITE_APP_CLEARBIT_LOGO_BASE_URL || 'https://logo.clearbit.com';
// Additional Constants
export const FILE_UPLOAD_SIZE_LIMIT = import.meta.env.VITE_APP_FILE_UPLOAD_SIZE_LIMIT
  ? Number(import.meta.env.VITE_APP_FILE_UPLOAD_SIZE_LIMIT)
  : 100000000;
export const BUGSNAG_APIKEY = import.meta.env.VITE_APP_BUGSNAG_APIKEY || '';
// Userflow token - falls back to QA token if none is provided
export const USERFLOW_TOKEN = import.meta.env.VITE_APP_USERFLOW_TOKEN || 'ct_nkelpakocncnrmabcn5f2vo3qm';
export const ADD_BULK_DEAL_TEMPLATE =
  import.meta.env.VITE_APP_ADD_BULK_DEAL_TEMPLATE ||
  'https://s3.us-east-2.amazonaws.com/public.npi-digital.com/assets/NPI-Add-Bulk-Deals-Template.xlsx';
export const AUTH0_CONFIG = {
  audience: import.meta.env.VITE_APP_AUTH0_AUDIENCE || '',
  clientId: import.meta.env.VITE_APP_AUTH0_CLIENT_ID || '',
  domain: import.meta.env.VITE_APP_AUTH0_DOMAIN || ''
};

export const scrollOptions = {
  duration: 1200,
  smooth: 'easeOutSine',
  offset: -50
};

export const dealTypes = {
  RENEWAL: 'Renewal',
  NEW_PURCHASE: 'New purchase'
};

export const dealTypeOptions = [
  { label: 'Renewals', value: dealTypes.RENEWAL },
  { label: 'New Contracts', value: dealTypes.NEW_PURCHASE }
];

export const DEFAULT_COLORS = [
  '#2f7ed8',
  '#0d233a',
  '#8bbc21',
  '#910000',
  '#1aadce',
  '#492970',
  '#f28f43',
  '#77a1e5',
  '#c42525',
  '#a6c96a'
];

export const COLOR_SCALE_FIVE_ASC = ['#7abd7e', '#8cd47e', '#f8d66d', '#ffb54c', '#ff6961'];

export const URGENCY_LOV = [
  { value: 0, label: 'Highest' },
  { value: 1, label: 'High' },
  { value: 2, label: 'Medium' },
  { value: 3, label: 'Low' },
  { value: 4, label: 'Lowest' }
];

export const URGENCY_SLIDER_VALUES = [
  { value: 0, key: 0 },
  { value: 1, key: 25 },
  { value: 2, key: 50 },
  { value: 3, key: 75 },
  { value: 4, key: 100 }
];

export const SLIDER_MARKS = {
  0: 'Low',
  25: ' ',
  50: 'Medium',
  75: ' ',
  100: 'High'
};

export const COMMUNITY_GUIDELINES = [
  'Keep online conversations professional and appropriate at all times.',
  'Refrain from promoting specific vendors or services, as this is a professional community.',
  'Keep discussions focused on the discussed topics and refrain from introducing new topics or subjects.',
  'Refrain from sharing confidential details or confidential documents without the express consent of the relevant parties.',
  'Notify a moderator or administrator if you encounter any hateful, malicious, or inappropriate behavior.',
  'Follow the instructions and guidance of the moderator team at all times.'
];

export const PINPOINT_BUDGET_STEPS: Array<PinpointStep> = [
  { value: 1, label: 'Select Supplier', completed: false },
  { value: 2, label: 'Select Product', completed: false }
];

export const PINPOINT_PRICE_CHECK_STEPS: Array<PinpointStep> = [
  { value: 1, label: 'Select Supplier', completed: false },
  { value: 2, label: 'Select Product', completed: false },
  { value: 3, label: 'Enter your quoted price, quantity and total annual contract value', completed: false }
];

export const RENEWAL_RECORD_TYPE = {
  ROOT: 'root',
  SUPPLIER_DEAL: 'supplierDeal',
  SUPPLIER: 'supplier',
  DEAL: 'deal',
  TASK: 'task'
};

export const DYNAMIC_COLUMNS = {
  VENDOR_COMPLEXITY: 'vendorComplexity',
  PRICING_FLEXIBILITY: 'pricingFlexibility',
  DEAL_SIZE_TREND: 'dealSizeTrend'
};
export const DEAL_DOCUMENTS = {
  PREVIEW_NOT_AVAILABLE: 'Preview not available',
  PLEASE_DOWNLOAD_THE_FILE_HERE: 'Please download the file here',
  UPLOADED_DOCUMENTS: 'Uploaded Documents',
  UPLOAD_DOCUMENTS: 'Upload Documents',
  PLEASE_SELECT_ANY_FILE_TO_PREVIEW: 'Please select any file to Preview'
};

export const PURCHASE_CHARACTERISTICS_THREE_COLORS = ['#a1cd6c', '#ffc106', '#ee7267'];
export const PURCHASE_CHARACTERISTICS_VALUE = ['Low', 'Medium', 'High'];
export const PURCHASE_CHARACTERISTICS_EIGHT_COLORS = [
  '#6d73c7',
  '#57a89e',
  '#abcb79',
  '#f8d354',
  '#f8d354',
  '#f19e52',
  '#ed7267',
  '#8f4341'
];

export const DEAL_SIZE_TREND_CHARACTERISTICS = {
  TRENDING_DOWNWARDS_TEXT: 'Trending Downwards',
  STABLE_TEXT: 'Stable',
  TRENDING_UPWARDS_TEXT: 'Trending Upwards'
};

export const RENEWAL_ZONE_CONTENT =
  'The "Red Zone" is a critical period when an IT contract is nearing its renewal date, indicating that organizations should be taking immediate action to ensure favorable terms and timely closure. The "Orange Zone" signifies an earlier phase, indicating that a contract renewal is approaching, giving organizations a heads-up to start preparing and strategizing well in advance.';

export const ckeditorToolbarOptions = [
  'heading',
  '|',
  'fontSize',
  'fontFamily',
  '|',
  'fontColor',
  'fontBackgroundColor',
  '|',
  'bold',
  'italic',
  'underline',
  '|',
  'textAlignment',
  '|',
  'numberedList',
  'bulletedList',
  '|',
  'decreaseIndent',
  'increaseIndent',
  '|',
  'link',
  'blockQuote',
  'insertTable',
  '|',
  'undo',
  'redo'
];
export const TRUSTED_PARTNER = import.meta.env.VITE_APP_TRUSTED_PARTNER ?? 'Plus';
