import { useAxios } from 'hooks/useAxios';
import { AxiosErrorHandler } from 'shared/Helpers';
import { useMutation, UseMutationResult, useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import { Accuracy, EditAccuracyPayload, ProductAccuracy } from 'sb/models/Product';
import { AxiosError } from 'axios';
import { BASE_URL } from 'sb/shared/constants';

export const useProductAccuracyApi = () => {
  const { axios } = useAxios(BASE_URL);

  const getSupplierProductAccuracy = async (supplierId: number): Promise<ProductAccuracy[]> => {
    try {
      const res = await axios.get(`/secure/supplier/${supplierId}/products/accuracy`);
      return res.data?.data;
    } catch (e) {
      AxiosErrorHandler(e);
      throw e;
    }
  };

  const editProductAccuracy = async (id: number, data: Partial<Accuracy>): Promise<Accuracy> => {
    try {
      const res = await axios.patch(`/secure/contract_transaction/${id}/accuracy`, data);
      return res.data?.data;
    } catch (e) {
      AxiosErrorHandler(e);
      throw e;
    }
  };

  return {
    getSupplierProductAccuracy,
    editProductAccuracy
  };
};

export const useSupplierProductAccuracy = (
  supplierId: number,
  config: any
): UseQueryResult<ProductAccuracy[], AxiosError> => {
  const { getSupplierProductAccuracy } = useProductAccuracyApi();
  return useQuery({
    queryKey: ['productAccuracy', supplierId],
    queryFn: async () => await getSupplierProductAccuracy(supplierId),
    ...config
  });
};

export const useEditProductAccuracy = (config: any = {}): UseMutationResult<Accuracy, AxiosError> => {
  const { editProductAccuracy } = useProductAccuracyApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (
      data: EditAccuracyPayload & {
        transaction_id: number;
        supplierId: number;
      }
    ) => {
      const { transaction_id, supplierId, ...rest } = data;
      const editedAccuracy = await editProductAccuracy(transaction_id, rest);
      await queryClient.invalidateQueries(['productAccuracy', supplierId]);

      return editedAccuracy;
    },
    ...config
  });
};
