import { selector } from 'recoil';
import { uiState, vendorState } from './atoms';

export const selectedVendor = selector({
  key: 'selectedVendor',
  get: ({ get }) => get(vendorState).selected
});

export const menuKeyState = selector({
  key: 'menuKeyState',
  get: ({ get }) => get(uiState).menuKey
});
