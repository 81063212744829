import { useAxios } from 'hooks/useAxios';
import { AxiosErrorHandler } from 'shared/Helpers';
import { BASE_URL } from 'shared/constants';

export const useFileApi = () => {
  const { axios } = useAxios(BASE_URL);

  const uploadFile = async (formData: any) => {
    try {
      const res = await axios.post(`/secure/file/upload`, formData);
      return res.data;
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  /**
   * Upload file to S3
   *
   * @param formData
   */
  const uploadFileToS3 = async (formData: any) => {
    try {
      const res = await axios.post(`/secure/s3`, formData, {
        transformRequest: () => formData
      });
      if (res.data?.status === 'success') {
        return res.data?.data;
      }
      return Promise.reject(Error(res.data?.message));
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  /**
   * Upload file to S3
   *
   * @param filename
   */
  const deleteFileFromS3 = async (filename: string) => {
    try {
      const res = await axios.delete(`/secure/s3/${filename}`);
      if (res.data?.status === 'success') {
        return res.data?.data;
      }
      return Promise.reject(Error(res.data?.message));
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  /**
   * Upload file to S3
   *
   * @param filename
   */
  const getFileUrlFromS3 = async (filename: string) => {
    try {
      const res = await axios.get(`/api/file/url`, {
        params: { filename }
      });
      if (res.data?.status === 'success') {
        return res.data?.data;
      }
      return Promise.reject(Error(res.data?.message));
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  return {
    uploadFile,
    uploadFileToS3,
    deleteFileFromS3,
    getFileUrlFromS3
  };
};
