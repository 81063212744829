import { useAxios } from 'hooks/useAxios';
import { AxiosErrorHandler } from 'shared/Helpers';
import { useMutation, useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { IBannerAd, CreateBannerAd } from 'types/SiteAdministration';
import { BASE_URL } from 'shared/constants';

export const useSiteAdministrationApi = () => {
  const { axios } = useAxios(BASE_URL);

  const fetchBannerAds = async (all: boolean): Promise<IBannerAd[]> => {
    try {
      const endpoint = all ? '/api/banners?all=true' : '/api/banners';
      const res = await axios.get(endpoint);
      if (res.data?.status === 'success') {
        return res.data.data;
      }
      return Promise.reject(Error(res?.data?.message || 'Failed to fetch banner ads'));
    } catch (e) {
      AxiosErrorHandler(e);
      throw e;
    }
  };

  const createBannerAd = async (banner: FormData): Promise<IBannerAd> => {
    try {
      const res = await axios.post(`/api/banners`, banner, {
        transformRequest: () => banner
      });
      if (res.data?.status === 'success') {
        return res.data;
      }
      return Promise.reject(Error(res?.data?.message || 'Failed to create banner ad'));
    } catch (e) {
      AxiosErrorHandler(e);
      throw e;
    }
  };

  const editBannerAd = async (id: number, payload: Partial<CreateBannerAd>): Promise<IBannerAd> => {
    try {
      const res = await axios.patch(`/api/banners/${id}`, payload);
      if (res.data?.status === 'success') {
        return res.data;
      }
      return Promise.reject(Error(res?.data?.message || 'Failed to edit banner ad'));
    } catch (e) {
      AxiosErrorHandler(e);
      throw e;
    }
  };

  const deleteBannerAd = async (id: number): Promise<void> => {
    try {
      const res = await axios.delete(`/api/banners/${id}`);
      if (res.data?.status === 'success') {
        return;
      }
      return Promise.reject(Error(res?.data?.message || 'Failed to delete banner ad'));
    } catch (e) {
      AxiosErrorHandler(e);
      throw e;
    }
  };

  return {
    fetchBannerAds,
    createBannerAd,
    editBannerAd,
    deleteBannerAd
  };
};

export const useDeleteBannerAd = (config = {}) => {
  const { deleteBannerAd } = useSiteAdministrationApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (id: number) => {
      const article = await deleteBannerAd(id);
      await queryClient.invalidateQueries(['banners']);
      return article;
    },
    ...config
  });
};

export const useEditBannerAd = (config = {}) => {
  const { editBannerAd } = useSiteAdministrationApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ id, data }: { id: number; data: Partial<CreateBannerAd> }) => {
      const banner = await editBannerAd(id, data);
      await queryClient.invalidateQueries(['banners']);
      return banner;
    },
    ...config
  });
};

export const useCreateBannerAd = (config = {}) => {
  const { createBannerAd } = useSiteAdministrationApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: FormData) => {
      const banner = await createBannerAd(data);
      await queryClient.invalidateQueries(['banners']);
      return banner;
    },
    ...config
  });
};

export const useBannerAds = (all: boolean, config = {}): UseQueryResult<IBannerAd[], AxiosError> => {
  const { fetchBannerAds } = useSiteAdministrationApi();
  const queryClient = useQueryClient();
  return useQuery({
    queryKey: ['banners'],
    queryFn: async (): Promise<IBannerAd[]> => {
      const banners = await fetchBannerAds(all);
      banners.forEach((banner) => {
        queryClient.setQueryData(['banners', banner.id], banner);
      });

      return banners;
    },
    ...config
  });
};

export const useBannerAd = (id: number, config = {}): UseQueryResult<IBannerAd, AxiosError> => {
  const queryClient = useQueryClient();
  return useQuery({
    queryKey: ['banners', id],
    queryFn: () => {
      return queryClient.getQueryData(['banners', id]);
    },
    ...config
  });
};
