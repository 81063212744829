import { useMemo } from 'react';
import { ToolbarConfig } from '@bryntum/gantt';

interface Props {
  onButtonClick: (
    method:
      | 'expandAll'
      | 'collapseAll'
      | 'zoomIn'
      | 'zoomOut'
      | 'zoomToFit'
      | 'shiftPrevious'
      | 'shiftNext'
      | 'exportExcel'
  ) => void;
  hideExportButton?: boolean;
}

export const GanttChartToolbarItems = ({ onButtonClick, hideExportButton }: Props) => {
  const items: ToolbarConfig['items'] = useMemo(
    () => [
      {
        type: 'buttonGroup',
        items: [
          {
            ref: 'expandAllButton',
            icon: 'b-fa b-fa-file-excel',
            text: 'EXPORT',
            tooltip: 'Export View To Excel',
            hidden: hideExportButton,
            onAction: () => onButtonClick('exportExcel')
          }
        ]
      },
      {
        type: 'buttonGroup',
        items: [
          {
            ref: 'expandAllButton',
            icon: 'b-fa b-fa-angle-double-down',
            tooltip: 'Expand all',
            onAction: () => onButtonClick('expandAll')
          },
          {
            ref: 'collapseAllButton',
            icon: 'b-fa b-fa-angle-double-up',
            tooltip: 'Collapse all',
            onAction: () => onButtonClick('collapseAll')
          }
        ]
      },
      {
        type: 'buttonGroup',
        items: [
          {
            ref: 'zoomInButton',
            icon: 'b-fa b-fa-search-plus',
            tooltip: 'Zoom in',
            onAction: () => onButtonClick('zoomIn')
          },
          {
            ref: 'zoomOutButton',
            icon: 'b-fa b-fa-search-minus',
            tooltip: 'Zoom out',
            onAction: () => onButtonClick('zoomOut')
          },
          {
            ref: 'zoomToFitButton',
            icon: 'b-fa b-fa-compress-arrows-alt',
            tooltip: 'Zoom to fit',
            onAction: () => onButtonClick('zoomToFit')
          },
          {
            ref: 'previousButton',
            icon: 'b-fa b-fa-angle-left',
            tooltip: 'Previous time span',
            onAction: () => onButtonClick('shiftPrevious')
          },
          {
            ref: 'nextButton',
            icon: 'b-fa b-fa-angle-right',
            tooltip: 'Next time span',
            onAction: () => onButtonClick('shiftNext')
          }
        ]
      }
    ],
    [onButtonClick]
  );
  return items;
};
