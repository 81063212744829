import { useAxios } from 'hooks/useAxios';
import { AxiosErrorHandler } from 'shared/Helpers';
import { Permission, PermissionMethods } from 'sb/models/Permission';
import { BASE_URL } from 'sb/shared/constants';

export const useAdminApi = () => {
  const { axios } = useAxios(BASE_URL);

  const getAdminModeratedContent = async (isReleased: 0 | 1) => {
    try {
      const res = await axios.get(`/secure/admin/moderated_content?is_released=${isReleased}`);
      if (res.data.status === 'error') {
        throw new Error(res.data.message);
      }
      return res.data;
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  const getAdminModeratedReviews = async (isReleased: 0 | 1) => {
    try {
      const res = await axios.get(`/secure/admin/moderated_reviews?is_released=${isReleased}`);
      if (res.data.status === 'error') {
        throw new Error(res.data.message);
      }
      return res.data;
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  const updateAdminNoteReleased = async (id: number, isReleased: 0 | 1) => {
    try {
      const res = await axios.post(`/secure/admin/note/${id}/is_released`, { is_released: isReleased });
      return res.data;
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  const updateAdminReviewReleased = async (id: number, isReleased: 0 | 1) => {
    try {
      const res = await axios.post(`/secure/admin/review/${id}/is_released`, { is_released: isReleased });
      return res.data;
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  /**
   * Fetch user from Auth0
   *
   * @param auth0_user_id
   */
  const fetchAuth0User = async (auth0_user_id: string) => {
    try {
      const url = `/auth0/user/${auth0_user_id}`;
      const { data } = await axios.get(url);
      return data;
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  const getPermissions = async () => {
    try {
      const res = await axios.get(`/secure/admin/permissions`);
      return res.data;
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  const updatePermission = async (oldP: Partial<Permission>, newP: Partial<Permission>) => {
    const methods = [
      PermissionMethods.create,
      PermissionMethods.read,
      PermissionMethods.update,
      PermissionMethods.delete
    ];
    try {
      const res = await axios.put('/secure/admin/permissions', {
        old: { name: oldP.name, methods: methods.filter((method) => oldP?.[method]) },
        new: { name: newP.name, methods: methods.filter((method) => newP?.[method]) }
      });
      return res.data;
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  const deletePermission = async (scope: string) => {
    try {
      const res = await axios.delete(`/secure/admin/permissions/${scope}`);
      return res.data;
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  const getRoles = async () => {
    try {
      const res = await axios.get(`/secure/admin/roles`);
      return res.data;
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  const createRole = async (data: {
    name: string;
    description: string;
    is_client_role: boolean;
    permissions: number[];
  }) => {
    try {
      const res = await axios.post(`/secure/admin/role`, data);
      return res.data;
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  const updateRole = async (
    id: number,
    data: { name: string; description: string; is_client_role: boolean; permissions: number[] }
  ) => {
    try {
      const res = await axios.put(`/secure/admin/role/${id}`, data);
      return res.data;
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  const deleteRole = async (id: number) => {
    try {
      const res = await axios.delete(`/secure/admin/role/${id}`);
      return res.data;
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  return {
    getAdminModeratedContent,
    getAdminModeratedReviews,
    updateAdminNoteReleased,
    updateAdminReviewReleased,
    fetchAuth0User,
    getPermissions,
    updatePermission,
    deletePermission,
    getRoles,
    createRole,
    updateRole,
    deleteRole
  };
};
