import { useAxios } from 'hooks/useAxios';
import { AxiosErrorHandler } from 'shared/Helpers';
import { InquiryForm, InquiryStatusForm } from 'sb/models/Inquiry';
import { BASE_URL } from 'sb/shared/constants';

export const useNoteApi = () => {
  const { axios } = useAxios(BASE_URL);

  /**
   * Fetch inquiries
   *
   */
  const selectInquiries = async () => {
    try {
      const res = await axios.get(`/secure/admin/inquiries`);
      if (res.data?.status === 'success') {
        return res.data?.data;
      }
      return Promise.reject(Error(res.data?.message));
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  /**
   * Fetch assigned inquiries
   *
   */
  const selectAssignedInquiries = async () => {
    try {
      const res = await axios.get(`/secure/admin/inquiries/assigned`);
      if (res.data?.status === 'success') {
        return res.data?.data;
      }
      return Promise.reject(Error(res.data?.message));
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  /**
   * Select Inquiry
   *
   */
  const selectInquiry = async (id: number) => {
    try {
      const res = await axios.get(`/secure/admin/inquiry/${id}`);
      if (res.data?.status === 'success') {
        return res.data?.data;
      }
      return Promise.reject(Error(res.data?.message));
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  /**
   * Inssert Inquiry Status record
   *
   * @param id
   * @param data
   */
  const insertInquiryStatus = async (id: number, data: InquiryStatusForm) => {
    try {
      const res = await axios.post(`/secure/admin/inquiry/${id}/status`, data);
      if (res.data?.status === 'success') {
        return res.data?.data;
      }
      return Promise.reject(Error(res.data?.message));
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  /**
   * Insert Inquiry Reply
   *
   * @param id
   * @param data
   */
  const insertInquiryReply = async (id: number, data: { note: string; vendor_id?: number }) => {
    try {
      const res = await axios.post(`/secure/admin/inquiry/${id}/reply`, data);
      if (res.data?.status === 'success') {
        return res.data?.data;
      }
      return Promise.reject(Error(res.data?.message));
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  /**
   * Insert Inquiry
   *
   * @param data
   */
  const insertInquiry = async (data: InquiryForm) => {
    try {
      const res = await axios.post(`/secure/admin/inquiry`, data);
      if (res.data?.status === 'success') {
        return res.data?.data;
      }
      return Promise.reject(Error(res.data?.message));
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  return {
    selectInquiries,
    selectAssignedInquiries,
    selectInquiry,
    insertInquiryStatus,
    insertInquiryReply,
    insertInquiry
  };
};
