import { useAxios } from 'hooks/useAxios';
import { AxiosErrorHandler } from 'shared/Helpers';
import { Vendor } from 'sb/models/vendor';
import { PriceList } from 'sb/models/PriceList';
import { BASE_URL } from 'sb/shared/constants';

const { VITE_APP_UNSECURE_ROUTE } = import.meta.env;

export const useVendorApi = () => {
  const { axios } = useAxios(BASE_URL);

  const searchVendors = async (reseller: boolean, str: string, limitToVendorDynamics?: boolean) => {
    try {
      const res = await axios.get(
        `/${VITE_APP_UNSECURE_ROUTE}/${!reseller ? 'vendors' : 'resellers'}/search?str=${str}${
          limitToVendorDynamics !== undefined ? `&vdi=${+limitToVendorDynamics}` : ''
        }`
      );
      return res.data;
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  /**
   * Select supplier info
   *
   * @param id
   * @return
   */
  const getVendor = async (id: number) => {
    try {
      const res = await axios.get(`${VITE_APP_UNSECURE_ROUTE}/vendor/${id}`);
      return res.data;
    } catch (e) {
      return e;
    }
  };

  /**
   * Select vendor details
   *
   * @param id
   * @return
   */
  const getVendorDetails = async (id: number) => {
    try {
      const res = await axios.get(`/secure/vendors/${id}`);
      if (res.data?.status === 'success') {
        return res.data?.data;
      }
    } catch (e) {
      return e;
    }
  };

  const getVendors = async (str?: string) => {
    try {
      const res = await axios.get(str ? `/secure/vendors?str=${str}` : `/secure/vendors`);
      if (res.data?.status === 'success') {
        return res.data?.data;
      }
      throw new Error(res.data?.message);
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  const createVendor = async (values: Omit<Vendor, 'id'>) => {
    try {
      const res = await axios.post(`/secure/vendor`, values);
      if (res.data?.status === 'success') {
        return res.data?.data;
      }
      throw new Error(res.data?.message);
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  const updateVendor = async (id: number, values: Partial<Vendor>) => {
    try {
      const res = await axios.patch(`/secure/vendor/${id}`, values);
      if (res.data?.status === 'success') {
        return res.data?.data;
      }
      throw new Error(res.data?.message);
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  const getVendorCategories = async (id: number) => {
    try {
      const res = await axios.get(`${VITE_APP_UNSECURE_ROUTE}/vendor/${id}/categories`);
      return res.data;
    } catch (e) {
      return e;
    }
  };

  const getVendorContracts = async (id: string) => {
    try {
      const res = await axios.get(`/secure/vendor/${id}/contracts`);
      return res.data;
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  const getVendorProducts = async (id: number, str: string, unverified?: boolean) => {
    try {
      const res = await axios.get(
        `/secure/vendor/${id}/products/search?str=${str}${unverified ? '&is_confirmed=0' : ''}`
      );
      if (res.data?.status === 'error') {
        throw new Error(res.data?.message);
      }
      return res.data;
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  const mergeVendorProducts = async (id: number, data: any) => {
    try {
      const res = await axios.post(`/secure/vendor/${id}/products/merge`, data);
      if (res.data?.status === 'error') {
        throw new Error(res.data?.message);
      }
      return res.data;
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  const updateVendorProduct = async (id: number, productId: number, data: any) => {
    try {
      const res = await axios.patch(`/secure/vendor/${id}/product/${productId}`, data);
      if (res.data?.status === 'error') {
        throw new Error(res.data?.message);
      }
      return res.data;
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  const updateVendorProductConfirmed = async (id: number, productId: number, isConfirmed: boolean) => {
    try {
      const res = await axios.put(`/secure/vendor/${id}/product/${productId}/is_confirmed`, {
        is_confirmed: isConfirmed
      });
      return res.data;
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  const updateVendorProductCategories = async (id: number, productId: number, categories: any[]) => {
    try {
      const res = await axios.put(`/secure/vendor/${id}/product/${productId}/categories`, { categories });
      return res.data;
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  const batchUpdateVendorProductCategories = async (id: number, productIds: (number | string)[], categories: any[]) => {
    try {
      const res = await axios.put(`/secure/vendor/${id}/products/categories`, {
        categories,
        product_ids: productIds
      });

      return res.data;
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  const getVendorDealDynamics = async (id: number, categoryId?: number) => {
    try {
      const res = await axios.get(
        `${VITE_APP_UNSECURE_ROUTE}/vendor/${id}/dealDynamics${categoryId ? `?category_id=${categoryId}` : ''}`
      );
      return res.data;
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  const createVendorDealDynamics = async (id: number, supplierDynamics: any, categoryId: any, profile: any) => {
    try {
      const res = await axios.post(`/secure/vendor/${id}/dealDynamics`, {
        supplierDynamics,
        category_id: categoryId,
        profile
      });
      return res.data;
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  const getVendorPriceLists = async (id: number) => {
    try {
      const res = await axios.get(`/secure/vendor/${id}/price_lists`);
      return res.data;
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  const getVendorPriceList = async (id: number, productListId: number, str: string, purchaseLevelId?: number) => {
    let url = `/secure/vendor/${id}/price_list/${productListId}?str=${str}`;

    if (purchaseLevelId !== undefined) {
      url += `&purchase_level_id=${purchaseLevelId}`;
    }

    try {
      const res = await axios.get(url);
      if (res.data?.status === 'error') {
        throw new Error(res.data?.message || 'Error');
      }
      return res.data;
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  const updateVendorPriceList = async (id: number, values: Partial<PriceList>) => {
    try {
      const res = await axios.put(`/secure/vendor/price_lists/${id}`, values);
      return res.data;
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  const deleteVendorPriceList = async (id: number) => {
    try {
      const res = await axios.delete(`/secure/vendor/price_lists/${id}`);
      return res.data;
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  const getVendorProductsByName = async (id: number, products: any[]) => {
    try {
      const res = await axios.post(`/secure/vendor/${id}/products_by_names`, { products });
      return res.data;
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  /**
   * Search for products of a given vendor
   *
   * @param vendor_id
   * @param str
   */
  const searchVendorProducts = async (vendor_id: number, str: string) => {
    if (str.length > 1) {
      try {
        const res = await axios.get(`/secure/vendor/${vendor_id}/search?str=${str}`);
        return res.data;
      } catch (e) {
        AxiosErrorHandler(e);
      }
    }
  };

  /**
   * Retrieve a vendor's ESG scores
   *
   * @param vendor_id
   */
  const fetchVendorESGScores = async (vendor_id: number) => {
    try {
      const res = await axios.get(`/secure/vendor/${vendor_id}/esg`);
      if (res.data?.status === 'success') {
        return res.data?.data;
      }
      return Promise.reject(Error(res.data?.message));
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  /**
   * Get Vendor or Client from salesforce id
   *
   * @param { string } sf_account_id
   * @returns
   */
  const getVendorBySalesforceId = async (sf_account_id: string) => {
    try {
      const res = await axios.get(`/secure/vendor/sf_account/${sf_account_id}`);
      if (res.data?.status === 'success') {
        return res.data?.data;
      }
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  /**
   * Update Vendor Category
   *
   * @param supplier_id
   * @param supplier_category_id
   * @param values
   */
  const updateSupplierCategory = async (
    supplier_id: number,
    supplier_category_id: number,
    values: { profile?: string; category?: string }
  ) => {
    try {
      const res = await axios.patch(`/secure/supplier/${supplier_id}/category/${supplier_category_id}`, values);
      if (res.data?.status === 'success') {
        return res.data?.data;
      }
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  /**
   * Create Vendor Category
   *
   * @param supplier_id
   * @param values
   */
  const createSupplierCategory = async (supplier_id: number, values: { profile?: string; category?: string }) => {
    try {
      const res = await axios.post(`/secure/supplier/${supplier_id}/category`, values);
      if (res.data?.status === 'success') {
        return res.data?.data;
      }
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  /**
   * Update Vendor Logo filename field
   *
   * @param vendor_id
   * @param logo_filename
   */
  const updateSupplierLogoFilename = async (vendor_id: number, logo_filename: string) => {
    try {
      const res = await axios.patch(`/secure/supplier/${vendor_id}/logoFilename`, { logo_filename });
      if (res.data?.status === 'success') {
        return res.data?.data;
      }
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  /**
   * Update competitor
   *
   * @param id
   * @param values
   */
  const updateCompetitor = async (
    id: number,
    values: { competitor_id?: number; competitor: string; supplier_category_id?: number }
  ) => {
    try {
      const res = await axios.patch(`/secure/competitor/${id}`, values);
      if (res.data?.status === 'success') {
        return res.data?.data;
      }
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  /**
   * Update competitor
   *
   * @param id
   */
  const deleteCompetitor = async (id: number) => {
    try {
      const res = await axios.delete(`/secure/competitor/${id}`);
      if (res.data?.status === 'success') {
        return true;
      }
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  /**
   * Create competitor
   *
   * @param id
   * @param values
   */
  const addCompetitor = async (
    id: number,
    values: { competitor_id?: number; competitor: string; supplier_category_id?: number }
  ) => {
    try {
      const res = await axios.post(`/secure/supplier/${id}/competitor`, values);
      if (res.data?.status === 'success') {
        return res.data?.data;
      }
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  return {
    searchVendors,
    getVendor,
    getVendorDetails,
    getVendors,
    createVendor,
    updateVendor,
    getVendorCategories,
    getVendorContracts,
    getVendorProducts,
    mergeVendorProducts,
    updateVendorProduct,
    updateVendorProductConfirmed,
    updateVendorProductCategories,
    batchUpdateVendorProductCategories,
    getVendorDealDynamics,
    createVendorDealDynamics,
    getVendorPriceLists,
    getVendorPriceList,
    updateVendorPriceList,
    deleteVendorPriceList,
    getVendorProductsByName,
    searchVendorProducts,
    fetchVendorESGScores,
    getVendorBySalesforceId,
    updateSupplierCategory,
    createSupplierCategory,
    updateSupplierLogoFilename,
    updateCompetitor,
    deleteCompetitor,
    addCompetitor
  };
};
