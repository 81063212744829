import { useRef, useCallback, useEffect } from 'react';
import { Form, Input, Select, InputRef, Modal } from 'antd';
import { Competitor, SupplierCategory } from 'sb/models/DealDynamicsSupplier';
import { VendorsSearch } from 'components/atoms/VendorsSearch';
import { useVendorApi } from 'sb/api/vendor';
import { ModalProps } from 'antd/es';

interface Props extends ModalProps {
  competitor?: Competitor;
  visible: boolean;
  onClose: () => void;
  onSubmit: (competitor: Competitor) => void;
  categories?: SupplierCategory[];
  supplierId?: number;
}

export const CompetitorModal: React.FC<Props> = ({
  visible,
  onClose,
  competitor,
  onSubmit,
  categories,
  supplierId
}) => {
  const supplierRef = useRef<InputRef>(null);
  const [form] = Form.useForm();

  // const [addSupplier, setAddSupplier] = useState(false);
  // const [supplierCategoryId, setSupplierCategoryId] = useState(undefined);

  // API
  const { addCompetitor, updateCompetitor } = useVendorApi();

  useEffect(() => {
    form.setFieldValue('categories', categories);
    form.setFieldsValue({
      // categories,
      newCompetitor: competitor?.vendor_competitor_competitor_idTovendor?.name || undefined,
      competitor_string: competitor?.competitor || undefined,
      competitor_id: competitor?.id || -1,
      supplier_category_id: competitor?.supplier_category?.id
    });
  }, [form, competitor, categories]);

  const handleSupplierSelectChange = (item: any) => {
    if (item.value !== -1) {
      form.setFieldsValue({ supplier: item.label, competitor_id: item.value });
    } else {
      // setAddSupplier(true);
      supplierRef.current?.focus();
    }
  };
  const handleCategorySelectChange = (item: any) => {
    console.log(item);
    // setSupplierCategoryId(item);
  };
  const handleSupplierSelectClear = () => {
    form.setFieldsValue({ supplier: undefined, competitor_id: -1 });
  };

  const _onClose = useCallback(() => {
    form.resetFields();
    // setSupplierCategoryId(undefined);
    // setAddSupplier(false);
    onClose();
  }, [form, onClose]);

  const onClickSubmit = useCallback(async () => {
    const values = await form.getFieldsValue();

    if (values) {
      const { newCompetitor, competitor_string, supplier_category_id } = values;
      const data = {
        competitor_id: newCompetitor.value,
        competitor: competitor_string,
        supplier_category_id: supplier_category_id
      };

      if (competitor?.id) {
        const result = await updateCompetitor(competitor?.id, data);
        onSubmit(result);
      } else if (supplierId) {
        const result = await addCompetitor(supplierId, data);
        onSubmit(result);
      }
    }
    _onClose();
  }, [form, competitor, _onClose, supplierId, onSubmit]);

  return (
    <Modal
      onOk={onClickSubmit}
      title={competitor ? 'Edit competitor' : 'Add competitor'}
      open={visible}
      onCancel={_onClose}
    >
      <Form
        layout={'vertical'}
        form={form}
        style={{
          maxWidth: 600
        }}
      >
        {categories && categories?.length > 0 && (
          <Form.Item name="supplier_category_id" label="Category">
            <Select
              className="w-full"
              dropdownMatchSelectWidth={false}
              placeholder="Select category"
              allowClear
              style={{ width: '100%' }}
              fieldNames={{ label: 'category', value: 'id' }}
              onChange={handleCategorySelectChange}
              options={categories}
              size="large"
            />
          </Form.Item>
        )}

        <Form.Item
          label="Supplier Search"
          required
          name="newCompetitor"
          rules={[{ required: false, message: 'Please select supplier!' }]}
        >
          <VendorsSearch
            placeholder="Search for Supplier"
            width="100%"
            labelInValue
            size={'large'}
            defaultOptions={[{ id: -1, name: <i>Add a supplier you can&apos;t find...</i> }]}
            onChange={handleSupplierSelectChange}
            onClear={handleSupplierSelectClear}
          />
        </Form.Item>

        <Form.Item label="Additional Label" name="competitor_string">
          <Input className="w-full" ref={supplierRef} placeholder={'Will appear after supplier name..'} size="large" />
        </Form.Item>
      </Form>
    </Modal>
  );
};
