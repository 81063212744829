import { Col, DatePicker, DatePickerProps, Form, Input, InputRef, message, Modal, ModalProps, Row, Select } from 'antd';
import { useEffect, useRef } from 'react';
import { useForm } from 'antd/es/form/Form';
import { URGENCY_LOV } from 'sb/shared/constants';
import moment from 'moment';
import { VendorsSearch } from 'components/atoms/VendorsSearch';
import { UsersSearch } from 'components/atoms/UsersSearch';
import { InquiryForm } from 'sb/models/Inquiry';
import { useNoteApi } from 'sb/api/note';

interface Props extends ModalProps {
  onCancel: () => void;
  onSave: () => void;
}

export const InquirySubmissionModal: React.FC<Props> = ({ onCancel, onSave, ...rest }: Props) => {
  const subjectRef = useRef<InputRef>(null);
  const textAreaRef = useRef<InputRef>(null);
  const [form] = useForm();

  // API
  const { insertInquiry } = useNoteApi();

  useEffect(() => {
    if (rest.open) return;
    form.resetFields();
  }, [form, rest.open]);

  const disabledDateNeededDate: DatePickerProps['disabledDate'] = (current) => {
    return current && current < moment().startOf('day');
  };

  const onOkayHandler = async () => {
    try {
      await form.validateFields();
      const data: InquiryForm = await form.getFieldsValue();
      Object.assign(data, {
        date_needed: data.date_needed ? moment(data.date_needed).format('YYYY-MM-DD') : undefined
      });
      const result = await insertInquiry(data);
      if (result) {
        message.success('Inquiry added!');
        await onSave();
      }
    } catch (e) {
      message.error('Please include values for required fields.');
    }
  };

  return (
    <Modal {...rest} onCancel={onCancel} title={'Add Inquiry'} width={800} onOk={onOkayHandler}>
      <Form layout={'vertical'} size={'small'} form={form} initialValues={{ urgency: 3 }}>
        <Form.Item label={'User'} name={'auth0_user_id'} rules={[{ required: true, message: 'User required' }]}>
          <UsersSearch size={'large'} style={{ width: '50%' }} placeholder="Search for user by name or email/domain" />
        </Form.Item>
        <Form.Item label={'Primary Supplier'} name={'vendor_id'}>
          <VendorsSearch style={{ width: '50%' }} placeholder="Search for a supplier" />
        </Form.Item>
        <Form.Item
          label={'Subject'}
          name={'subject'}
          rules={[{ required: true, message: 'Subject required' }]}
          style={{ marginBottom: 12 }}
        >
          <Input
            ref={subjectRef}
            placeholder={'Brief subject'}
            style={{ width: '100%' }}
            size={'large'}
            maxLength={100}
            showCount
            allowClear
          />
        </Form.Item>

        <Form.Item label={'Details'} name={'note'} rules={[{ required: true, message: 'Note details required' }]}>
          <Input.TextArea
            size="small"
            ref={textAreaRef}
            rows={4}
            placeholder="What's on your mind?"
            allowClear
            // onPressEnter={handleEnter}
          />
        </Form.Item>
        <>
          <Row justify={'space-between'} style={{ margin: '-16px 0' }}>
            <Col>
              <Form.Item label={'Urgency'} name={'urgency'}>
                <Select options={URGENCY_LOV} style={{ width: 120 }} size={'large'} />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                label={
                  <div>
                    Date Needed <span style={{ color: '#888' }}>(Optional)</span>
                  </div>
                }
                name={'date_needed'}
              >
                <DatePicker
                  placeholder={'Date needed'}
                  disabledDate={disabledDateNeededDate}
                  size={'large'}
                  placement={'bottomRight'}
                />
              </Form.Item>
            </Col>
          </Row>
        </>
      </Form>
    </Modal>
  );
};
