import { useAxios } from 'hooks/useAxios';
import { AxiosErrorHandler } from 'shared/Helpers';
import { BASE_URL } from 'shared/constants';
import { FormValuesType } from 'components/molecules/admin/PriceListView';

export const usePriceListApi = () => {
  const { axios } = useAxios(BASE_URL);

  /**
   * Handles the upload of a price list file to the server.
   *
   * This asynchronous function accepts form values, which typically include file data,
   * and posts them to the server for uploading. It utilizes Axios to perform the HTTP POST request
   * and integrates error handling through a custom error handler.
   *
   * @param {any} formData - An object containing the form data to be uploaded.
   * @returns {Promise<any>} A promise that resolves with the server's response if the upload is successful,
   *                         or the result of the custom error handler if an error occurs.
   */
  const handlePriceListUpload = async (formData: any): Promise<any> => {
    try {
      return await axios.post(`/secure/file/upload`, formData);
    } catch (e) {
      return AxiosErrorHandler(e);
    }
  };

  /**
   * Asynchronously processes a price list file associated with a specific vendor.
   * Sends a POST request to the server with the provided form values and encrypted file name.
   *
   * @param {FormValuesType} formValues - The form values to be submitted with the request.
   * @param {number} vendor_id - The unique identifier of the vendor.
   * @param {string} encryptedFilename - The name of the file to be processed, encrypted for security.
   * @returns {Promise<any>} A promise that resolves with the server's response or an error handled via AxiosErrorHandler.
   */
  const handlePriceListProcessing = async (
    formValues: FormValuesType,
    vendor_id: number,
    encryptedFilename: string
  ): Promise<any> => {
    try {
      return await axios.post(`/secure/vendor/${vendor_id}/price_list/process`, { ...formValues, encryptedFilename });
    } catch (e) {
      return AxiosErrorHandler(e);
    }
  };

  return {
    handlePriceListProcessing,
    handlePriceListUpload
  };
};
