import { useAxios } from 'hooks/useAxios';
import { AxiosErrorHandler } from 'shared/Helpers';
import { Reseller } from 'sb/models/Reseller';
import { BASE_URL } from 'sb/shared/constants';

export const useResellerApi = () => {
  const { axios } = useAxios(BASE_URL);

  const getResellers = async (str: string) => {
    try {
      const res = await axios.get(`/secure/resellers?str=${str}`);
      if (res.data?.status === 'success') {
        return res.data?.data;
      }
      throw new Error(res.data?.message);
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  const createReseller = async (values: Omit<Reseller, 'id'>) => {
    try {
      const res = await axios.post(`/secure/reseller`, values);
      if (res.data?.status === 'success') {
        return res.data?.data;
      }
      throw new Error(res.data?.message);
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  const updateReseller = async (id: number, values: Partial<Reseller>) => {
    try {
      const res = await axios.patch(`/secure/reseller/${id}`, values);
      if (res.data?.status === 'success') {
        return res.data?.data;
      }
      throw new Error(res.data?.message);
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  return {
    getResellers,
    createReseller,
    updateReseller
  };
};
