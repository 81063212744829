import { BryntumGantt, BryntumProjectModel, BryntumToolbar } from '@bryntum/gantt-react';
import { projectConfig, useGanttConfig, customTaskTooltip } from './BryntumConfig';
import React, { ChangeEvent, createRef, useCallback, useEffect, useRef, useState } from 'react';
import {
  ClientRenewals,
  DealExport,
  GanttTask,
  Project,
  RenewalChildRecord,
  RenewalRecord,
  Resource,
  Task
} from 'models/Renewals';
import '@bryntum/gantt/gantt.stockholm.css';
import {
  Button,
  Card,
  Col,
  ConfigProvider,
  Input,
  InputRef,
  message,
  notification,
  Popover,
  Row,
  Segmented,
  Select,
  Space,
  Spin
} from 'antd';
import {
  DownloadOutlined,
  ExclamationCircleOutlined,
  FilterOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
  TableOutlined,
  WarningOutlined
} from '@ant-design/icons';
import './bryntum-gantt.scss';
import moment, { Moment } from 'moment-timezone';
import { cloneDeep, escapeRegExp } from 'lodash-es';
import { ANONYMOUS_CLIENT_NAME, RENEWAL_RECORD_TYPE, RENEWAL_ZONE_CONTENT } from 'shared/constants';
import { Auth0User } from 'types/global';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { profileSwitchState, renewalNotificationRequestState, selectedTopNavKeyState } from 'recoil/atoms';
import { DealModal } from 'components/deal-modal/DealModal';
import { useProjectApi } from 'api/project';
import { useAdminApi } from 'api/admin';
import { useAddTasks, useDeleteDealTask, useUpdateTask } from 'api/contract';
import { EditTasksPayload, Task as ContractTask } from 'types/Contract';
import { DealTypeModal } from 'components/deal/DealTypeModal';
import { GanttChartToolbarItems } from './GanttChartToolbar';
import { useDealHelper } from 'hooks/useDealHelper';
import AddDealTaskForm from './AddDealTaskForm';
import { EditTaskModal } from './EditDealTaskModal';
import { AssignmentModel, AssignmentModelConfig, TaskTooltipConfig } from '@bryntum/gantt';
import MultiSelect from '../../shared/MultiSelect';
import { getFilteredTasks } from './RenewalGanttChartFilters';
import { FilterRange, GanttChartMode } from 'sb/shared/constants';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { ExcelCreateRequest, FieldType, Horizontal, NumberFormat, Vertical } from 'models/ExcelJs';

import { useExcelApi } from 'api/excel';
import fileDownload from 'js-file-download';

interface Props {
  id?: number | string;
  renewalsObject: 'client' | 'supplier' | 'npi';
  onClose?: () => void;
  renewalTimeframe?: string;
  mode?: GanttChartMode;
}

export const RenewalGanttChart: React.FC<Props> = ({
  id,
  renewalsObject,
  onClose,
  renewalTimeframe,
  mode = GanttChartMode.OWN_CALENDER
}) => {
  // REFERENCES
  const ganttRef = useRef<BryntumGantt>(null);
  const projectRef = useRef<BryntumProjectModel>(null);
  const vendorFilterRef = createRef<InputRef>();
  const portfolioFilterRef = createRef<InputRef>();
  useRef<any>(null);
  // LOCAL STATE
  const [tasks, setTasks] = useState<Array<RenewalRecord>>([]);
  const [_tasks, _setTasks] = useState<Array<RenewalRecord>>([]);
  const [project, setProject] = useState<Project | undefined>(undefined);
  const [dealModalOpen, setDealModalOpen] = useState(false);
  const [task, setTask] = useState<undefined | GanttTask>(undefined);
  const [, setDateRange] = useState<[Moment, Moment] | undefined>(undefined);
  const [segmentedClass, setSegmentedClass] = useState<'default' | 'red' | 'orange' | 'expired'>('default');
  const [dateSelector] = useState<'preset' | 'custom'>('preset');
  const [resources, setResources] = useState<Array<Resource>>([]);
  const [isDealTypeModalOpen, setIsDealTypeModalOpen] = useState(false);
  const [assignments, setAssignments] = useState<AssignmentModel[] | Partial<AssignmentModelConfig>[]>([]);
  const [loading, setLoading] = useState(false);
  const [portfolioFilterValue, setPortfoliorFilterValue] = useState<string | undefined>(undefined);
  const [vendorFilterValue, setVendorFilterValue] = useState<string | undefined>(undefined);
  const [rangeFilterValue, setRangeFilterValue] = useState<FilterRange>(FilterRange.reset);
  const [clientContactFilterValue, setClientContactFilterValue] = useState<Array<string | number>>([]);
  const [dealTypeFilterValue, setDealTypeFilterValue] = useState<'default' | 'Calendar' | 'Submitted'>('default');
  const [status, setStatus] = useState<'active' | 'expired'>('active');
  const { SUPPLIER, SUPPLIER_DEAL, DEAL } = RENEWAL_RECORD_TYPE;
  const [clientName, setClientName] = useState<string | undefined>(undefined);
  const navigate = useNavigate();

  // RECOIL STATE
  // const renewalNotificationRequest = useRecoilValue(renewalNotificationRequestState);
  const [renewalNotificationRequest, setRenewalNotificationRequest] = useRecoilState(renewalNotificationRequestState);
  

  // API
  const { fetchPortfolioRenewals, fetchRenewals } = useProjectApi();
  const { getMyCompanyUsers } = useAdminApi();
  const { createExcel } = useExcelApi();

  const { dealIdToAddTasks, clearDealIdToAddTasks, dealIdToEditTask, clearDealIdToEditTask } = useDealHelper();

  const { mutate: addTasksToDeal } = useAddTasks({
    onSuccess: async () => {
      notification.success({
        message: 'Tasks added successfully'
      });
      clearDealIdToAddTasks();
      await handleFetchRenewals();
    },
    onError: () => {
      notification.error({
        message: 'Error while adding tasks'
      });
      clearDealIdToAddTasks();
    }
  });

  const { mutate: updateTask } = useUpdateTask({
    onSuccess: async () => {
      notification.success({
        message: 'Task updated successfully'
      });
      clearDealIdToEditTask();
      await handleFetchRenewals();
    },
    onError: () => {
      notification.error({
        message: 'Error while updating task'
      });
      clearDealIdToEditTask();
    }
  });

  const { mutate: deleteDealTask } = useDeleteDealTask({
    onSuccess: async () => {
      notification.success({
        message: 'Task deleted successfully'
      });
      clearDealIdToEditTask();
      await handleFetchRenewals();
    },
    onError: () => {
      notification.error({
        message: 'Error while deleting task'
      });
      clearDealIdToEditTask();
    }
  });

  const handleAddTasks = (
    dealId: number,
    formValues: {
      tasks: ContractTask[];
    }
  ) => {
    const { tasks } = formValues;
    const payload = tasks.map((t) => {
      return {
        name: t.name,
        duration: t.duration,
        assignments: t.assignments,
        comments: t.comments || ''
      };
    });

    addTasksToDeal({
      dealId,
      data: payload
    });
  };

  const handleEditTask = (dealId: number, taskId: number, data: EditTasksPayload) => {
    updateTask({
      dealId,
      taskId,
      data
    });
  };

  const handleDeleteTask = async () => {
    if (dealIdToEditTask?.dealId && dealIdToEditTask?.taskId) {
      deleteDealTask({ dealId: dealIdToEditTask?.dealId, taskId: dealIdToEditTask?.taskId });
    }
  };

  /**
   * handle onSave event from RenewalAlertsModal.
   */
  useEffect(() => {
    if (renewalNotificationRequest.length > 0) {
      (async (): Promise<void> => {
        try {
          setLoading(true);
          const renewals: ClientRenewals = await fetchRenewals(status, profileSwitch ? profileSwitch.id : undefined);
          if (profileSwitch && profileSwitch.anonymous) renewals.tasks[0].name = ANONYMOUS_CLIENT_NAME;
          setClientName(renewals.tasks[0].name);
          setProject(renewals.project);
          setTasks(renewals.tasks);
          _setTasks(renewals.tasks);
          const activeAlerts = renewalNotificationRequest.filter(
            (alert) => alert.action === 'insert' || alert.action === 'update'
          );
          message.success(
            activeAlerts.length > 0
              ? `${activeAlerts.length} Renewal Alert${activeAlerts.length > 1 ? 's' : ''} Set!`
              : 'All renewal alerts removed for this deal!'
          );
          setRenewalNotificationRequest([]);
        } catch (e) {
          console.log(e);
        } finally {
          setLoading(false);
        }
      })();
    }
    // eslint-disable-next-line
  }, [renewalNotificationRequest]);

  const taskClickHandler = useCallback(
    ({ taskRecord }: any) => {
      const task: GanttTask = taskRecord._data;
      if (!task.id) return;
      if (task.status === 'Calendar') {
        navigate(`/calendar_deals/${task.id}`);
      } else {
        if (mode === GanttChartMode.OWN_CALENDER && (task?.recordType === SUPPLIER_DEAL || task?.recordType === DEAL)) {
          navigate(`/deal_details/${task.id}`);
        }
      }
    },
    [mode]
  );

  const ganttConfig = useGanttConfig(mode, taskClickHandler);

  // RECOIL STATE
  const setSelectedTopNavKey = useSetRecoilState(selectedTopNavKeyState);

  useEffect(() => setSelectedTopNavKey('dealCalendar'), [setSelectedTopNavKey]);
  const profileSwitch = useRecoilValue(profileSwitchState);

  const handleFetchRenewals = useCallback(async (): Promise<void> => {
    setLoading(true);

    const fetchRenewalsByMode = (status: 'expired' | 'active', profile_id: number | undefined) => {
      if (mode == GanttChartMode.OWN_CALENDER) {
        return fetchRenewals(status, profile_id);
      } else {
        return fetchPortfolioRenewals(status, profile_id);
      }
    };

    fetchRenewalsByMode(status, profileSwitch ? profileSwitch.id : undefined)
      .then((renewals: ClientRenewals) => {
        if (profileSwitch && profileSwitch.anonymous) renewals.tasks[0].name = ANONYMOUS_CLIENT_NAME;
        setClientName(renewals.tasks[0].name);
        setProject(renewals.project);
        setAssignments(renewals.assignments);
        setTasks(renewals.tasks);
        _setTasks(renewals.tasks);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => setLoading(false));
  }, [mode, profileSwitch, status]);

  useEffect(() => {
    if (!id) {
      setTasks([]);
      return;
    }

    if (renewalsObject === 'client' && typeof id === 'number') {
      handleFetchRenewals().then();
    }
    // eslint-disable-next-line
  }, [id, renewalsObject, renewalTimeframe]);

  useEffect(() => {
    getMyCompanyUsers()
      .then((users: Auth0User[]) => {
        const myCompanyUsers: Array<Resource> =
          users && Array.isArray(users)
            ? users.map((user: Auth0User) => ({
                id: user.user_id,
                name: user.name,
                user_id: user.user_id
              }))
            : [];

        setResources(myCompanyUsers);
      })
      .catch((err) => {
        console.error('Error occurred while fetching company users', err);
      });
  }, []);

  useEffect(() => {
    if (!projectRef || !projectRef.current || !ganttRef || !ganttRef.current) return;
    projectRef.current.instance.on({
      refresh: () => {
        if (ganttRef.current) {
          ganttRef.current.instance.zoomToFit({
            leftMargin: 50,
            rightMargin: 50
          });
        }
      }
    });

    if (mode == GanttChartMode.OWN_CALENDER) {
      vendorFilterRef.current?.focus();
    } else {
      portfolioFilterRef.current?.focus();
    }

    return () => {
      setTasks([]);
      _setTasks([]);
      setTask(undefined);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // noinspection DuplicatedCode
  const handleToolbarButtonClick = async (
    method:
      | 'expandAll'
      | 'collapseAll'
      | 'zoomIn'
      | 'zoomOut'
      | 'zoomToFit'
      | 'shiftPrevious'
      | 'shiftNext'
      | 'exportExcel'
  ) => {
    if (!ganttRef) return;

    switch (method) {
      case 'expandAll':
        ganttRef.current?.instance.expandAll();
        break;
      case 'collapseAll':
        await ganttRef.current?.instance.collapseAll();
        ganttRef.current?.instance.expand(0);
        break;
      case 'zoomIn':
        ganttRef.current?.instance.zoomIn();
        break;
      case 'zoomOut':
        ganttRef.current?.instance.zoomOut();
        break;
      case 'zoomToFit':
        ganttRef.current?.instance.zoomToFit({ leftMargin: 50, rightMargin: 50 });
        break;
      case 'shiftPrevious':
        ganttRef.current?.instance.shiftPrevious();
        break;
      case 'shiftNext':
        ganttRef.current?.instance.shiftNext();
        break;
      case 'exportExcel':
        handleExport();
        break;
    }
  };

  const getUserOptions = (): Array<{ value: number | string; label: string }> => {
    const usersList: Array<{ value: number | string; label: string }> = [];
    if (_tasks[0]?.children) {
      _tasks[0]?.children?.forEach((taskChild: RenewalChildRecord) => {
        if (taskChild?.recordType === SUPPLIER_DEAL) {
          const idx = usersList?.findIndex((user) => user.value === taskChild?.clientContact?.Id);
          taskChild?.clientContact &&
            idx === -1 &&
            usersList?.push({
              value: taskChild.clientContact?.Id,
              label: taskChild.clientContact?.Name
            });
        } else if (taskChild?.recordType === SUPPLIER && taskChild?.children?.length > 0) {
          taskChild?.children?.forEach((childChild: RenewalChildRecord) => {
            const idx = usersList?.findIndex((user) => user.value === childChild?.clientContact?.Id);
            childChild.recordType === DEAL &&
              childChild?.clientContact &&
              idx === -1 &&
              usersList.push({
                value: childChild.clientContact?.Id,
                label: childChild.clientContact?.Name
              });
          });
        }
      });
    }
    return usersList;
  };

  const resetTasks = (tasksList: RenewalRecord[]) => {
    setTasks(tasksList);
    zoomToFitGantt();
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (
        !portfolioFilterValue &&
        !vendorFilterValue &&
        rangeFilterValue === 'reset' &&
        clientContactFilterValue.length === 0 &&
        !dealTypeFilterValue
      ) {
        resetTasks(_tasks);
      } else {
        resetTasks(
          getFilteredTasks({
            portfolioFilterValue,
            vendorFilterValue,
            rangeFilterValue,
            clientContactFilterValue,
            _tasks,
            mode,
            dealTypeFilterValue
          })
        );
      }
    }, 500);
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portfolioFilterValue, vendorFilterValue, rangeFilterValue, clientContactFilterValue, mode, dealTypeFilterValue]);

  const handleDealTypeFilterChange = (value: 'default' | 'Calendar' | 'Submitted'): void => {
    setDealTypeFilterValue(value);
  };

  const handleSearchWithinPortfolio = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setVendorFilterValue(undefined);
    setPortfoliorFilterValue(escapeRegExp(value));
  };
  /**
   * Search results of contracts array for vendor, reseller or deal names
   *
   * @param e
   */
  const handleSearchWithinContracts = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setPortfoliorFilterValue(undefined);
    setVendorFilterValue(escapeRegExp(value));
  };

  const handleOnDateRangeChange = async (value: FilterRange) => {
    setRangeFilterValue(value);
  };

  /**
   * Handles the change of owner values.
   * If values are provided and the first value is not 'All', filters the tasks based on the owner values.
   * If values are not provided or the first value is 'All', sets the tasks back to the original tasks.
   * Also triggers zoomToFitGantt after the tasks are updated.
   *
   * @param {Array<string | number>} values - The owner values to be applied for filtering tasks
   *
   * @returns {void}
   */
  const handleOwnerChange = (values: Array<string | number>): void => {
    if (values?.length > 0 && values[0] !== 'All') {
      setClientContactFilterValue(values);
    } else {
      setClientContactFilterValue([]);
    }
  };

  const zoomToFitGantt = () => {
    if (projectRef.current) {
      projectRef.current.instance.on({
        refresh: () => {
          if (ganttRef.current) {
            ganttRef.current.instance.zoomToFit({
              leftMargin: 50,
              rightMargin: 50
            });
          }
        }
      });
    }
  };

  const handleResetDateRange = useCallback(() => {
    setTasks(_tasks);
    setDateRange([moment(project?.startDate), moment(project?.endDate)]);
  }, [_tasks, project?.endDate, project?.startDate]);

  // const onAddTaskClick = async (record: Task) => {
  //   if (!ganttRef.current || !projectRef) return;
  //   const defaultDealTasks = record._data.default_deal_tasks;
  //
  //   if (defaultDealTasks) {
  //     defaultDealTasks.map((item) => {
  //       Object.assign(item, {
  //         id: random(120000, 10000000),
  //         tcv: null,
  //         readOnly: false,
  //         manuallyScheduled: true,
  //         startDate: moment(record._data.endDate)
  //           .subtract(3, 'months')
  //           .subtract(item.duration, 'days')
  //           .format('YYYY-MM-DD'),
  //         endDate: moment(record._data.endDate).subtract(3, 'months').format('YYYY-MM-DD')
  //       });
  //     });
  //
  //     ganttRef.current.instance.taskStore.getById(record.id).appendChild(defaultDealTasks);
  //     await ganttRef.current.instance.taskStore.toggleCollapse(record.id);
  //   }
  // };

  useEffect(() => {
    (async (): Promise<void> => handleFetchRenewals())();
  }, [handleFetchRenewals, status]);

  /**
   * Sets the status based on the provided value.
   *
   * @param {string} status - The status to be set. It should be either 'active' or 'expired'.
   * @return {void}
   */
  const handleActiveExpiredClick = (status: 'active' | 'expired'): void => {
    setStatus(status);
  };

  const handleZoneClick = useCallback(
    (zone: 'red' | 'orange' | 'default' | 'expired') => {
      setSegmentedClass(zone);
      if (zone === 'default') {
        handleResetDateRange();
        resetTasks(_tasks);
        return;
      }

      if (!_tasks) return;
      const clonedTasks = cloneDeep(_tasks);
      const childTasks: RenewalChildRecord[] = [];

      if (zone === 'red') {
        clonedTasks[0]?.children?.map((item: RenewalChildRecord) => {
          if (item && item.children.length > 1) {
            const redZoneTasks = item.children.filter((deal: RenewalChildRecord) => {
              const runway = deal.runway || 3;
              return runway && deal.remaining >= 0 && (runway ? deal.remaining <= runway : deal.remaining <= 3);
            });
            if (redZoneTasks && redZoneTasks.length > 0) {
              item.children = redZoneTasks;
              childTasks.push(item);
            }
          } else {
            const runway = item.runway || 3;
            if (runway && item.remaining >= 0 && (runway ? item.remaining <= runway : item.remaining <= 3)) {
              childTasks.push(item);
            }
          }
        });
      } else if (zone === 'orange') {
        clonedTasks[0]?.children?.map((item: RenewalChildRecord) => {
          const orangeRunway = 1;
          if (item && item.children.length > 1) {
            const orangeZoneTasks = item.children.filter((deal: RenewalChildRecord) => {
              return (
                deal.remaining >= 0 &&
                (deal.runway ? deal.remaining <= deal.runway + orangeRunway : deal.remaining <= 3 + orangeRunway)
              );
            });
            if (orangeZoneTasks && orangeZoneTasks.length > 0) {
              item.children = orangeZoneTasks;
              childTasks.push(item);
            }
          } else {
            if (
              item.remaining >= 0 &&
              (item.runway ? item.remaining <= item.runway + orangeRunway : item.remaining <= 3 + orangeRunway)
            ) {
              childTasks.push(item);
            }
          }
        });
      } else if (zone === 'expired') {
        clonedTasks[0]?.children?.map((item: RenewalChildRecord) => {
          if (item && item.children.length > 0) {
            const expiredTasks = item.children.filter((deal: RenewalChildRecord) => deal.remaining <= 0);
            if (expiredTasks && expiredTasks.length > 0) {
              item.children = expiredTasks;
              childTasks.push(item);
            }
          } else {
            if (item.remaining <= 0) {
              childTasks.push(item);
            }
          }
        });
      }

      clonedTasks[0].children = childTasks;
      setTasks(clonedTasks);
      zoomToFitGantt();
    },
    // eslint-disable-next-line
    [_tasks, handleResetDateRange]
  );

  const cardTitle = () => {
    return (
      <ConfigProvider componentSize={'large'}>
        <Row justify={'space-between'} className={'gap-5'}>
          <Col className={'grow'}>
            <Space>
              <div className={'flex flex-wrap gap-4'}>
                {mode == GanttChartMode.PORTFOLIO && (
                  <span>
                    <div className={'filter-label'}>Filter by Portfolio</div>
                    <Input
                      placeholder="Filter by Portfolio"
                      prefix={<FilterOutlined />}
                      ref={portfolioFilterRef}
                      value={portfolioFilterValue}
                      allowClear
                      onChange={handleSearchWithinPortfolio}
                      style={{ width: 260 }}
                    />
                  </span>
                )}
                <span>
                  <div className={'filter-label'}>Filter by Vendor</div>
                  <Input
                    placeholder="Filter by Vendor"
                    prefix={<FilterOutlined />}
                    ref={vendorFilterRef}
                    allowClear
                    value={vendorFilterValue}
                    onChange={handleSearchWithinContracts}
                    style={{ width: 220 }}
                  />
                </span>
              </div>
              {mode == GanttChartMode.OWN_CALENDER && (
                <>
                  {dateSelector === 'preset' && (
                    <>
                      <div>
                        <div className={'filter-label'}>Filter by Deal type</div>
                        <Select
                          className={'w-36'}
                          size={'large'}
                          defaultValue={'default'}
                          value={dealTypeFilterValue}
                          options={[
                            { label: 'All', value: 'default' },
                            { label: 'Calendar', value: 'Calendar' },
                            { label: 'Submitted', value: 'Submitted' }
                          ]}
                          onChange={(value: 'default' | 'Calendar' | 'Submitted') => handleDealTypeFilterChange(value)}
                        />
                      </div>
                      <div>
                        <div className={'filter-label'}>Expires in</div>
                        <Select
                          className={'w-36'}
                          size={'large'}
                          defaultValue={'reset'}
                          options={[
                            { label: 'Full Range', value: 'reset' },
                            { label: '3 months', value: 3 },
                            { label: '6 months', value: 6 },
                            { label: '12 months', value: 12 },
                            { label: `End of ${moment().format('Y')}`, value: 'year' }
                          ]}
                          onChange={(value: any) => handleOnDateRangeChange(value)}
                        />
                      </div>
                    </>
                  )}
                  <div>
                    <div className={'filter-label'}>Client Contact</div>
                    <MultiSelect
                      options={getUserOptions()}
                      onChange={handleOwnerChange}
                      displayLabelAll={true}
                      maxTagCount={4}
                    />
                  </div>
                </>
              )}
            </Space>
          </Col>
          <Col className={'flex justify-end'}>
            <Space size={'large'}>
              <div style={{ width: 200 }}>
                <div className={'filter-label'}>Status</div>
                <Segmented
                  size={'large'}
                  block
                  className={status === 'active' ? 'npi-blue' : 'default'}
                  onChange={(value: any) => handleActiveExpiredClick(value)}
                  options={[
                    { label: 'Active', value: 'active' },
                    { label: 'Inactive', value: 'expired' }
                  ]}
                  onResize={undefined}
                  onResizeCapture={undefined}
                />
              </div>
            </Space>
          </Col>
          <Col className={'flex justify-end'}>
            <Space size={'large'}>
              <div style={{ width: 310 }}>
                <div className={'filter-label'}>
                  Renewal Zone
                  <Popover
                    content={<div style={{ width: 550 }}>{RENEWAL_ZONE_CONTENT}</div>}
                    title={<h2>What is a Renewal Zone?</h2>}
                    trigger="hover"
                    placement="topRight"
                  >
                    <QuestionCircleOutlined className="ml-2 cursor-pointer" style={{ fontSize: 14 }} />
                  </Popover>
                </div>
                <Segmented
                  size={'large'}
                  className={segmentedClass}
                  block
                  onChange={(value: any) => handleZoneClick(value)}
                  defaultValue={'default'}
                  options={[
                    { label: 'All', value: 'default' },
                    { label: 'Orange', value: 'orange', icon: <WarningOutlined /> },
                    { label: 'Red', value: 'red', icon: <ExclamationCircleOutlined /> }
                  ]}
                  onResize={undefined}
                  onResizeCapture={undefined}
                />
              </div>
            </Space>
          </Col>
        </Row>
      </ConfigProvider>
    );
  };

  const handleDealModalCancel = () => setDealModalOpen(false);

  const handleDealTypeModalCancel = () => setIsDealTypeModalOpen(false);

  const removeDealsByDealId = (parentRecord: RenewalRecord, dealId: number, removedRecords: RenewalChildRecord[]) => {
    const recordList: RenewalChildRecord[] = parentRecord.children;
    return recordList.filter((record: RenewalChildRecord) => {
      if (record.recordType == DEAL || record.recordType == SUPPLIER_DEAL) {
        const found: boolean = record.id == dealId;
        if (found) {
          parentRecord.expanded = true;
          removedRecords.push(record);
        }
        return !found;
      } else if (record.recordType == SUPPLIER) {
        record.children = removeDealsByDealId(record, dealId, removedRecords);
        return true;
      } else {
        return true;
      }
    });
  };

  const handleDeleteDeal = (dealId: number) => {
    const removedRecords: RenewalChildRecord[] = [];
    const filteredRecords = removeDealsByDealId(tasks[0], dealId, removedRecords);
    if (removedRecords.length == 0) {
      notification.warning({ message: 'Record Not found!!' });
    } else {
      const contractsCount = tasks[0]?.contractsCount ? tasks[0].contractsCount - 1 : 0;
      const newTask = { ...tasks[0], children: filteredRecords, contractsCount };
      setTasks([newTask]);
      _setTasks([newTask]);
    }
  };
  /**
   * Recursively traverses a tree of renewal records to extract deal information
   * @param {RenewalRecord[]} tasksTree - Array of renewal records representing the tree structure
   * @returns {DealExport[]} Array of DealExport objects containing flattened deal information
   * @description Extracts deal information from leaf nodes of the tasks tree, including:
   * - Vendor name
   * - Deal name
   * - Deal ID (shows 'NA' for Calendar status)
   * - TCV (Total Contract Value)
   * - Start date
   * - End date
   */
  function getAllDeals(tasksTree: RenewalRecord[]): DealExport[] {
    return _.flatMapDeep(tasksTree, (node: any) => {
      // Check if this is a leaf node (no children or empty children array)
      if (!node.children || node.children.length === 0) {
        return {
          Vendor_name: node.orgName,
          Deal_name: node.subject,
          Deal_id: node.status !== 'Calendar' ? node.name : 'NA',
          TCV: node.tcv,
          Start_date: node.startDate,
          End_date: node.endDate
        };
      }
      // If it has children, recursively get leaf nodes from children
      return getAllDeals(node.children);
    });
  }

  const handleExport = async () => {
    if (tasks.length > 0) {
      const today = moment().format('YYYY-MM-DD');
      const filename = `${clientName || 'NPI'}_${today}.xlsx`;
      const formData: ExcelCreateRequest = {
        data: getAllDeals(tasks),
        columns: [
          { header: 'Vendor name', key: 'Vendor_name', width: 20, type: FieldType.String },
          { header: 'Deal name', key: 'Deal_name', width: 50, type: FieldType.String },
          { header: 'Deal Id', key: 'Deal_id', width: 20, type: FieldType.String },
          { header: 'TCV', key: 'TCV', width: 20, type: FieldType.Number, numFmt: NumberFormat.currency },
          {
            header: 'Start date',
            key: 'Start_date',
            width: 20,
            type: FieldType.Date,
            alignment: {
              horizontal: Horizontal.center,
              vertical: Vertical.middle,
              wrapText: false
            }
          },
          {
            header: 'End date',
            key: 'End_date',
            width: 20,
            type: FieldType.Date,
            alignment: {
              horizontal: Horizontal.center,
              vertical: Vertical.middle,
              wrapText: false
            }
          }
        ],
        options: { fileName: filename, workSheetName: 'NPI Deals' }
      };

      try {
        message.loading({ content: 'Creating Excel file ...', key: 'excelExport' });
        const excelData = await createExcel(formData);
        fileDownload(excelData, filename);
        message.success({ content: 'Excel Exported Successfully', key: 'excelExport' });
      } catch (e) {
        message.error({ content: 'Problem creating Excel file.', key: 'excelExport' });
      }
    } else {
      message.warning({ content: 'No data to export', key: 'excelExport' });
    }
  };
  const loadingSpinner: React.ReactElement = <div className="text-lg pt-6 center-content">Refreshing calendar ...</div>;
  return (
    <Card title={cardTitle()} className="mt-6">
      {dealModalOpen && (
        <DealModal
          open={dealModalOpen}
          id={task?.id}
          onCancel={handleDealModalCancel}
          onDeleteDeal={handleDeleteDeal}
        />
      )}
      {onClose && (
        <div style={{ textAlign: 'right', marginBottom: 3 }}>
          <Button type="primary" icon={<TableOutlined />} onClick={onClose}>
            Return to Table
          </Button>
        </div>
      )}
      <BryntumProjectModel
        tasks={tasks}
        resources={resources}
        assignments={assignments}
        ref={projectRef}
        startDate={project?.startDate}
        endDate={project?.endDate}
        {...projectConfig}
      />
      <Spin spinning={loading} size="large" tip={loadingSpinner}>
        <Row>
          <Col flex="auto" className="b-toolbar b-outer flex b-dock-top">
            {mode == GanttChartMode.OWN_CALENDER && (
              <Button
                type="default"
                className="self-center font-semibold mx-1"
                onClick={() => {
                  setIsDealTypeModalOpen(true);
                }}
              >
                <PlusOutlined style={{ fontSize: '16px' }} />
                Add
              </Button>
            )}
          </Col>

          <Col flex="none">
            <BryntumToolbar items={GanttChartToolbarItems({ onButtonClick: handleToolbarButtonClick })} />
          </Col>
        </Row>
        <BryntumGantt
          ref={ganttRef}
          project={projectRef}
          onTaskClick={taskClickHandler}
          onTaskDblClick={() => false}
          onTaskResizeEnd={(event) =>
            console.log({
              endDate: event.taskRecord.get('endDate')
            })
          }
          onPercentBarDrop={(event) =>
            console.log({
              percentDone: event.taskRecord.get('percentDone')
            })
          }
          onTaskDrop={(event) =>
            console.log({
              startDate: event.taskRecords[0].get('startDate'),
              endDate: event.taskRecords[0].get('endDate')
            })
          }
          projectLinesFeature={false}
          taskMenuFeature={false}
          taskResizeFeature={{ dragThreshold: 1 }}
          features={{ taskTooltip: { template: customTaskTooltip } as Partial<TaskTooltipConfig> }}
          {...ganttConfig}
        />
      </Spin>
      {dealIdToAddTasks ? (
        <AddDealTaskForm
          handleCancel={() => {
            clearDealIdToAddTasks();
          }}
          handleDealSubmit={handleAddTasks}
        />
      ) : null}
      {isDealTypeModalOpen && <DealTypeModal onCancel={handleDealTypeModalCancel} />}
      {dealIdToEditTask ? (
        <EditTaskModal
          handleCancel={() => {
            clearDealIdToEditTask();
          }}
          handleEditTask={handleEditTask}
          handleDeleteTask={handleDeleteTask}
        />
      ) : null}
    </Card>
  );
};
