import { useAxios } from 'hooks/useAxios';
import { AxiosErrorHandler } from 'shared/Helpers';
import { BASE_URL } from 'sb/shared/constants';

export const useProductsApi = () => {
  const { axios } = useAxios(BASE_URL);

  const getProductOutliers = async (id: number) => {
    try {
      const res = await axios.get(`/secure/product/${id}/outliers`);
      return res.data;
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  const getProductContracts = async (id: number) => {
    try {
      const res = await axios.get(`/secure/product/${id}/contracts`);
      if (res.data?.status === 'error') {
        return Promise.reject(Error(res.data?.message));
      }
      return res.data?.data;
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  /**
   * Add list price for a product
   *
   * @param product_id
   * @param list_price
   */
  const addProductListPriceCustom = async (product_id: number, list_price: { list_price: number; comment: string }) => {
    try {
      const res = await axios.post(`/secure/product/${product_id}/list_price`, list_price);
      if (res.data?.status === 'error') {
        return Promise.reject(Error(res.data?.message));
      }
      return res.data;
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  /**
   * Fetch product list prices
   *
   * @param product_id
   */
  const getProductListPrices = async (product_id: number) => {
    try {
      const res = await axios.get(`/secure/product/${product_id}/list_prices`);
      if (res.data?.status === 'error') {
        return Promise.reject(Error(res.data?.message));
      }
      return res.data;
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  /**
   * Delete list price
   *
   * @param id
   */
  const deleteProductListPrice = async (id: number) => {
    try {
      const res = await axios.delete(`/secure/product/list_price/${id}`);
      if (res.data?.status === 'success') {
        return res.data?.data;
      }
      return Promise.reject(Error(res.data?.message));
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  /**
   * Get product comps
   *
   * @param product_id
   */
  const getProductComps = async (product_id: number) => {
    try {
      const res = await axios.get(`/secure/product/${product_id}/comps`);
      return res.data;
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  /**
   * Fetch Products linked to a given product
   *
   * @param id
   */
  const fetchLinkedProducts = async (id: number) => {
    try {
      const res = await axios.get(`/secure/product/${id}/linked_products`);
      if (res.data?.status === 'success') {
        return res.data?.data;
      }
      return Promise.reject(Error(res.data?.message));
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  /**
   * Fetch Products linked to a given product
   *
   * @param str
   * @param inContract
   * @param vendor_id
   */
  const searchProducts = async (str: string, inContract?: string, vendor_id?: number) => {
    try {
      const res = await axios.get(`/secure/product/search`, {
        params: {
          str: str,
          inContract: inContract,
          vendor_id: vendor_id
        }
      });
      if (res?.data?.status === 'success') {
        return res?.data?.data;
      }
      return Promise.reject(Error(res?.data?.message || 'Failed to search products'));
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  /**
   * Searches for products associated with a specific vendor based on a search string.
   *
   * @param {string} str - The search string used to find matching products.
   * @param {number} supplier_id - The unique identifier of the vendor whose products are to be searched.
   * @return {Promise<any>} A promise that resolves to the search results if successful, or an error message if the search fails.
   */
  const searchSupplierProducts = async (str: string, supplier_id: number): Promise<any> => {
    try {
      const res = await axios.get(`/secure/supplier/${supplier_id}/products/search`, { params: { str } });
      if (res.data?.status === 'success') {
        return res.data?.data;
      }
      return Promise.reject(Error(res.data?.message));
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  /**
   * Fetch supplier products
   *
   * @param {number} id - vendor ID
   * @param str
   */
  const fetchSupplierProducts = async (id: number, str?: string) => {
    try {
      const res = await axios.get(`/secure/supplier/${id}/products`, {
        params: { str }
      });
      if (res.data?.status === 'success') {
        return res.data?.data;
      }
      return Promise.reject(Error(res.data?.message));
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  /**
   * Update Linked Products
   *
   * @param id
   * @param productIds
   * @param name
   */
  const updateGroupedProducts = async (id: number, productIds: number[], name: string) => {
    try {
      const res = await axios.patch(`/secure/product_group/${id}`, { productIds, name });
      if (res.data?.status === 'success') {
        return res.data?.data;
      }
      return Promise.reject(Error(res.data?.message));
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  /**
   * Insert Linked Products
   *
   * @param productIds
   * @param name
   */
  const insertGroupedProducts = async (productIds: number[], name: string) => {
    try {
      const res = await axios.post(`/secure/product_group`, { productIds, name });
      if (res.data?.status === 'success') {
        return res.data?.data;
      }
      return Promise.reject(Error(res.data?.message));
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  /**
   * Delete Linked Products
   *
   * @param id
   */
  const deleteProductGroup = async (id: number) => {
    try {
      const res = await axios.delete(`/secure/product_group/${id}`);
      if (res.data?.status === 'success') {
        return res.data?.data;
      }
      return Promise.reject(Error(res.data?.message));
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  /**
   * Delete Linked Products
   */
  const selectTaxonomy = async () => {
    try {
      const res = await axios.get(`/secure/taxonomy`);
      if (res.data?.status === 'success') {
        return res.data?.data;
      }
      return Promise.reject(Error(res.data?.message));
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  /**
   * Update secondary categories for an array of products
   *
   * @param product_ids
   * @param category_ids
   */
  const updateProductsCategories = async (product_ids: number[], category_ids: number[]) => {
    try {
      const res = await axios.post(`/secure/products/categories`, {
        product_ids,
        category_ids
      });
      if (res.data?.status === 'success') {
        return res.data.data;
      }
      return Promise.reject(Error(res.data.message));
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  const selectTaxonomyUnsafe = async () => {
    try {
      const res = await axios.get(`/secure/taxonomy`);
      if (res.data?.status === 'success') {
        return res.data?.data;
      }
      return Promise.reject(Error(res.data?.message));
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  return {
    getProductOutliers,
    getProductContracts,
    addProductListPriceCustom,
    getProductListPrices,
    deleteProductListPrice,
    getProductComps,
    fetchLinkedProducts,
    searchProducts,
    searchSupplierProducts,
    fetchSupplierProducts,
    updateGroupedProducts,
    insertGroupedProducts,
    deleteProductGroup,
    selectTaxonomy,
    updateProductsCategories,
    selectTaxonomyUnsafe
  };
};
