import React from 'react';
import { atom } from 'recoil';
import { SalesforceDeal } from 'sb/models/salesforce';
import { ContractBanner, ContractSectionOpenType } from 'sb/models/Contract';
import { MenuProps } from 'antd';

import {
  Auth0User,
  ContractComp,
  SalesforceAccount,
  SalesforceContract,
  SelectedProduct,
  SelectedVendor,
  SelectItem,
  SelectItemDb,
  SupplierDynamicsVendor,
  UserContractEntitlement
} from 'types/global';
import { AuthUser } from 'types/AuthUser';
import { BreadcrumbItem } from 'types/BreadcrumbItem';
import { APP_NAME } from 'shared/constants';
import { SupplierSearchItem } from 'types/SupplierSearchItem';
import { ContractORM, EditTaskState, NameIdType } from 'types/Contract';
import { Product } from 'sb/models/Product';
import { Me, ProfileSwitch } from 'models/User';
import { SupplierSelection } from 'types/vendor';
import { PinpointProduct, PinpointSelection } from 'models/Pinpoint';
import { RenewalNotificationRequest } from 'components/renewals/RenewalAlertsModal';
import { AppVersion } from 'models/Site';
import { ReportContractSummary, ReportDetailsType, ReportLayoutDb } from 'sb/models/Reports';
import { User } from 'sb/models/User';
import { Scope } from 'types/permissions';
import { Permission } from 'sb/models/Permission';
import { localStorageEffect } from './atom_effects';
import { DashboardContract } from 'sb/models/Dashboard';

/*****************************
 * App
 ****************************/
interface AuthState {
  user: AuthUser;
}

export const authState = atom<AuthState>({
  key: 'authState',
  default: { user: { given_name: '', family_name: '', email: '', user_metadata: { title: '', phone: '' } } }
});

interface UIState {
  title?: string;
  breadcrumbs?: BreadcrumbItem[];
  menu?: React.ComponentType<MenuProps>;
  menuKey?: string;
  showIntro: boolean;
}

export const uiState = atom<UIState>({
  key: 'uiState',
  default: { title: APP_NAME, breadcrumbs: [{ label: 'Home', linkTo: '/' }], showIntro: false }
});

/**************************
 * Menu related
 **************************/
export const accountAdminMenuKeyState = atom<string>({
  key: 'accountAdminMenuKeyState',
  default: 'account_summary'
});

export const accountSettingsMenuKeyState = atom<string>({
  key: 'accountSettingsMenuKeyState',
  default: 'accountInfo'
});

export const userAdminMenuKeyState = atom<string>({
  key: 'userAdminMenuKeyState',
  default: 'user_profile'
});

/**************************
 * Account Administration
 **************************/
export const selectedClientState = atom<SelectItem | undefined>({
  key: 'selectedClientState',
  default: undefined
});

/**
 * A state atom representing whether the account is using federated sign-on.
 *
 * This variable indicates if the user's account is authenticated through
 * a federated identity provider (e.g., Azure Active Directory) instead of
 * traditional username/password authentication. It is a reactive state
 * that tracks a boolean value, making it possible to manage and respond
 * to changes within the application's state management system.
 *
 * Defaults to `false`, implying traditional authentication by default.
 */
export const isAccountFederatedSignonState = atom<boolean>({
  key: 'isAccountFederatedSignonState',
  default: false
});

export const manageUserDrawerVisibleState = atom({
  key: 'manageUserDrawerVisibleState',
  default: false
});

export const selectedAuth0UserState = atom<Auth0User | undefined>({
  key: 'selectedAuth0UserState',
  default: undefined
});

export const clientRolesState = atom<SelectItemDb[] | undefined>({
  key: 'clientRolesState',
  default: undefined
});

export const clientContractState = atom<SalesforceContract | undefined>({
  key: 'clientContractState',
  default: undefined
});

export const userContractEntitlementsState = atom<UserContractEntitlement[] | undefined>({
  key: 'userContractEntitlementsState',
  default: undefined
});

export const contractEntitlementsState = atom<string>({
  key: 'contractEntitlementsState',
  default: '0'
});

export const clientEmailDomainsState = atom<string[]>({
  key: 'clientEmailDomainsState',
  default: []
});

export const salesforceAccountState = atom<undefined | SalesforceAccount>({
  key: 'salesforceAccountState',
  default: undefined
});

export const vendorState = atom<{ vendors: SupplierSearchItem[]; selected?: SupplierSearchItem }>({
  key: 'vendorState',
  default: {
    vendors: []
  }
});

export const clientAdminTabKeyState = atom<string>({
  key: 'clientAdminTabKeyState',
  default: 'user_admin'
});

export const usersState = atom<Array<Auth0User>>({
  key: 'usersState',
  default: []
});

export const loadingUsersState = atom<boolean>({
  key: 'loadingUsersState',
  default: false
});

export const userPermissionsState = atom<Array<string>>({
  key: 'userPermissionsState',
  default: []
});

export const permissionState = atom<Permission[]>({
  key: 'permissionState',
  default: []
});

export const selectedSupplierDynamicsVendorState = atom<SupplierDynamicsVendor | undefined>({
  key: 'selectedSupplierDynamicsVendorState',
  default: undefined
});

export const clientState = atom<NameIdType | undefined>({
  key: 'clientState',
  default: undefined
});

export const contractState = atom<ContractORM | undefined>({
  key: 'contractsOrmState',
  default: undefined
});

export const contractCompsState = atom<Array<ContractComp>>({
  key: 'contractCompsState',
  default: []
});

export const myState = atom<undefined | Me>({
  key: 'myState',
  default: undefined
});

export const myCompanyUsersState = atom<Auth0User[]>({
  key: 'myCompanyUsersState',
  default: []
});

export const inquirySubmissionOpenState = atom<boolean>({
  key: 'inquirySubmissionOpenState',
  default: false
});

export const supplierSelectionsState = atom<Array<SupplierSelection>>({
  key: 'supplierSelectionsState',
  default: []
});

export const screenSizeState = atom<number>({
  key: 'screenSizeState',
  default: 4
});

export const selectedTopNavKeyState = atom<string | undefined>({
  key: 'selectedTopNavKeyState',
  default: undefined
});

export const profileSwitchState = atom<undefined | ProfileSwitch>({
  key: 'profileSwitchState',
  default: undefined
});

export const pinpointSelectionState = atom<PinpointSelection | undefined>({
  key: 'pinpointSelectionState',
  default: undefined
});

export const pinpointProductState = atom<undefined | PinpointProduct>({
  key: 'pinpointProductState',
  default: undefined
});

export const renewalNotificationRequestState = atom<RenewalNotificationRequest[]>({
  key: 'renewalNotificationRequestState',
  default: []
});

export const appVersionsState = atom<AppVersion | undefined>({
  key: 'appVersionsState',
  default: undefined
});

export const submitPurchaseAnalysisOpenState = atom<boolean>({
  key: 'submitPurchaseAnalysisOpenState',
  default: false
});

export const salesforceDealState = atom<undefined | SalesforceDeal>({
  key: 'salesforceDealState',
  default: undefined
});

export const reportState = atom<undefined | ReportDetailsType>({
  key: 'reportState',
  default: undefined
});

export const modeState = atom<'view' | 'print' | 'edit'>({
  key: 'modeState',
  default: 'view'
});

export const reportContractState = atom<undefined | ReportContractSummary>({
  key: 'reportContractState',
  default: undefined
});

export const scopeState = atom<Scope[]>({
  key: 'scopeState',
  default: []
});

export const contractBannerState = atom<ContractBanner | undefined>({
  key: 'contractBannerState',
  default: undefined
});

export const contractSectionOpenState = atom<ContractSectionOpenType | undefined>({
  key: 'contractSectionOpenState',
  default: undefined
});

/*****************************
 * ComparableContracts
 ****************************/
export const selectedCompsState = atom({
  key: 'selectedCompsState',
  default: [] as Array<React.Key>
});

/**
 * Used for passing to other pages where we want to keep
 * track of a selected contract
 */
export const selectedContractIdState = atom({
  key: 'selectedContractIdState',
  default: undefined as number | undefined
});

export const allAccountUsersState = atom<User[]>({
  key: 'allAccountUsersState',
  default: []
});

export const selectedVendorState = atom<SelectedVendor | undefined>({
  key: 'selectedVendorState',
  default: undefined
});

export const selectedProductState = atom<SelectedProduct | undefined>({
  key: 'selectedProductState',
  default: undefined
});

export const totalContractValueState = atom<number | undefined>({
  key: 'totalContractValueState',
  default: undefined
});

export const bomState = atom<Product[]>({
  key: 'bomState',
  default: []
});
/**
 * Creates an Atom to keep track of the state of the products table refresh.
 *
 * @param {object} config - The configuration options for the Atom.
 * @param {string} config.key - The key to use for the Atom in the Atom store.
 * @param {string} config.default - The default value for the Atom.
 * @returns {atom<string>} - The Atom instance for the products table refresh state.
 */
export const refreshProductsTableState = atom<string>({
  key: 'refreshProductsTableState',
  default: ''
});

export const dealToAddTasksState = atom<Record<string, number> | undefined>({
  key: 'dealToAddTasksState',
  default: undefined
});

export const dealToEditTasksState = atom<EditTaskState | undefined>({
  key: 'dealToEditTasksState',
  default: undefined
});

export const productAccuracyVariationState = atom<number>({
  key: 'productAccuracyVariationState',
  default: 25,
  effects: [localStorageEffect('productAccuracyVariationState')]
});

export const siteState = atom<'smartbench' | 'vantage' | undefined>({
  key: 'siteState',
  default: undefined
});

export const contractsDashboardState = atom<undefined | DashboardContract[]>({
  key: 'contractsDashboardState',
  default: undefined
});

export const selectedAuth0UserIdState = atom<undefined | string>({
  key: 'selectedAuth0UserIdState',
  default: undefined
});

export const reportTemplatesState = atom<ReportLayoutDb[]>({
  key: 'reportTemplatesState',
  default: []
});
