import { useEffect, useState, useRef } from 'react';
import { scroller, Element } from 'react-scroll';
import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputRef,
  message,
  Modal,
  Row,
  Select,
  Table,
  TableColumnsType,
  Upload
} from 'antd';
import { CloseOutlined, LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import { isUndefined, isNull } from 'lodash-es';
import moment from 'moment';
import { useScopes } from 'hooks/useScopes';

import {  FieldSorter, FormatCurrency } from 'sb/shared/Helpers';
import { AxiosErrorHandler } from 'shared/Helpers';
import { DEFAULT_TABLE_PAGINATION_CONFIG, scrollOptions } from 'sb/shared/constants';
import { PriceList } from 'sb/models/PriceList';
import { usePriceListApi } from 'api/usePriceListApi';
import { useVendorApi } from 'sb/api/vendor';

const { Item } = Form;

const typeOptions: Array<string> = ['Initial', 'GSA', 'MSRP'];

interface UploadedFileType {
  encryptedFilename: string;
  filename: string;
  mimetype: string;
}

export interface FormValuesType {
  price_list_id: number;
  name: string;
  price_list_name: string | null;
  type: string;
  effective_date_obj?: Date;
  effective_date: string | null;
  expiration_date_obj?: Date;
  expiration_date: string | null;
  notes: string;
  encryptedFilename: UploadedFileType;
}

interface SupportingTableType {
  id: number;
  label: string;
}

interface Props {
  vendorId?: number;
  priceList?: PriceList;
  onSave: () => void;
  onUpdate: (pl: Partial<PriceList>) => void;
  onClose: () => void;
}

export const PriceListView: React.FC<Props> = ({ vendorId, priceList, onSave, onUpdate, onClose }) => {
  const { aclCheck } = useScopes();
  const [form] = Form.useForm();
  const priceListNameRef = useRef<InputRef>(null);
  const { handlePriceListProcessing, handlePriceListUpload } = usePriceListApi();

  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [searchKey, setSearchKey] = useState('');
  const [fileList, setFileList] = useState<any[]>([]);

  const [products, setProducts] = useState<any[]>([]);
  const [purchaseLevels, setPurchaseLevels] = useState<SupportingTableType[]>([]);

  // API
  const { getVendorPriceList, updateVendorPriceList } = useVendorApi();

  useEffect(() => {
    setSearchKey('');
    setProducts([]);

    form.resetFields();
    if (priceList) {
      form.setFieldsValue({
        price_list_name: priceList.price_list_name,
        price_list_type: priceList.type,
        effective_date_obj: isNull(priceList.effective_date) ? undefined : moment(priceList.effective_date),
        expiration_date_obj: isNull(priceList.expiration_date) ? undefined : moment(priceList.expiration_date),
        notes: priceList.notes
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [priceList?.price_list_id]);

  useEffect(() => {
    getPriceList().then();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKey]);

  const getPriceList = async (purchase_level_id?: number) => {
    if (!priceList || !vendorId || searchKey.length < 2) return;

    setLoading(true);
    const data = await getVendorPriceList(vendorId, priceList.price_list_id, searchKey, purchase_level_id);
    if (data) {
      const { priceList, purchaseLevels } = data;
      setProducts(priceList.products);
      setPurchaseLevels(purchaseLevels);

      let purchaseLevelId: number | undefined;
      if (purchaseLevels.length > 0 && purchase_level_id) {
        purchaseLevelId = purchase_level_id;
      } else if (purchaseLevels.length > 0 && !purchase_level_id) {
        purchaseLevelId = purchaseLevels[0].id;
      }

      form.setFieldsValue({ purchase_level_id: purchaseLevelId });

      if (purchase_level_id) {
        scroller.scrollTo('priceListComponent', scrollOptions);
      }
    }
    setLoading(false);
  };

  const handleUpload = async (formValues: FormValuesType) => {
    const formData = new FormData();
    fileList.forEach(() => {
      formData.append('file', fileList[0]);
    });

    setUploading(true);

    try {
      const result = await handlePriceListUpload(formData);
      await processPriceList(result.data, formValues);
    } catch (e) {
      console.log('Error uploading price list ...');
    } finally {
      setUploading(false);
    }
  };

  const processPriceList = async (uploadedFile: UploadedFileType, formValues: FormValuesType) => {
    const { encryptedFilename } = uploadedFile;

    formValues.effective_date = isUndefined(formValues.effective_date_obj)
      ? null
      : moment(formValues.effective_date_obj).format('YYYY-MM-DD');
    formValues.expiration_date = isUndefined(formValues.expiration_date_obj)
      ? null
      : moment(formValues.expiration_date_obj).format('YYYY-MM-DD');

    if (!vendorId) {
      message.error('Select a supplier first ...');
      return;
    }

    const modal = Modal.info({
      title: 'Processing Price List...',
      content: 'Depending upon the size of the file, this may take a while ...',
      okButtonProps: { style: { display: 'none' } },
      icon: <LoadingOutlined />
    });

    try {
      await handlePriceListProcessing(formValues, vendorId, encryptedFilename);

      onSave?.();
    } catch (e) {
      AxiosErrorHandler(e);
    } finally {
      modal.destroy();
    }
  };

  const handleMetaUpdate = async () => {
    if (!priceList) return;

    await form.validateFields();
    const { expiration_date_obj, effective_date_obj, ...rest } = form.getFieldsValue();

    setUploading(true);
    const data = {
      effective_date: isUndefined(effective_date_obj) ? null : effective_date_obj?.format('YYYY-MM-DD'),
      expiration_date: isUndefined(expiration_date_obj) ? null : expiration_date_obj?.format('YYYY-MM-DD'),
      ...rest
    };
    const res = await updateVendorPriceList(priceList.price_list_id, data);
    if (res) {
      onUpdate({ price_list_id: priceList.price_list_id, ...data });
    }
    setUploading(false);
  };

  const handlePurchaseLevelChange = async (value: any) => {
    await getPriceList(value);
  };

  const handleBeforeUpload = (file: any) => {
    setFileList((prev) => [...prev, file]);
    priceListNameRef.current?.focus();
    return false;
  };

  const handleRemove = () => {
    setFileList([]);
  };

  const handleChange = async (info: any) => {
    const { status } = info.file;
    if (status !== 'uploading') {
      console.log(info.file, info.fileList);
    }
    if (status === 'error') {
      await message.error(`${info.file.name} file upload failed.`);
    }
  };

  const columns: TableColumnsType<any> = [
    {
      title: 'Product Code',
      dataIndex: 'product_code',
      sorter: (a: any, b: any) => FieldSorter(a.product_code, b.product_code),
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Description',
      dataIndex: 'product_name',
      sorter: (a: any, b: any) => FieldSorter(a.product_name, b.product_name),
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Family',
      dataIndex: 'product_family',
      sorter: (a: any, b: any) => FieldSorter(a.product_family, b.product_family),
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Level',
      dataIndex: 'purchase_level',
      align: 'center',
      sorter: (a: any, b: any) => FieldSorter(a.purchase_level, b.purchase_level),
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Lic. Price',
      dataIndex: 'license_price',
      align: 'right',
      sorter: (a: any, b: any) => FieldSorter(a.license_price, b.license_price),
      sortDirections: ['ascend', 'descend'],
      render: (_text: any, rec: any) => FormatCurrency(rec.license_price)
    },
    {
      title: 'Maint. Price',
      dataIndex: 'maintenance_price',
      align: 'right',
      sorter: (a: any, b: any) => FieldSorter(a.maintenance_price, b.maintenance_price),
      sortDirections: ['ascend', 'descend'],
      render: (_text: any, rec: any) => FormatCurrency(rec.maintenance_price)
    }
  ];

  return (
    <Card
      type="inner"
      size="small"
      title={<div className="text-lg">Price List</div>}
      extra={<Button type="text" size="small" icon={<CloseOutlined />} onClick={onClose} />}
    >
      <Form colon={false} form={form} onFinish={handleUpload} size="large" layout="vertical">
        <br />
        <Item label="">
          <Upload
            multiple={false}
            accept=".xls, .xlsx, .xlsm, .xltm, .xl, .ods"
            beforeUpload={handleBeforeUpload}
            onRemove={handleRemove}
            onChange={handleChange}
          >
            <Button disabled={isUndefined(vendorId)} type="default" icon={<UploadOutlined />}>
              Select Price List File
            </Button>
          </Upload>
        </Item>

        <Row gutter={[24, 0]}>
          <Col span={12}>
            <Item
              label="Price List Name"
              name="price_list_name"
              rules={[{ required: true, message: 'Please provide a name for the price list!' }]}
            >
              <Input ref={priceListNameRef} placeholder="Price list name" />
            </Item>
          </Col>
          <Col span={12}>
            <Item
              label="Price List Type"
              name="price_list_type"
              rules={[{ required: true, message: 'Please select the price list type!' }]}
            >
              <Select placeholder="Price list type">
                {typeOptions.map((item: string) => (
                  <Select.Option value={item} key={item}>
                    {item}
                  </Select.Option>
                ))}
              </Select>
            </Item>
          </Col>
          <Col span={12}>
            <Item
              label="Effective Date"
              name="effective_date_obj"
              rules={[{ required: true, message: 'Please provide effective date!' }]}
            >
              <DatePicker className="w-full" format="ll" />
            </Item>
          </Col>
          <Col span={12}>
            <Item label="Expiration Date" name="expiration_date_obj">
              <DatePicker className="w-full" format="ll" />
            </Item>
          </Col>
          <Col span={24}>
            <Item label="Notes" name="notes">
              <Input.TextArea placeholder="Price list notes..." />
            </Item>
          </Col>
        </Row>

        <div className="mt-4">
          {!priceList ? (
            <Button type="primary" htmlType="submit" disabled={fileList.length === 0} loading={uploading}>
              {uploading ? 'Uploading' : 'Upload and process price List'}
            </Button>
          ) : (
            <>
              <Button
                type="primary"
                loading={uploading}
                onClick={handleMetaUpdate}
                disabled={!aclCheck(['update:all_supplier_price_lists'])}
              >
                Update
              </Button>
              <Element name="priceListComponent"></Element>
              <Divider />

              <Row className="mb-4">
                <Col span={12}>
                  <div>
                    <div className="mb-4 text-xs">PRODUCT SEARCH</div>
                    <Input.Search
                      loading={loading}
                      placeholder="Search by product name or code"
                      size="large"
                      allowClear
                      enterButton
                      style={{ width: 480 }}
                      onSearch={setSearchKey}
                    />
                  </div>
                </Col>
                <Col span={12}>
                  {purchaseLevels?.length ? (
                    <div>
                      Purchase Level:&nbsp;
                      <Item label="" name="purchase_level_id">
                        <Select
                          className="w-[15rem] mt-3"
                          size="large"
                          placeholder="Select purchase level"
                          onChange={handlePurchaseLevelChange}
                        >
                          {purchaseLevels.map((item: SupportingTableType) => (
                            <Select.Option value={item.id} key={item.id}>
                              {item.label}
                            </Select.Option>
                          ))}
                        </Select>
                      </Item>
                    </div>
                  ) : null}
                </Col>
              </Row>

              {searchKey.length > 1 ? (
                <Table
                  loading={loading}
                  className="sm-font"
                  rowKey="product_id"
                  size="small"
                  columns={columns}
                  dataSource={products}
                  showSorterTooltip={false}
                  pagination={DEFAULT_TABLE_PAGINATION_CONFIG}
                />
              ) : null}
            </>
          )}
        </div>
      </Form>
    </Card>
  );
};
