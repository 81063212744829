import React from 'react';
import { Button, Card, Typography } from 'antd';
import './DealCreationSuccess.scss';
import { Link } from 'react-router-dom';
import AddAnotherPurchaseIcon from 'assets/AddAnotherPurchageIcon.svg';

export const AddAnotherPurchase = () => {
  return (
    <Card bordered={false} className="bg-transparent text-center w-96">
      <Link to={'/submit_purchase'}>
        <Button
          type={'link'}
          icon={<img src={AddAnotherPurchaseIcon} alt="Icon" height={20} width={26} />}
          size="large"
        >
          Request Another Benchmark Report
        </Button>
      </Link>
      <Typography.Title level={5} className="!font-light text-xs leading-4 text-center">
        Request another benchmark report to evaluate if you&apos;re getting the best deal, identify any potential
        issues, and receive suggestions to improve the terms.
      </Typography.Title>
    </Card>
  );
};
