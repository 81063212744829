import { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Divider, Row, Switch, Table, TableColumnsType, Typography } from 'antd';
import { useRecoilValue } from 'recoil';
import moment from 'moment';
import { useScopes } from 'hooks/useScopes';
import { Review } from 'sb/models/Review';
import { useAdminApi } from 'sb/api/admin';
import { FieldSorter } from 'sb/shared/Helpers';
import { DEFAULT_TABLE_PAGINATION_CONFIG } from 'sb/shared/constants';
import { menuKeyState } from 'recoil/selectors';

const ReviewModeration: React.FC = () => {
  const { aclCheck } = useScopes();
  const menuKey = useRecoilValue(menuKeyState);
  const [loading, setLoading] = useState(false);
  const [isReleased, setReleased] = useState(false);
  const [reviews, setReviews] = useState<Review[]>([]);

  // API
  const { getAdminModeratedReviews, updateAdminReviewReleased } = useAdminApi();

  useEffect(() => {
    if (menuKey !== 'review_moderation') return;
    (async () => {
      setLoading(true);
      const res = await getAdminModeratedReviews(isReleased ? 1 : 0);
      if (res) setReviews(res);
      setLoading(false);
    })();
  }, [menuKey, isReleased]);

  const handleSwitchChange = useCallback(async (id: number, checked: boolean) => {
    await updateAdminReviewReleased(id, checked ? 1 : 0);
  }, []);

  const handleShowCommentsChange = (_isReleased: boolean) => {
    setReleased(_isReleased);
  };

  const columns: TableColumnsType<Review> = useMemo(
    () => [
      {
        title: 'User Name',
        dataIndex: 'first_name',
        sorter: (a: Review, b: Review) => a.first_name.localeCompare(b.first_name),
        sortDirections: ['ascend', 'descend']
      },
      {
        title: 'Supplier',
        dataIndex: 'vendor_name',
        sorter: (a: Review, b: Review) => a.vendor_name.localeCompare(b.vendor_name),
        sortDirections: ['ascend', 'descend']
      },
      {
        title: 'Date Posted',
        dataIndex: 'date_created',
        sorter: (a: Review, b: Review) => FieldSorter(a.date_created, b.date_created),
        sortDirections: ['descend', 'ascend'],
        render: (_, rec: Review) => moment(rec.date_created).format('lll')
      },
      {
        title: 'Headline',
        dataIndex: 'headline'
      },
      {
        title: 'Review',
        dataIndex: 'review'
      },
      {
        title: 'Release',
        dataIndex: 'is_released',
        render: (_, rec: Review) => (
          <Switch
            disabled={!aclCheck(['update:all_supplier_community'])}
            defaultChecked={rec.is_released === 1}
            onChange={(checked: boolean) => handleSwitchChange(rec.id, checked)}
          />
        )
      }
    ],
    [aclCheck, handleSwitchChange]
  );

  return (
    <>
      <Row justify="space-between" align="middle">
        <Col>
          <Typography.Title level={4}>Moderate Reviews</Typography.Title>
        </Col>
        <Col>
          <span className="text-xs mr-2">SHOW:</span>
          <Switch
            checkedChildren="Approved reviews"
            unCheckedChildren="Unapproved reviews"
            onChange={handleShowCommentsChange}
            defaultChecked={false}
          />
        </Col>
      </Row>

      <Divider className="my-4" />

      <Table
        className="sm-font"
        rowKey="id"
        size="small"
        loading={loading}
        columns={columns}
        dataSource={reviews}
        pagination={DEFAULT_TABLE_PAGINATION_CONFIG}
      />
    </>
  );
};

export default ReviewModeration;
