import { useAxios } from 'hooks/useAxios';
import { AxiosErrorHandler } from 'shared/Helpers';
import {
  ClientManagementSummary,
  ClientRelationTypes,
  ClientParentChildRelation,
  ChildRelationPayload
} from 'sb/models/Client';
import { useMutation, useQuery } from '@tanstack/react-query';
import { BASE_URL } from 'sb/shared/constants';

export const useClientApi = () => {
  const { axios } = useAxios(BASE_URL);
  /**
   * Search clients
   *
   * @param { string } str
   * @returns
   */
  const getClients = async (str: string) => {
    try {
      const res = await axios.get(`/secure/clients/search?str=${str}`);
      return res.data;
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  /**
   * Get Client Summary
   *
   * @param {number} clientId
   * @returns
   */
  const getClientSummary = async (clientId: number) => {
    try {
      const res = await axios.get(`/secure/client/${clientId}/summary`);
      return res.data;
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  const getClientContractPaths = async (
    id: number,
    vendorId: number,
    resellerId: number | null,
    contractId: number
  ) => {
    try {
      const res = await axios.get(`/secure/client/${id}/contract-paths`, {
        params: {
          vendor_id: vendorId,
          reseller_id: resellerId,
          contract_id: contractId
        }
      });
      return res.data;
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  /**
   * Update Client Logo filename field
   *
   * @param client_id
   * @param logo_filename
   */
  const updateClientLogoFilename = async (client_id: number, logo_filename: string) => {
    try {
      const res = await axios.patch(`/secure/client/${client_id}/logoFilename`, { logo_filename });
      return res.data;
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  const getClientDetail = async (salesforceId: string): Promise<ClientManagementSummary> => {
    try {
      const res = await axios.get(`/secure/client/${salesforceId}`);
      return res.data?.data;
    } catch (e) {
      AxiosErrorHandler(e);
      throw e;
    }
  };

  const updateClient = async (data: ClientManagementSummary, salesforceId?: string) => {
    try {
      const method = salesforceId ? axios.patch : axios.post;
      const url = salesforceId ? `/secure/client/${salesforceId}` : '/secure/client';
      const res = await method(url, data);
      return res.data;
    } catch (e) {
      AxiosErrorHandler(e);
      throw e;
    }
  };

  const getClientRelationTypes = async (): Promise<ClientRelationTypes[]> => {
    try {
      const res = await axios.get(`/secure/client/relation/types`);
      return res.data?.data;
    } catch (e) {
      AxiosErrorHandler(e);
      throw e;
    }
  };

  /**
   * Create parent client relation
   *
   * @param data
   * @param client_id
   */

  const createClientParentRelation = async (data: ClientParentChildRelation, client_id: number) => {
    try {
      const res = await axios.post(`/secure/client/${client_id}/parent_relation`, data);
      return res.data;
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  /**
   * Create child client relation
   *
   * @param data
   * @param parent_client_id
   */

  const createClientChildRelation = async (data: ClientParentChildRelation[], parent_client_id: number) => {
    try {
      const res = await axios.post(`/secure/client/${parent_client_id}/child_relations`, data);
      return res.data;
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  /**
   * Delete client relation
   *
   * @param { number } id
   * @returns
   */
  const deleteClientRelation = async (id: number) => {
    try {
      const res = await axios.delete(`/secure/client/relation/${id}`);
      return res.data;
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  return {
    getClients,
    getClientSummary,
    getClientContractPaths,
    updateClientLogoFilename,
    getClientDetail,
    updateClient,
    getClientRelationTypes,
    createClientParentRelation,
    createClientChildRelation,
    deleteClientRelation
  };
};

export const useDeleteClientRelation = (config = {}) => {
  const { deleteClientRelation } = useClientApi();
  return useMutation({
    mutationFn: async (id: number) => {
      return await deleteClientRelation(id);
    },
    ...config
  });
};

export const useCreateClientChildRelation = (config = {}) => {
  const { createClientChildRelation } = useClientApi();
  return useMutation({
    mutationFn: async (data: ChildRelationPayload & { parent_client_id: number }) => {
      const { parent_client_id, payload } = data;
      return await createClientChildRelation(payload, parent_client_id);
    },
    ...config
  });
};

export const useCreateClientParentRelation = (config = {}) => {
  const { createClientParentRelation } = useClientApi();
  return useMutation({
    mutationFn: async (data: ClientParentChildRelation & { client_id: number }) => {
      const { client_id, ...rest } = data;
      return await createClientParentRelation(rest, client_id);
    },
    ...config
  });
};

export const useClientRelationTypes = () => {
  const { getClientRelationTypes } = useClientApi();
  return useQuery({
    queryFn: async () => await getClientRelationTypes(),
    retry: 1,
    staleTime: 1000 * 60 * 60
  });
};

export const useUpdateClientLogoFilename = (config = {}) => {
  const { updateClientLogoFilename } = useClientApi();
  return useMutation({
    mutationFn: async (data: { client_id: number; logo_filename: string }) => {
      const { client_id, logo_filename } = data;
      return await updateClientLogoFilename(client_id, logo_filename);
    },
    ...config
  });
};

export const useClientDetail = (salesforceId: string, config = {}) => {
  const { getClientDetail } = useClientApi();
  return useQuery({
    queryKey: ['clientDetail', salesforceId],
    queryFn: async () => await getClientDetail(salesforceId),
    retry: 1,
    staleTime: 1000 * 60 * 60,
    ...config
  });
};

export const useUpdateClient = (config = {}) => {
  const { updateClient } = useClientApi();
  return useMutation({
    mutationFn: async (data: ClientManagementSummary & { salesforceId?: string }) => {
      const { salesforceId, ...rest } = data;
      return await updateClient(rest, salesforceId);
    },
    ...config
  });
};
