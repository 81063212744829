import { useAxios } from 'hooks/useAxios';
import { AxiosErrorHandler } from 'shared/Helpers';
import { Role } from '../types/global';
import { BASE_URL } from 'shared/constants';

export const useClientApi = () => {
  const { axios } = useAxios(BASE_URL);

  /**
   * Update Client Logo filename field
   *
   * @param client_id
   * @param logo_filename
   */
  const updateClientLogoFilename = async (client_id: number, logo_filename: string) => {
    try {
      const res = await axios.patch(`/secure/client/${client_id}/logoFilename`, { logo_filename });
      return res.data;
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  /**
   * Select client access roles
   */
  const fetchClientUserRoles = async (): Promise<Role[] | undefined> => {
    try {
      const url = `/secure/client/user/roles`;
      const { data } = await axios.get(url);
      return data;
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  return {
    updateClientLogoFilename,
    fetchClientUserRoles
  };
};
