import React, { createContext, useMemo } from 'react';
import { createRoot } from 'react-dom/client';
import { Auth0ContextInterface, Auth0Provider, initialContext, useAuth0 } from '@auth0/auth0-react';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { RecoilRoot } from 'recoil';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { AUTH0_CONFIG, BUGSNAG_APIKEY } from 'shared/constants';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import App from './App';
import { version } from '../package.json';

const queryClient = new QueryClient();

/**
 * Bugsnag Initialize
 */
Bugsnag.start({
  apiKey: BUGSNAG_APIKEY,
  plugins: [new BugsnagPluginReact()],
  releaseStage: process.env.NODE_ENV,
  appVersion: version
});
const ErrorBoundary = Bugsnag.getPlugin('react')?.createErrorBoundary(React) || React.Fragment;
// const navigate = useNavigate();

/**
 * store pathname upon page load, though only if it is not '/'
 */
if (window.location.pathname !== '/') {
  localStorage.setItem('lastLocation', window.location.pathname);
}

/**
 * Listen for changes in @reach/router locations and
 * record the lastLocation in localStorage
 */
// globalHistory.listen(({ location }) => {
//   localStorage.setItem('lastLocation', location.pathname);
// });

const onRedirectCallback = (appState: any) => {
  window.history.replaceState(
    {},
    document.title,
    appState && appState.targetUrl ? appState.targetUrl : window.location.pathname
  );
};

// Please see https://auth0.github.io/auth0-react/interfaces/auth0_provider.auth0provideroptions.html
// for a full list of the available properties on the provider
const providerConfig = {
  domain: AUTH0_CONFIG.domain,
  clientId: AUTH0_CONFIG.clientId,
  onRedirectCallback: onRedirectCallback,
  authorizationParams: {
    audience: AUTH0_CONFIG.audience,
    redirect_uri: window.location.origin,
    scope: 'openid profile email'
  }
};
export const MyAuth0Context = createContext<Auth0ContextInterface>(initialContext);

/**
 * Custom Auth0 Provider component that wraps the application with Auth0 context
 *
 * This component creates a wrapper around the Auth0 authentication context to make it
 * available throughout the application. It uses the useAuth0 hook to access authentication
 * state and functions.
 *
 * @component
 * @param {Object} props - Component props
 * @param {React.ReactNode} [props.children] - Child components to be wrapped
 *
 * The context value is memoized to prevent unnecessary re-renders, and only updates when:
 * - The user's data is updated (user?.updated_at)
 * - The loading state changes (rest.isLoading)
 * - The authentication state changes (rest.isAuthenticated)
 *
 * This provider is used in conjunction with Auth0Provider to provide authentication
 * functionality throughout the application.
 */
const MyAuth0Provider = ({ children }: { children?: React.ReactNode }) => {
  const { user, ...rest } = useAuth0();
  const contextValue = useMemo<Auth0ContextInterface>(() => {
    return {
      user,
      ...rest
    };
  }, [user?.updated_at, rest.isLoading, rest.isAuthenticated]);
  return <MyAuth0Context.Provider value={contextValue}>{children}</MyAuth0Context.Provider>;
};
const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

root.render(
  <ErrorBoundary>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <RecoilRoot>
          <Auth0Provider {...providerConfig}>
            <MyAuth0Provider>
              <HelmetProvider>
                <DndProvider backend={HTML5Backend}>
                  <App />
                </DndProvider>
              </HelmetProvider>
            </MyAuth0Provider>
          </Auth0Provider>
        </RecoilRoot>
      </BrowserRouter>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </ErrorBoundary>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.register();
