import { useCallback, useContext, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useSetRecoilState } from 'recoil';
import { authState, myState, userPermissionsState, vendorState } from 'recoil/atoms';
import { useExtraApi } from 'api/extra';
import LogRocket from 'logrocket';
import { Storage } from 'shared/storage';
import { Auth0User } from 'types/global';
import { ENV, USERFLOW_TOKEN } from 'shared/constants';
import { useScopes } from 'hooks/useScopes';
import Bugsnag from '@bugsnag/js';
import { useLocation, useNavigate } from 'react-router-dom';
import userflow from 'userflow.js';
import moment from 'moment/moment';
import packageInfo from '../../package.json';
import { MyAuth0Context } from 'index';

if (ENV === 'production') {
  LogRocket.init('npi-digital/portofino');
}

export const useAuth = () => {
  const navigate = useNavigate();
  const location = useLocation();  
  // Using MyAuth0Context instead of useAuth0 hook directly since we need the wrapped context
  const { isAuthenticated, user, getAccessTokenSilently, logout, loginWithRedirect } = useContext(MyAuth0Context);
  const setAuth = useSetRecoilState(authState);
  const setVendorState = useSetRecoilState(vendorState);
  const setUserPermissions = useSetRecoilState(userPermissionsState);
  const setMyState = useSetRecoilState(myState);
  const { setScopes } = useScopes();

  // API
  const { getMe } = useExtraApi();

  /**
   * Check to see if user is logged in.  If not, send them to Auth0 login.
   */
  useEffect(() => {
    (async () => {
      try {
        // Comment out storing access token in local storage
        // const accessToken = await getAccessTokenSilently();
        // Storage.setToken(accessToken);
        await getAccessTokenSilently();

        await initializeApp();
      } catch (e) {
        await loginWithRedirect({
          appState: { returnTo: location.pathname }
        });
        sessionStorage.setItem('returnTo', location.pathname + location.search);
        return null;
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAccessTokenSilently, loginWithRedirect]);

  /**
   * Initializes the application by performing the following:
   * - Fetching user information and permissions.
   * - Configuring user permissions, scopes, and state.
   * - Setting up storage for permissions.
   * - Initializing and configuring third-party tools and services such as LogRocket, Userflow, Bugsnag, and Freshdesk.
   * - Redirecting the user if required.
   *
   * The function makes use of callbacks and asynchronous flows to manage the application initialization process.
   *
   * If an error occurs during the initialization, the user is redirected to the login page with the current path saved for post-login redirection.
   *
   * Dependencies:
   * - `getMe`: Function to fetch the current user's information.
   * - `setMyState`: Function to update the user's state.
   * - `setScopes`: Function to configure user scopes.
   * - `setUserPermissions`: Function to set the user's permissions.
   * - `loginWithRedirect`: Function to redirect the user to the login page.
   * - `navigate`: Function to perform navigation, such as redirecting the user to a specific route.
   * - `Storage`: Object used to persist permissions to local storage.
   * - `LogRocket`, `userflow`, `Bugsnag`, `window.FreshworksWidget`: Third-party tools for monitoring, analytics, and support.
   * - `USERFLOW_TOKEN`: Token required for initializing Userflow.
   *
   * Behavior in Production Environment:
   * - LogRocket is configured and identifies the user with metadata.
   * - Permissions and application version details are logged.
   *
   * Error Handling:
   * - If an error occurs, it redirects the user to the login page and ensures the intended route is preserved for post-login navigation.
   *
   * Dependencies and Dependencies Array:
   * - Relies on React's `useCallback` hook to memoize the function and ensure it only recomputes when its dependencies change.
   * - Dependencies: `getMe`, `location.pathname`, `loginWithRedirect`, `navigate`, `setMyState`, `setScopes`, `setUserPermissions`.
   *
   * Notes:
   * - This function leverages async/await for handling asynchronous operations.
   * - The USERFLOW_TOKEN adds an additional security layer to protect Userflow API requests.
   * - The function integrates seamlessly with third-party monitoring and assistance tools for better application support and diagnostics.
   */
  const initializeApp = useCallback(async (): Promise<any> => {
    try {
      const myInfo = await getMe();

      if (myInfo) {
        /**
         * Determines the permissions for a user.
         *
         * @param {Array} user.permissions - The permissions of the user.
         * @returns {Array} - The permissions of the user, or an empty array if user.permissions is not an array.
         */
        const permissions = Array.isArray(myInfo.permissions) ? myInfo.permissions : [];
        setUserPermissions(permissions);
        setScopes(permissions);
        setMyState(myInfo);
        Storage.set('permissions', JSON.stringify(permissions));

        const npiPersonnel = permissions.indexOf('read:smartbench_access') > -1;
        const vantagePro = permissions.indexOf('read:portofino_full') > -1;
        const vantageBasic = permissions.indexOf('read:portofino_full') === -1;
        const supplierDynamicsAccess = permissions.indexOf('read:supplier_dynamics') > -1;

        // Check if LogRocket has been initialized before calling its methods
        if (ENV === 'production') {
          LogRocket.identify(myInfo.user_id, {
            name: myInfo.name,
            email: myInfo.email,
            client_id: myInfo.client_id,
            client_name: myInfo.client.name,
            portofino: permissions.indexOf('read:portofino') > -1 ? 'true' : 'false',
            version: packageInfo.version,
            npiPersonnel,
            portofinoGold: vantagePro,
            portofinoBasic: vantageBasic,
            vantagePro: vantagePro,
            vantageBasic: vantageBasic,
            supplierDynamicsAccess
          });
        }
        if (sessionStorage.getItem('returnTo')) {
          navigate(sessionStorage.getItem('returnTo') || '/');
          sessionStorage.removeItem('returnTo');
        }

        /**
         * Initialize Userflow wrapper and set user identity.
         * Also add a security layer to our Userflow.js snippet, by requiring a special signature,
         * which only our API computes via a secret key provided by Userflow.
         * This prevents malicious third parties from posting data to Userflow
         * on behalf of our users.
         */
        if (USERFLOW_TOKEN) {
          userflow
          .load()
          .then(async () => {
            userflow.init(USERFLOW_TOKEN);
            userflow.setResourceCenterLauncherHidden(true);
            await userflow.identify(
              myInfo.user_id,
              {
                name: myInfo.name,
                email: myInfo.email,
                signed_up_at: moment(myInfo.created_at).toISOString(),
                client: myInfo.client.name,
                last_login: moment(myInfo.last_login).toISOString(),
                npiPersonnel,
                vantagePro: vantagePro,
                vantageBasic: vantageBasic,
                supplierDynamicsAccess: supplierDynamicsAccess
              },
              { signature: myInfo.userflowSignature }
            );
          })
          .catch((error) => {
            console.warn("Error initializing Userflow:", error);
      
          });   
        }

        // Set user and company info for better reporting in Bugsnag
        Bugsnag.setUser(myInfo.user_id, myInfo.email, myInfo.name);
        Bugsnag.addMetadata('company', {
          name: myInfo.client?.name,
          id: myInfo.client?.id
        });

        /**
         * Identify username and email in Freshdesk ticketForm
         */
        if (typeof window.FreshworksWidget === 'function') {
          window.FreshworksWidget('identify', 'ticketForm', {
            name: myInfo.name,
            email: myInfo.email
          });
        }
      }
    } catch (e) {
      await loginWithRedirect({
        appState: { returnTo: location.pathname }
      });
      return null;
    }
  }, [getMe, location.pathname, loginWithRedirect, navigate, setMyState, setScopes, setUserPermissions]);

  useEffect(() => {
    if (user) setAuth({ user });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const handleLogout = async () => {
    Storage.remove('lastLocation');
    Storage.remove('permissions');
    setVendorState({ vendors: [], selected: undefined });
    await logout();
  };

  return { isAuthenticated, user, logout: handleLogout };
};
