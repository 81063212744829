import { useAxios } from 'hooks/useAxios';
import { AxiosErrorHandler } from 'shared/Helpers';
import { AccountData } from 'models/AccountData';
import { BASE_URL } from 'shared/constants';

export interface CreateApplicationResponse {
  client_id: string;
  client_secret: string;
  audience: string;
}

export interface ClientApplication extends CreateApplicationResponse {
  name: string;
  description: string | null;
}
export interface MultifactorAuthentication {
  is_mfa_required: boolean;
  allow_remember_browser: boolean;
}
export const useAccountSettingsApi = () => {
  const { axios } = useAxios(BASE_URL);

  /**
   * Asynchronously retrieves the client application data from the server.
   * @returns {Promise<ClientApplication | undefined>} A Promise that resolves to the client application data if the request is successful, or undefined if the request fails.
   * @throws {Error} If the request fails, an Error object with the response message is thrown.
   */
  const getClientApplication = async (): Promise<ClientApplication | undefined> => {
    try {
      const res = await axios.get(`/api/account/applications`);
      if (res.data?.status === 'success') {
        return res.data.data;
      }
      return Promise.reject(Error(res.data.message));
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  /**
   * Creates a client application.
   *
   * @returns {Promise<CreateApplicationResponse | undefined>} A Promise that resolves with the response data or undefined if the request fails.
   *
   * @throws {Error} If the request fails, an error object with the error message is thrown.
   */
  const createClientApplication = async (): Promise<ClientApplication | undefined> => {
    try {
      const res = await axios.post(`/api/account/applications`);
      if (res.data?.status === 'success') {
        return res.data.data;
      }
      return Promise.reject(Error(res.data.message));
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };

  /**
   * Deletes the client application associated with the authenticated user's account.
   *
   * @async
   * @returns {Promise<{ id: number; auth0_azp: string | null } | undefined>} - A promise that resolves to an object containing the ID of the deleted client application and the associated authentication party for the application. If the deletion fails, the promise will be rejected with an error message.
   * @throws {Error} - The error thrown if the deletion fails.
   */
  const deleteClientApplication = async (): Promise<{ id: number; auth0_azp: string | null } | undefined> => {
    try {
      const res = await axios.delete(`/api/account/applications`);
      if (res.data?.status === 'success') {
        return res.data.data;
      }
      return Promise.reject(Error(res.data.message));
    } catch (e) {
      await AxiosErrorHandler(e);
    }
  };

  /**
   * Rotates the client secret of the client application.
   *
   * @async
   * @returns {Promise<ClientApplication | undefined>} A promise that resolves with the updated client application object, or undefined if an error occurs.
   */
  const rotateClientSecret = async (): Promise<ClientApplication | undefined> => {
    try {
      const res = await axios.post(`/api/account/applications/rotate-secret`);
      if (res.data?.status === 'success') {
        return res.data.data;
      }
      return Promise.reject(Error(res.data.message));
    } catch (e) {
      await AxiosErrorHandler(e);
    }
  };

  /**
   * Get Current account
   *
   * @returns account object
   */
  const getCurrentAccount = async (profile_id?: number): Promise<AccountData | undefined> => {
    try {
      const res = await axios.get(`/api/account/contract/current`, {
        params: { profile_id }
      });
      if (res?.data?.status === 'success') {
        return res?.data?.data;
      }
      return Promise.reject(Error(res?.data?.message || 'Failed to fetch current account'));
    } catch (e) {
      await AxiosErrorHandler(e);
    }
  };

  /**
   * Get Email Domains for account
   * @returns array of email domains
   */
  const getEmailDomainsForAccount = async (): Promise<string[] | undefined> => {
    try {
      const res = await axios.get(`/secure/account/email_domains`);
      if (res?.data?.status === 'success') {
        return res?.data?.data;
      }
      return Promise.reject(Error(res?.data?.message || 'Failed to fetch email domains'));
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };
  /**
   * Updates the Multifactor Authentication (MFA) settings for an account.
   * 
   * This function sends a PATCH request to update the MFA requirements for a specific account.
   * It can be used to enable or disable MFA for all users within the account.
   *
   * @param {MultifactorAuthentication} data - The MFA configuration object containing the settings to update
   * @param {string|number} [profile_id] - Optional profile ID to update MFA settings for a specific profile
   * @returns {Promise<boolean|undefined>} A promise that resolves to:
   *   - true if MFA settings were successfully updated
   *   - false if update failed but was handled gracefully
   *   - undefined if an error occurred during the request
   * @throws {Error} If the API request fails with an error message
   */
  const updateMultifactorAuthentication = async (data: MultifactorAuthentication, profile_id?:string|number): Promise<boolean | undefined> => {
    try {
      const url = `/api/account/mfa/required`;
      const res = await axios.patch(url, data, {
        params: { profile_id }
      });
      if (res?.data?.status === 'success') {
        return res?.data?.data;
      }
      return Promise.reject(Error(res?.data?.message || 'Failed to fetch multifactor authentication'));
    } catch (e) {
      AxiosErrorHandler(e);
    }
  };
  return {
    getClientApplication,
    createClientApplication,
    deleteClientApplication,
    rotateClientSecret,
    getCurrentAccount,
    getEmailDomainsForAccount,
    updateMultifactorAuthentication
  };
};
