import { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Row,
  Select,
  Space,
  Spin,
  Table,
  Typography
} from 'antd';
import { VendorsSearch } from 'components/atoms/VendorsSearch';
import { DealDynamicsDrawer } from 'components/molecules/admin/DealDynamicsDrawer';
import { DealProductDrawer } from 'components/molecules/admin/DealProductDrawer';
import { DealDynamicsSupplierTableColumns } from 'components/columns/DealDynamicsSupplierTableColumns';
import { useScopes } from 'hooks/useScopes';
import { DEFAULT_TABLE_PAGINATION_CONFIG } from 'sb/shared/constants';
import { useSupplierDynamicsApi } from 'sb/api/supplier_dynamics';
import { useVendorApi } from 'sb/api/vendor';
import {
  Competitor,
  DealDynamic,
  DealDynamicsSupplier,
  Note,
  SupplierCategory,
  SupplierDealDynamic
} from 'sb/models/DealDynamicsSupplier';
import { cloneDeep } from 'lodash-es';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { DealDynamicNotesSupplierTableColumns } from 'components/columns/DealDynamicNotesSupplierTableColumns';
import { DealDynamicsCompetitorColumnTable } from 'components/columns/DealDynamicsCompetitorColumnTable';
import { SupplierDynamicsScoringDrawer } from 'components/organisms/SupplierDynamicsScoringDrawer';
import { CompetitorModal } from 'components/molecules/admin/CompetitorModal';

const SupplierDynamicsAdmin: React.FC = () => {
  const { aclCheck } = useScopes();
  const { confirm } = Modal;
  const [supplierDynamicForm] = Form.useForm();
  const [noteForm] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [savingProfile, setSavingProfile] = useState(false);
  const [openDealDynamicDrawer, setOpenDealDynamicDrawer] = useState(false);
  const [openProductCategoryDrawer, setOpenProductCategryDrawer] = useState(false);
  const [rateSupplierOpen, setRateSupplierOpen] = useState(false);
  const [openDrawerCompetitor, setDrawerCompetitorOpen] = useState(false);
  const [supplier, setSupplier] = useState<undefined | DealDynamicsSupplier>(undefined);
  const [hideCategoryColumn, setHideCategoryColumn] = useState(false);
  const [categories, setCategories] = useState<SupplierCategory[] | undefined>(undefined);
  const [supplierDealDynamics, setSupplierDealDynamics] = useState<SupplierDealDynamic[]>([]);
  const [notes, setNotes] = useState<Note[]>([]);
  const [competitorList, setCompetitorList] = useState<Competitor[]>([]);
  const [selectedCompetitor, setSelectedCompetitor] = useState<Competitor | undefined>(undefined);
  const [dealDynamics, setDealDynamics] = useState<DealDynamic[]>([]);
  const [categoryId, setCategoryId] = useState<number | undefined>(undefined);
  const [dealDynamicId, setDealDynamicId] = useState<number | undefined>(undefined);
  const [productCategoryProfile, setProductCategoryProfile] = useState<undefined | string>(undefined);

  // API
  const {
    deleteSupplierDealDynamic,
    deleteSupplierDealDynamicNote,
    fetchDealDynamicsSupplierAdmin,
    updateSupplierDealDynamic,
    updateSupplierDealDynamicNote
  } = useSupplierDynamicsApi();
  const { deleteCompetitor, updateSupplierCategory, updateVendor } = useVendorApi();

  const handleVendorChange = async (vendor_id?: number) => {
    if (!vendor_id) {
      setSupplierDealDynamics([]);
      setNotes([]);
      setSupplier(undefined);
      setCategories(undefined);
      setCategoryId(undefined);
      setDealDynamicId(undefined);
      setProductCategoryProfile(undefined);
      return;
    }

    setLoading(true);

    const supplier: DealDynamicsSupplier = await fetchDealDynamicsSupplierAdmin(vendor_id);
    if (supplier) {
      setSupplierDealDynamics(supplier.supplier_deal_dynamic);
      setNotes(supplier.note);
      setCompetitorList(supplier.competitor);
      setSupplier(supplier);
      setCategories(supplier.supplier_category);
      setDealDynamics(supplier.dealDynamics);
      setHideCategoryColumn(supplier.supplier_category.length === 0);
      setCategoryId(undefined);
      setDealDynamicId(undefined);
      setProductCategoryProfile(undefined);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (!supplier) return;

    if (categoryId && !dealDynamicId) {
      setSupplierDealDynamics(
        supplier.supplier_deal_dynamic.filter((item: SupplierDealDynamic) => item.supplier_category_id === categoryId)
      );
      setNotes(supplier.note.filter((item: Note) => item.supplier_category_id === categoryId));
      setCompetitorList(supplier.competitor.filter((item) => item.supplier_category?.id === categoryId));
    } else if (!categoryId && dealDynamicId) {
      setSupplierDealDynamics(
        supplier.supplier_deal_dynamic.filter((item: SupplierDealDynamic) => item.deal_dynamic.id === dealDynamicId)
      );
      setNotes(supplier.note.filter((item: Note) => item.deal_dynamic.id === dealDynamicId));
      setCompetitorList(supplier.competitor);
    } else if (categoryId && dealDynamicId) {
      setSupplierDealDynamics(
        supplier.supplier_deal_dynamic
          .filter((item: SupplierDealDynamic) => item.supplier_category_id === categoryId)
          .filter((item: SupplierDealDynamic) => item.deal_dynamic.id === dealDynamicId)
      );
      setNotes(
        supplier.note
          .filter((item: Note) => item.supplier_category_id === categoryId)
          .filter((item: Note) => item.deal_dynamic.id === dealDynamicId)
      );
      setCompetitorList(supplier.competitor.filter((item) => item.supplier_category?.id === categoryId));
    } else {
      setCompetitorList(supplier.competitor);
      setSupplierDealDynamics(supplier.supplier_deal_dynamic);
      setNotes(supplier.note);
    }

    if (categories && categoryId) {
      const [category] = categories.filter((item: SupplierCategory) => item.id === categoryId);
      if (category) {
        setProductCategoryProfile(category.profile);
      }
    } else {
      setProductCategoryProfile(undefined);
    }
  }, [categories, categoryId, dealDynamicId, supplier]);

  const handleSupplierDynamicIsAcceptedChange = async (id: number, checked: boolean) => {
    if (!supplier) return;

    try {
      const clonedSupplier = cloneDeep(supplier);
      await updateSupplierDealDynamic(id, { is_accepted: checked });
      message.success('Record updated!');

      // Update supplier state with updated is_accepted value
      clonedSupplier.supplier_deal_dynamic.map((item: SupplierDealDynamic) => {
        if (id === item.id) item.is_accepted = checked;
      });
      setSupplier(clonedSupplier);
    } catch (e) {
      console.log(e);
    }
  };

  const handleConfirmSupplierDynamicDelete = async (id: number) => {
    if (!supplier) return;

    try {
      await deleteSupplierDealDynamic(id);
      const clonedSupplier = cloneDeep(supplier);
      clonedSupplier.supplier_deal_dynamic = clonedSupplier.supplier_deal_dynamic.filter(
        (item: SupplierDealDynamic) => item.id !== id
      );
      message.success('Record deleted!');
      setSupplier(clonedSupplier);
    } catch (e) {
      console.log(e);
    }
  };

  const handleSupplierDynamicEditClick = async (rec: SupplierDealDynamic) => {
    if (!dealDynamics) return;
    const [dealDynamic] = dealDynamics.filter((item: DealDynamic) => item.id === rec.deal_dynamic.id);

    confirm({
      title: 'Edit Supplier Dynamic Value',
      icon: <EditOutlined />,
      content: (
        <Form form={supplierDynamicForm} size="large" layout="horizontal">
          <br />
          <Form.Item label="Value" name="value">
            <InputNumber size="large" min={dealDynamic.scale_low} max={dealDynamic.scale_high} />
          </Form.Item>
          <div style={{ fontSize: 12, margin: '-22px 0 0 56px' }}>
            Range: {dealDynamic.scale_low} - {dealDynamic.scale_high}
          </div>
        </Form>
      ),
      onOk() {
        const value = supplierDynamicForm.getFieldsValue();
        updateRecordValue(rec, value);
      }
    });
    supplierDynamicForm.setFieldsValue({ value: rec.value });
  };
  const handlleCompetitorEditClick = async (rec: Competitor) => {
    setSelectedCompetitor(rec);
    setDrawerCompetitorOpen(true);
  };

  const handleCompetitorDeleteClick = async (rec: Competitor) => {
    if (!competitorList) return;
    try {
      await deleteCompetitor(rec.id);
      let clonedCompetitorList = [...competitorList];
      clonedCompetitorList = clonedCompetitorList.filter((item: Competitor) => item.id !== rec.id);
      message.success('Record deleted!');
      setCompetitorList(clonedCompetitorList);
    } catch (e) {
      console.log(e);
    }
  };
  const handleCompetitorAddClick = async () => {
    setDrawerCompetitorOpen(true);
  };

  const updateRecordValue = async (rec: SupplierDealDynamic, data: { value: number }) => {
    if (!supplier) return;

    try {
      await updateSupplierDealDynamic(rec.id, data);
      message.success('Record successfully updated!');
      const clonedSupplier = cloneDeep(supplier);
      // Update supplier state with updated is_accepted value
      clonedSupplier.supplier_deal_dynamic.map((item: SupplierDealDynamic) => {
        if (rec.id === item.id) item.value = data.value;
      });
      setSupplier(clonedSupplier);
    } catch (e) {
      console.log(e);
    }
  };

  const handleNoteEditClick = async (rec: Note) => {
    if (!dealDynamics) return;

    confirm({
      title: 'Edit Supplier Dynamic Note',
      icon: <EditOutlined />,
      width: 700,
      content: (
        <Form form={noteForm} size="large" layout="horizontal">
          <br />
          <Form.Item label="Note" name="note">
            <Input.TextArea placeholder="Enter comment" />
          </Form.Item>
        </Form>
      ),
      onOk() {
        const value = noteForm.getFieldsValue();
        updateRecordNote(rec, value);
      }
    });
    noteForm.setFieldsValue({ note: rec.note });
  };

  const onCompetitorSubmit = async (competitor: Competitor) => {
    let newCompetitor = true;
    const result = competitorList.map((item) => {
      if (item.id === competitor.id) {
        newCompetitor = false;
        return competitor;
      }
      return item;
    });

    if (newCompetitor) {
      result.push(competitor);
    }
    setCompetitorList(result);
  };

  const onCompetitorClose = async () => {
    setSelectedCompetitor(undefined);
    setDrawerCompetitorOpen(false);
  };
  const handleSupplierDynamicNoteIsAcceptedChange = async (id: number, checked: boolean) => {
    if (!supplier) return;

    try {
      const clonedSupplier = cloneDeep(supplier);
      await updateSupplierDealDynamicNote(id, { is_released: checked });
      message.success('Record updated!');

      // Update supplier state with updated is_released value
      clonedSupplier.note.map((item: Note) => {
        if (id === item.id) item.is_released = checked;
      });
      setSupplier(clonedSupplier);
    } catch (e) {
      console.log(e);
    }
  };

  const updateRecordNote = async (rec: Note, data: { note: string }) => {
    if (!supplier) return;

    try {
      await updateSupplierDealDynamicNote(rec.id, data);
      message.success('Record successfully updated!');
      const clonedSupplier = cloneDeep(supplier);
      // Update supplier state with updated is_released value
      clonedSupplier.note.map((item: Note) => {
        if (rec.id === item.id) item.note = data.note;
      });
      setSupplier(clonedSupplier);
      setNotes(clonedSupplier.note);
    } catch (e) {
      console.log(e);
    }
  };

  const handleConfirmSupplierDynamicNoteDelete = async (id: number) => {
    if (!supplier) return;
    try {
      await deleteSupplierDealDynamicNote(id);
      const clonedSupplier = cloneDeep(supplier);
      clonedSupplier.note = clonedSupplier.note.filter((item: Note) => item.id !== id);
      message.success('Record deleted!');
      setSupplier(clonedSupplier);
      setNotes(clonedSupplier.note);
    } catch (e) {
      console.log(e);
    }
  };

  const handleSupplierScored = async () => {
    await handleVendorChange(supplier?.id);
    setRateSupplierOpen(false);
  };

  const handleSupplierProfileChange = async (profile: string) => {
    // Return if profile hasn't changed
    if (!supplier || supplier.profile === profile) return;
    try {
      setSavingProfile(true);
      await updateVendor(supplier.id, { profile, name: supplier.name });
      setSavingProfile(false);
      message.success('Supplier profile successfully updated!');

      const supplierClone = cloneDeep(supplier);
      supplierClone.profile = profile;
      setSupplier(supplierClone);
    } catch (e) {
      console.log(e);
    }
  };

  const handleSupplierCategoryProfileChange = async (profile: string) => {
    if (!supplier || !categoryId || !categories) return;

    // Return if profile hasn't changed
    const [category] = categories.filter((item: SupplierCategory) => item.id === categoryId);
    if (category && category.profile === profile) return;

    try {
      const supplierClone = cloneDeep(supplier);

      setSavingProfile(true);
      await updateSupplierCategory(supplier.id, categoryId, { profile });
      setSavingProfile(false);
      message.success('Supplier category profile successfully updated!');

      setSupplier(supplierClone);
      if (categories) {
        categories.map((item: SupplierCategory) => {
          if (item.id === categoryId) item.profile = profile;
        });

        supplierClone.supplier_category = categories;
        setSupplier(supplierClone);
        setProductCategoryProfile(profile);
      } else {
        setProductCategoryProfile(undefined);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onProductCateogryUpdate = (category: SupplierCategory) => {
    if (categories) {
      const _categories = categories.map((_category) => {
        if (_category.id === category.id) {
          _category = category;
        }
        return _category;
      });
      setCategories(_categories);
    }
  };
  const onProductCateogryCreate = (category: SupplierCategory) => {
    if (categories) {
      const _categories = [...categories];
      console.log(category);
      _categories.push(category);
      setCategories(_categories);
    }
  };
  return (
    <>
      <Row justify="space-between">
        <Col>
          <Typography.Title level={4}>Supplier Dynamics</Typography.Title>
        </Col>
        <Col>
          <Space>
            <Button
              type="primary"
              size="middle"
              icon={<PlusOutlined />}
              disabled={!supplier}
              onClick={() => setRateSupplierOpen(true)}
            >
              Rate Vendor
            </Button>
            {aclCheck(['update:supplier_dynamics_admin']) && (
              <Button type="primary" onClick={() => setOpenDealDynamicDrawer(true)} icon={<EditOutlined />}>
                Edit Supplier Dynamics
              </Button>
            )}
          </Space>
        </Col>
      </Row>
      <div className="text-xs">SUPPLIER SEARCH</div>
      <VendorsSearch placeholder="Search for a supplier" width="50%" onChange={handleVendorChange} />

      {categories && categories.length === 0 && (
        <Card style={{ marginTop: 16, width: '50%' }} title="Supplier Profile" size="small">
          <Spin spinning={savingProfile}>
            <Typography.Paragraph
              className="ml-2"
              editable={{
                tooltip: 'Click to edit',
                onChange: handleSupplierProfileChange
              }}
            >
              {supplier?.profile}
            </Typography.Paragraph>
          </Spin>
        </Card>
      )}

      <Row gutter={24}>
        <Col span={12}>
          {supplier && (
            <div style={{ marginTop: 18 }}>
              <div className="text-xs">SUPPLIER DYNAMIC</div>
              <Select
                className="w-full"
                dropdownMatchSelectWidth={false}
                placeholder="Select supplier dynamic"
                allowClear
                style={{ width: '100%' }}
                fieldNames={{ label: 'name', value: 'id' }}
                options={dealDynamics}
                size="large"
                onChange={setDealDynamicId}
              />
            </div>
          )}
        </Col>
        <Col span={12}>
          {supplier && (
            <div style={{ marginTop: 3 }}>
              <div className="text-xs">
                SUPPLIER PRODUCT CATEGORIES
                <Button
                  type={'link'}
                  disabled={!aclCheck(['update:supplier_dynamics_admin'])}
                  icon={<EditOutlined />}
                  onClick={() => setOpenProductCategryDrawer(true)}
                />
              </div>
              <Select
                className="w-full"
                dropdownMatchSelectWidth={false}
                placeholder="Select category"
                allowClear
                style={{ width: '100%' }}
                fieldNames={{ label: 'category', value: 'id' }}
                options={categories}
                size="large"
                onChange={setCategoryId}
              />

              {productCategoryProfile && (
                <Card style={{ marginTop: 16 }} title="Product Category Profile" size="small">
                  <Spin spinning={savingProfile}>
                    <Typography.Paragraph
                      className="ml-2"
                      editable={{
                        tooltip: 'Click to edit',
                        onChange: handleSupplierCategoryProfileChange
                      }}
                    >
                      {productCategoryProfile}
                    </Typography.Paragraph>
                  </Spin>
                </Card>
              )}
            </div>
          )}
        </Col>
      </Row>

      <Table<SupplierDealDynamic>
        loading={loading}
        style={{ marginTop: 24 }}
        className="line-items"
        title={() => <div>Supplier Dynamic Scores</div>}
        showSorterTooltip={false}
        rowKey="id"
        size="small"
        columns={DealDynamicsSupplierTableColumns({
          hideCategoryColumn: hideCategoryColumn,
          onConfirmDelete: handleConfirmSupplierDynamicDelete,
          onIsAcceptedChange: handleSupplierDynamicIsAcceptedChange,
          onEditClick: handleSupplierDynamicEditClick
        })}
        dataSource={supplierDealDynamics}
        pagination={{
          ...DEFAULT_TABLE_PAGINATION_CONFIG,
          position: ['bottomRight'],
          defaultPageSize: 10,
          showTotal: (total) => `Count: ${total}`
        }}
      />
      <br />
      <Table<Note>
        loading={loading}
        style={{ marginTop: 24 }}
        className="line-items"
        title={() => <div>Supplier Dynamic Notes</div>}
        showSorterTooltip={false}
        rowKey="id"
        size="small"
        columns={DealDynamicNotesSupplierTableColumns({
          hideCategoryColumn: hideCategoryColumn,
          onConfirmDelete: handleConfirmSupplierDynamicNoteDelete,
          onIsAcceptedChange: handleSupplierDynamicNoteIsAcceptedChange,
          onEditClick: handleNoteEditClick
        })}
        dataSource={notes}
        pagination={{
          ...DEFAULT_TABLE_PAGINATION_CONFIG,
          position: ['bottomRight'],
          defaultPageSize: 10,
          showTotal: (total) => `Count: ${total}`
        }}
      />

      <Table<Competitor>
        loading={loading}
        style={{ marginTop: 24 }}
        className="line-items"
        title={() => (
          <Row justify="space-between" align="middle">
            <Col>Supplier Competitors</Col>
            <Col>
              <Space>
                <Button size="middle" onClick={handleCompetitorAddClick}>
                  Add competitor
                </Button>
              </Space>
            </Col>
          </Row>
        )}
        showSorterTooltip={false}
        rowKey="id"
        size="small"
        columns={DealDynamicsCompetitorColumnTable({
          hideCategoryColumn: hideCategoryColumn,
          onEditClick: handlleCompetitorEditClick,
          onDeleteCompetitorClick: handleCompetitorDeleteClick
        })}
        dataSource={competitorList}
        pagination={{
          ...DEFAULT_TABLE_PAGINATION_CONFIG,
          position: ['bottomRight'],
          defaultPageSize: 10,
          showTotal: (total) => `Count: ${total}`
        }}
      />

      <DealDynamicsDrawer visible={openDealDynamicDrawer} onClose={() => setOpenDealDynamicDrawer(false)} />
      <DealProductDrawer
        visible={openProductCategoryDrawer}
        onClose={() => setOpenProductCategryDrawer(false)}
        onCategoryUpdate={onProductCateogryUpdate}
        onCategoryCreate={onProductCateogryCreate}
        supplierName={supplier?.name}
        categories={categories}
        supplierId={supplier?.id}
      />
      <SupplierDynamicsScoringDrawer
        open={rateSupplierOpen}
        onClose={() => setRateSupplierOpen(false)}
        onOkay={handleSupplierScored}
        vendor_id={supplier?.id}
      />

      <CompetitorModal
        visible={openDrawerCompetitor}
        onClose={onCompetitorClose}
        competitor={selectedCompetitor}
        onSubmit={onCompetitorSubmit}
        categories={categories}
        supplierId={supplier?.id}
      />
    </>
  );
};

export default SupplierDynamicsAdmin;
