import {
  Col,
  Row,
  Button,
  Form,
  Checkbox,
  Card,
  Typography,
  Radio,
  Input,
  Select,
  notification,
  UploadProps,
  UploadFile,
  message,
  Popconfirm
} from 'antd';
import { Link } from 'react-router-dom';
import { DatePicker } from 'antd/es';
import { useSetRecoilState } from 'recoil';
import { selectedTopNavKeyState } from 'recoil/atoms';
import React, { useEffect, useMemo, useCallback } from 'react';
import Dragger from 'antd/lib/upload/Dragger';
import { ArrowLeftOutlined, InboxOutlined, UndoOutlined } from '@ant-design/icons';
import { useForm } from 'antd/es/form/Form';
import { useBannerAd, useCreateBannerAd, useEditBannerAd } from 'api/site_administration';
import moment from 'moment/moment';
import { CreateBannerAd } from 'types/SiteAdministration';
import { RangePickerProps } from 'antd/es/date-picker';
import { RcFile, UploadChangeParam } from 'antd/es/upload';
import { UploadRequestOption as RcCustomRequestOptions } from 'rc-upload/lib/interface';
import ImgCrop from 'antd-img-crop';

const gutter = 24;

interface BannerProps {
  closeBanner: () => void;
  bannerId?: number;
}

const UserMessage: React.FC<BannerProps> = ({ closeBanner, bannerId }) => {
  // RECOIL STATE
  const setSelectedTopNavKey = useSetRecoilState(selectedTopNavKeyState);
  const [form] = useForm();
  const [fileList, setFileList] = React.useState<any[]>([]);
  const [previewImage, setPreviewImage] = React.useState('');
  const noExpiry = Form.useWatch('never_expires', form);

  const { data: bannerAd } = useBannerAd(bannerId || 0, {
    enabled: !!bannerId
  });
  useEffect(() => setSelectedTopNavKey('siteAdministration'), [setSelectedTopNavKey]);

  useEffect(() => {
    if (bannerAd && bannerAd.banner_url) {
      setPreviewImage(bannerAd.banner_url);
    }
  }, [bannerAd]);

  const { mutate: createBannerAd } = useCreateBannerAd({
    onSuccess: async () => {
      notification.success({
        message: 'Banner created successfully',
        duration: 4
      });
      closeBanner();
    },
    onError: () => {
      notification.error({
        message: 'Failed to create banner',
        duration: 4
      });
    }
  });

  const { mutate: editBannerAd } = useEditBannerAd({
    onSuccess: async () => {
      notification.success({
        message: 'Banner edited successfully',
        duration: 4
      });
      closeBanner();
    },
    onError: () => {
      notification.error({
        message: 'Failed to edit banner',
        duration: 4
      });
    }
  });

  const unpublishBanner = () => {
    if (bannerAd) {
      const { banner_type, url, publish_date, name } = bannerAd;
      const expiration_date = moment().subtract(1, 'days').startOf('day').toISOString();
      editBannerAd({ id: bannerId || 0, data: { name, banner_type, url, publish_date, expiration_date } });
    }
  };

  const handleFinish = async (
    values: CreateBannerAd & {
      never_expires: string;
    }
  ) => {
    const formData = new FormData();
    if (!bannerTypeText) {
      formData.append('file', fileList[0].originFileObj as RcFile);
    }
    formData.append('banner_type', values.banner_type);
    if (bannerTypeText) {
      formData.append('main_text', values.main_text);
    }
    formData.append('name', values.name);
    formData.append('url', values.url);
    if (values.publish_date) {
      formData.append('publish_date', moment(values.publish_date).toISOString());
    }
    if (values.expiration_date && !values.never_expires) {
      formData.append('expiration_date', moment(values.expiration_date).toISOString());
    }
    if (values.permissions?.includes('gold') && values.permissions?.includes('basic')) {
      formData.append('permissions', JSON.stringify(['read:portofino', 'read:portofino_gold']));
    } else if (values.permissions?.includes('gold')) {
      formData.append('permissions', JSON.stringify(['read:portofino_gold']));
    } else {
      formData.append('permissions', JSON.stringify(['read:portofino']));
    }
    createBannerAd(formData);
  };

  const handleEdit = (
    values: CreateBannerAd & {
      never_expires: string;
    }
  ) => {
    const { publish_date, expiration_date, permissions, url, banner_type, main_text, name } = values;
    const payload = { url, banner_type, main_text, name } as Partial<CreateBannerAd>;
    if (publish_date) {
      payload.publish_date = moment(publish_date).toISOString();
    }
    if (expiration_date && !values.never_expires) {
      payload.expiration_date = moment(expiration_date).toISOString();
    } else if (values.never_expires) {
      payload.expiration_date = undefined;
    }
    if (permissions?.includes('gold') && permissions?.includes('basic')) {
      payload.permissions = ['read:portofino', 'read:portofino_gold'];
    } else if (permissions?.includes('gold')) {
      payload.permissions = ['read:portofino_gold'];
    } else {
      payload.permissions = ['read:portofino'];
    }
    editBannerAd({ id: bannerId || 0, data: payload });
  };

  const subTitle = Form.useWatch('name', form);
  const mainTitle = Form.useWatch('main_text', form);
  const bannerType = Form.useWatch('banner_type', form);
  const bannerTypeText = bannerType === 'text';

  const handlePreview = useCallback(async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }
    setPreviewImage(file.url || (file.preview as string));
  }, []);

  const uploadChangeHandler = useCallback(
    async (info: UploadChangeParam<UploadFile>) => {
      const { status } = info.file;
      if (status === 'removed') {
        setFileList([]);
      } else if (status && status !== 'error') {
        setFileList(info.fileList);
        await handlePreview(info.file).then();
      }
    },
    [handlePreview]
  );

  const validateFileType = ({ type }: UploadFile, allowedTypes?: string) => {
    if (!allowedTypes) {
      return true;
    }
    if (type) {
      return allowedTypes.includes(type);
    }
  };

  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

  const customRequestHandler = async (options: RcCustomRequestOptions) => {
    const { onSuccess } = options;
    const file = options.file as RcFile;
    if (onSuccess) {
      onSuccess(console.log);
    }
    const formData = new FormData();
    formData.append('file', file);
  };

  const uploadProps: UploadProps = {
    name: 'files',
    multiple: false,
    fileList,
    onChange: uploadChangeHandler,
    beforeUpload: (file: UploadFile) => {
      const isAllowedType = validateFileType(file, 'image/png') || validateFileType(file, 'image/jpg');
      const isAllowedSize = (file.size as number) < 500000;
      if (!isAllowedType) {
        setFileList([]);
        message.error(`${file.name} is not a image file`).then();
        return false;
      }
      if (!isAllowedSize) {
        setFileList([]);
        message.error(`${file.name} is too large to upload`).then();
        return false;
      }
    },
    customRequest: customRequestHandler
  };

  const formInitialValues = useMemo(() => {
    if (!bannerAd) {
      return {
        banner_type: 'text',
        permissions: ['gold', 'basic'],
        never_expires: false
      };
    }
    return {
      banner_type: bannerAd.banner_type,
      name: bannerAd.name,
      main_text: bannerAd.main_text,
      url: bannerAd.url,
      publish_date: moment(bannerAd.publish_date),
      expiration_date: bannerAd.expiration_date ? moment(bannerAd.expiration_date) : undefined,
      never_expires: !bannerAd.expiration_date,
      permissions: (() => {
        if (
          (bannerAd.permissions.includes('read:portofino_gold') && bannerAd.permissions.includes('read:portofino')) ||
          bannerAd.permissions.includes('read:portofino_full')
        ) {
          return ['gold', 'basic'];
        } else if (bannerAd.permissions.includes('read:portofino_gold')) {
          return ['gold'];
        }
        return ['basic'];
      })()
    };
  }, [bannerAd]);

  const disabledDate: RangePickerProps['disabledDate'] = (current: any) => {
    return moment(current).isBefore(moment(), 'day');
  };

  return (
    <>
      <h1 className="text-3xl tracking-tight">
        <Button type={'link'} onClick={closeBanner}>
          <ArrowLeftOutlined size={24} />
        </Button>
        Banner Composer
      </h1>
      <Row gutter={gutter} className="mt-8">
        <Col span={18}>
          <Card title="Composer">
            <hr className="mb-4 opacity-50 -mt-1" />

            <Form
              labelCol={{ span: 5 }}
              wrapperCol={{ span: 12 }}
              form={form}
              initialValues={formInitialValues}
              onFinish={bannerAd ? handleEdit : handleFinish}
            >
              <Form.Item rules={[{ required: true }]} name="banner_type" label={'Banner Type'}>
                <Radio.Group>
                  <Radio value="text">Text</Radio>
                  <Radio value="image">Image</Radio>
                </Radio.Group>
              </Form.Item>

              <Form.Item name="name" label="Subtitle" rules={[{ required: true, message: 'Please enter a subtitle' }]}>
                <Input size="large" className="rounded-md" showCount maxLength={36} placeholder="Message from NPI" />
              </Form.Item>

              {bannerTypeText && (
                <Form.Item
                  className="mb-9"
                  name="main_text"
                  label="Main Title Text"
                  rules={[{ required: true, message: 'Main text cannot be blank!' }]}
                >
                  <Input.TextArea
                    size="large"
                    className="rounded-md"
                    showCount
                    maxLength={60}
                    placeholder="Short description of the main message here"
                  />
                </Form.Item>
              )}

              {!bannerTypeText && (
                <>
                  <Form.Item label="Upload Image1" rules={[{ required: true }]}>
                    <ImgCrop showReset aspect={185 / 66}>
                      <Dragger {...uploadProps} className="p-2 mb-4 " maxCount={1}>
                        <p className="ant-upload-drag-icon mb-0">
                          <InboxOutlined />
                        </p>
                        <p className="ant-upload-text text-sm opacity-50">Click or drag file to this area to upload</p>
                      </Dragger>
                    </ImgCrop>
                    <Form.Item wrapperCol={{ offset: 5 }} className="text-sm opacity-50 mb-4">
                      Image should be 370 x 150 px dimensions.
                    </Form.Item>
                  </Form.Item>
                </>
              )}

              <Form.Item
                name="url"
                label="URL"
                rules={[{ required: true, message: 'Please enter a url' }]}
                className="mb-1"
              >
                <Input size="large" className="rounded-md" />
              </Form.Item>

              <Form.Item wrapperCol={{ offset: 5 }} className="text-sm opacity-50 mb-4">
                Note: URL always opens in a new window
              </Form.Item>

              <Form.Item
                name="publish_date"
                label="Publish Date"
                rules={[{ required: true, message: 'Please select publication date' }]}
              >
                <DatePicker
                  size="large"
                  className="rounded-md"
                  showToday
                  showTime={{ format: 'HH:mm', defaultValue: moment('00:00:00', 'HH:mm'), minuteStep: 15 }}
                  use12Hours
                  disabledDate={disabledDate}
                />
              </Form.Item>

              <Form.Item name="expiration_date" label="Expiry Date" className="mb-1">
                <DatePicker
                  size="large"
                  className="rounded-md"
                  showToday
                  showTime={{ format: 'HH:mm', defaultValue: moment('00:00:00', 'HH:mm'), minuteStep: 15 }}
                  use12Hours
                  disabled={!!noExpiry}
                  disabledDate={disabledDate}
                />
              </Form.Item>

              <Form.Item wrapperCol={{ offset: 5 }} name="never_expires" valuePropName="checked">
                <Checkbox>Never Expires</Checkbox>
              </Form.Item>

              <Form.Item name="permissions" label="Audience">
                <Select
                  size="large"
                  style={{ width: 260 }}
                  mode="multiple"
                  placeholder="Select"
                  options={[
                    {
                      value: 'gold',
                      label: 'Gold'
                    },
                    {
                      value: 'basic',
                      label: 'Basic'
                    }
                  ]}
                />
              </Form.Item>

              <Form.Item wrapperCol={{ offset: 5 }}>
                <hr className="mb-6 opacity-50 mt-0" />
                <Button type="primary" htmlType="submit">
                  Save Banner
                </Button>
                <Button type="link" onClick={closeBanner}>
                  Cancel
                </Button>
                <Popconfirm
                  title="Are you sure to unpublish this banner?"
                  onConfirm={() => {
                    unpublishBanner();
                  }}
                >
                  <UndoOutlined className="text-orange-600 px-3" />
                </Popconfirm>
              </Form.Item>
            </Form>
          </Card>
        </Col>

        <Col span={6}>
          <Link to={'/'}>
            <Card className="banner-card news-triangle overflow-hidden">
              {bannerTypeText && (
                <>
                  <Typography.Title level={5} className="-mt-1 mb-3 opacity-50">
                    {subTitle || 'Message from NPI'}
                  </Typography.Title>
                  <Typography.Title level={4}>
                    {mainTitle || 'Short description of the main message here'}
                  </Typography.Title>
                </>
              )}
              {!bannerTypeText && previewImage && <img className="-m-6 w-[370px]" alt="image" src={previewImage} />}
            </Card>
          </Link>
        </Col>
      </Row>
    </>
  );
};

export default UserMessage;
