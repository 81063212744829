import { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Divider, Row, Switch, Table, TableColumnsType, Typography } from 'antd';
import { useRecoilValue } from 'recoil';
import moment from 'moment';
import { useScopes } from 'hooks/useScopes';

import { Note } from 'sb/models/Note';
import { useAdminApi } from 'sb/api/admin';
import { FieldSorter } from 'sb/shared/Helpers';
import { DEFAULT_TABLE_PAGINATION_CONFIG } from 'sb/shared/constants';
import { menuKeyState } from 'recoil/selectors';

const ContentModeration: React.FC = () => {
  const { aclCheck } = useScopes();
  const menuKey = useRecoilValue(menuKeyState);
  const [loading, setLoading] = useState(false);
  const [isReleased, setReleased] = useState(false);

  const [notes, setNotes] = useState<Note[]>([]);
  const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>([]);

  // API
  const { getAdminModeratedContent, updateAdminNoteReleased } = useAdminApi();

  useEffect(() => {
    if (menuKey !== 'note_moderation') return;
    (async () => {
      setLoading(true);

      const res = await getAdminModeratedContent(isReleased ? 1 : 0);
      if (res) {
        setNotes(res);
        setExpandedRowKeys(res.filter((item: Note) => item.parent_id === null).map((item: Note) => item.id));
      }

      setLoading(false);
    })();
  }, [menuKey, isReleased]);

  const handleSwitchChange = useCallback(async (id: number, checked: boolean) => {
    await updateAdminNoteReleased(id, checked ? 1 : 0);
  }, []);

  const handleShowCommentsChange = async (_isReleased: boolean) => {
    setReleased(_isReleased);
  };

  const columns: TableColumnsType<Note> = useMemo(
    () => [
      {
        title: 'User Name',
        dataIndex: 'first_name',
        sorter: (a: Note, b: Note) => a.first_name.localeCompare(b.first_name),
        sortDirections: ['ascend', 'descend'],
        render: (_, rec: Note) => rec.first_name
      },
      {
        title: 'Supplier',
        dataIndex: 'vendor_name',
        sorter: (a: Note, b: Note) => a.vendor_name?.localeCompare(b.vendor_name || '') || 0,
        sortDirections: ['ascend', 'descend']
      },
      {
        title: 'Date Posted',
        dataIndex: 'creation_date',
        sorter: (a: Note, b: Note) => FieldSorter(a.creation_date, b.creation_date),
        sortDirections: ['descend', 'ascend'],
        render: (_, rec: Note) => moment(rec.creation_date).format('lll')
      },
      {
        title: 'Comment',
        dataIndex: 'note'
      },
      {
        title: 'Release',
        dataIndex: 'is_released',
        render: (_, rec: Note) => (
          <Switch
            disabled={!aclCheck(['update:all_supplier_community'])}
            onChange={(checked: boolean) => handleSwitchChange(rec.id, checked)}
            defaultChecked={rec.is_released === 1}
          />
        )
      }
    ],
    [aclCheck, handleSwitchChange]
  );

  return (
    <>
      <Row justify="space-between" align="middle">
        <Col>
          <Typography.Title level={4}>Moderate Content</Typography.Title>
        </Col>
        <Col>
          <span className="mr-2 text-xs">SHOW:</span>
          <Switch
            checkedChildren="Approved comments"
            unCheckedChildren="Unapproved comments"
            defaultChecked={false}
            onChange={handleShowCommentsChange}
          />
        </Col>
      </Row>

      <Divider className="my-4" />

      <Table
        rowKey="id"
        size="small"
        loading={loading}
        columns={columns}
        dataSource={notes}
        expandable={{
          expandRowByClick: true,
          defaultExpandAllRows: true,
          expandedRowKeys
        }}
        pagination={DEFAULT_TABLE_PAGINATION_CONFIG}
      />
    </>
  );
};

export default ContentModeration;
